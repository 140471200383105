html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, img, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
    margin: 0;
    padding: 0;

    vertical-align: baseline;

    border: 0;
    outline: 0;
    background: transparent;

    font-size: 100%;
}

.loading img{
    display: block;

    margin: 0 auto;
    /*width: 100px;*/
    margin-top: 50px;
    margin-bottom: 50px;
}
.container_main {
    overflow: hidden;

    min-width: 320px;
}


body {
    cursor: default;

    font-family: 'futurabookc';
}
body .App{
    overflow: hidden;

    min-width: 320px;

    text-align: left;
}
body > b{
    font-weight: normal;
}
.container_main > b{
    font-weight: normal;
}
.container_main > b > b{
    font-weight: normal;
}
.overflow {
overflow: hidden;
}

.err {
    -webkit-animation: shake 1 linear .8s;
    -moz-animation: shake 1 linear .8s;
    -o-animation: shake 1 linear .8s;
    animation: shake 1 linear .8s;

color: #FF0000 !important;
}

a:hover {
    text-decoration: none;
}


a img {
    border: none;
}


fieldset {
    padding: 0px;

    border: none;
}


input,textarea,button {
    outline: none;

    font-family: 'futurabookc';

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
}


input[type="submit"], button {
    cursor: pointer;

    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
}


ol, ul {
    list-style: none;
}


blockquote, q {
    quotes: none;
}


blockquote:before, blockquote:after,
q:before, q:after {
    content: none;
}


:focus {
    outline: 0;
}


ins {
    text-decoration: none;
}


del {
    text-decoration: line-through;
}


* {
    outline-style: none;
}


button {
    cursor: pointer;
}


.clr {
    clear: both;

    width: 0px;
    height: 0px;
}

.align_center {
    text-align: center;
}


.wmain {
    max-width: 1200px;
    margin: 0 auto;
}

.fancybox-skin{
    padding: 2px !important;
}
.fancybox-inner{
    width: 100% !important;
}
/*hl_left*/
.hl_left{
    position: fixed;
    z-index: 112;
    top: 0;
    left: 0;

    display: block;

    width: 15vw;
    height: 100vh;

    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;

    background: #2E3443;
}

.hl_left_act.hl_left{
    width: 64px;

    transition-delay: .3s;
}
.hl_left_act:hover.hl_left{
    width: 15vw;

    transition-delay: 0s;
}
.logo{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 20.6vh;
    min-height: 184px;

    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;

    background: #ED1C24 url(../img/rebrending/logo2_white.svg)no-repeat center center;
    -webkit-background-size: 120px;
    background-size: 120px;

    -ms-align-items: center;
}

.hl_left_act .logo{
    height: 64px;
    min-height: 64px;

    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;

    background:#ED1C24  url(../img/rebrending/logo3_white.svg)no-repeat center center;
    -webkit-background-size: 32px;
    background-size: 32px;
}
.hl_left_act:hover .logo{
    height: 20.6vh;
    min-height: 184px;

    background: #ED1C24 url(../img/rebrending/logo2_white.svg)no-repeat center center;
    -webkit-background-size: 120px;
    background-size: 120px;
}


.hl_nav{
    position: relative;

    display: block;
    overflow: hidden;

    margin-top: 28px;

    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.hl_left_act .hl_nav{
    margin-top: 160px;

    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.hl_left_act:hover .hl_nav{
    margin-top: 28px;
}
.hl_nav li{
    display: block;

    box-sizing: border-box;
}
.hl_nav li a{
    position: relative;

    display: block;

    box-sizing: border-box;
    height: 4.45vw;
    height: 64px;
    padding-left: 48px;

    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    text-align: left;
    text-decoration: none;

    color: #fff;
    background: rgba(255,255,255, 0);

    font-size: 15px;
    font-size: 1.04vw;
    font-weight: 100;
    line-height: 4.45vw;
    line-height: 64px;
}
.hl_nav li a:hover{
    background: rgba(255,255,255, .1);
}
.hl_nav li a:before{
    position: absolute;
    bottom: 0;
    left: 0%;

    display: block;

    width: 0;
    height: 1px;

    content: '';
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;

    background: rgba(255,255,255, .1);
}
.hl_left_act .hl_nav li a:before{
    left: 0;

    width: 64px;

    -webkit-transition-delay: .7s;
    -o-transition-delay: .7s;
    transition-delay: .7s;
}
.hl_left_act:hover .hl_nav li a:before{
    width: 0;

    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.hl_nav li a span{
    position: relative;
    left: 0;

    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    -webkit-transition-delay: .2s;
    -o-transition-delay: .2s;
    transition-delay: .2s;
}
.hl_left_act .hl_nav li a span{
    left: 100%;

    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
}
.hl_left_act:hover .hl_nav li a span{
    left: 0;

    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
}

.menu_ico{
    position: absolute;
    top: 0;
    left: 0;

    display: block;

    width: 64px;
    height: 100%;

    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    transition-delay: 2s;
}
.hl_left_act .menu_ico{
    -webkit-transition-delay: .3s;
    -o-transition-delay: .3s;
    transition-delay: .3s;
}


.hl_left_act:hover .menu_ico{
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;

    -webkit-background-size: 0 !important;
    background-size: 0 !important;
}




.infra_menu{
    background: url(../img/infra_menu_ico.png)no-repeat center center;
    -webkit-background-size: 0px;
    background-size: 0px;
}
.hl_left_act .infra_menu{
    background: url(../img/infra_menu_ico.png)no-repeat center center;
    -webkit-background-size: 16px;
    background-size: 16px;
}

.plans_menu{
    background: url(../img/plans_menu_ico.png)no-repeat center center;
    -webkit-background-size: 0px;
    background-size: 0px;
}
.hl_left_act .plans_menu{
    background: url(../img/plans_menu_ico.png)no-repeat center center;
    -webkit-background-size: 16px;
    background-size: 16px;
}

.buy_menu{
    background: url(../img/buy_menu_ico.png)no-repeat center center;
    -webkit-background-size: 0px;
    background-size: 0px;
}
.hl_left_act .buy_menu{
    background: url(../img/buy_menu_ico.png)no-repeat center center;
    -webkit-background-size: 16px;
    background-size: 16px;
}


.gal_menu{
    background: url(../img/gal_menu_ico.svg)no-repeat center center;
    -webkit-background-size: 0px;
    background-size: 0px;
}
.hl_left_act .gal_menu{
    background: url(../img/gal_menu_ico.svg)no-repeat center center;
    -webkit-background-size: 20px;
    background-size: 20px;
}


.sales_menu{
    background: url(../img/sales_menu_ico.png)no-repeat center center;
    -webkit-background-size: 0px;
    background-size: 0px;
}
.hl_left_act .sales_menu{
    background: url(../img/sales_menu_ico.png)no-repeat center center;
    -webkit-background-size: 16px;
    background-size: 16px;
}

.cont_menu{
    background: url(../img/cont_menu_ico.png)no-repeat center center;
    -webkit-background-size: 0px;
    background-size: 0px;
}
.hl_left_act .cont_menu{
    background: url(../img/cont_menu_ico.png)no-repeat center center;
    -webkit-background-size: 20px;
    background-size: 20px;
}


.hl_bot{
    position: absolute;
    bottom: 0;
    left: 0;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    overflow: hidden;
    align-content: center;
    align-items: flex-start;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;

    box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 112px;
    padding-left: 48px;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    text-decoration: none;

    color: #fff;
    background: rgba(255,255,255, .05);

    font-size: 12px;
    font-size: .7vw;
    font-weight: normal;

    -ms-align-items: center;
    -moz-flex-direction: column;
    -o-flex-direction: column;
}
.hl_bot img{
    display: block;

    margin-bottom: 12px;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}

.hl_left_act  .hl_bot img{
    margin-bottom: 0;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}
.hl_left_act:hover .hl_bot img{
    display: block;

    margin-bottom: 12px;
}
.hl_bot span {
    position: relative;
    top: 0;

    display: block;
    overflow: hidden;

    width: 100%;
    height: 32px;

    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
    white-space:nowrap;
    letter-spacing: .8px;

    opacity: 1;

    font-weight: 100;
    line-height: 120%;
}
.hl_left_act  .hl_bot span{
    top: 10px;

    height: 0;

    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
}
.hl_left_act:hover .hl_bot span{
    top: 0;

    height: 32px;
}
.hl_bot span b{
    opacity: .6;

    font-weight: 100;
}

.hl_left_act .hl_bot{
    height: 64px;
    padding-left: 20px;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}
.hl_left_act:hover .hl_bot{
    height: 112px;
    padding-left: 48px;
}

/*hl_left*/

/*hl_top*/
.hl_top_mob_nav{
    display: none;
}
.hl_nav_btn{
    position: relative;

    display: block;
    display: none;
}
.hl_top{
    position: fixed;
    z-index:111;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: auto;

    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;

    background: rgba(0,0,0, .3);
}
.hl_top_act.hl_top{
    background: rgba(0,0,0, .05);
}
.hl_top_act:hover.hl_top{
    background: rgba(0,0,0, .3);
}
.hl_left_act:hover ~.hl_top{
    background: rgba(0,0,0, .3);
}
.hl_top_info{
    display: inline-block;

    margin-left: 28vw;

    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
    vertical-align: top;

    font-size: 0;
}
.hl_top_act .hl_top_info{
    margin-left: 12vw;

    opacity: 0;
}
.hl_top_act:hover .hl_top_info{
    margin-left: 28vw;

    opacity: 1;
}
.hl_left_act:hover ~.hl_top .hl_top_info{
    margin-left: 28vw;
}
.hl_top_info span{
    position: relative;

    display: inline-block;

    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
    vertical-align: top;
    text-transform: uppercase;

    color: #fff;

    font-size: 12px;
    font-size: .8vw;
    font-weight: 100;
    line-height: 64px;
}
.hl_top_act .hl_top_info span{
    transition-delay: .4s;

    color: #2E3443;
}
.hl_top_act:hover .hl_top_info span{
    color: #fff;
}
.hl_left_act:hover ~.hl_top .hl_top_info span{
    color: #fff;
}
.hl_top_info span + span{
    margin-left: 38px;
}
.hl_top_info span+span:before{
    position: absolute;
    top: 50%;
    left: -21px;

    display: block;

    width: 6px;
    height: 6px;

    content: '';
    transform: translateY(-50%);

    border-radius: 50%;
    background: #ED1C24;
}
.hl_right{
    display: inline-block;
    float: right;

    vertical-align: top;

    font-size: 0;
}
.hlt_develop{
    position: relative;
    z-index: 1;

    display: inline-block;

    width: 160px;
    width: 9.2vw;

    vertical-align: top;

    background: #fff;
}
.hlt_develop img{
    display: block;

        width: 1.9vw
}
.hlt_in{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 64px;

    -ms-align-items: center;
}
.hlt_in span {
    padding-left: .6vw;

    text-transform: uppercase;

    color: #000;

    font-size: 12px;
    font-size: 0.64vw;
    font-weight: normal;
    line-height: 100%;
}
.hltd{
    -webkit-transition: .8s;
    -o-transition: .8s;
    transition: .8s;
    transform: scaleY(1);
    /*transition-delay: 0s;*/
    transform-origin: center center;
}
.hlt_develop:hover .hltd{
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    transition-delay: .5s;
    transform: scaleY(0);
}
.hltf{
    position: absolute;
    top: 0;
    left: -12px;
    /*transition-delay: 0s;*/

    -webkit-transition: .8s;
    -o-transition: .8s;
    transition: .8s;
    transform: scaleY(0);
    transform-origin: center center;

    opacity: 0;
}
.hlt_develop:hover .hltf{
    -webkit-transition: .8s;
    -o-transition: .8s;
    transition: .8s;
    transition-delay: .5s;
    transform: scaleY(1);

    opacity: 1;
}
.hlt_numb{
    position: relative;

    display: inline-block;
    overflow: hidden;

    box-sizing: border-box;
    width: 234px;
        width: 12.65vw;
    height: 64px;
    padding-left: 0;

    -webkit-transition: .8s;
    -o-transition: .8s;
    transition: .8s;
    text-align: left;
    vertical-align: top;
    text-decoration: none;

    color: #fff;
    background: #2E3443;

    font-size: 18px;
        font-size: 1vw;
        font-weight: 100;
    line-height: 64px;
}
.hlt_numb:before{
    position: relative;

    display: inline-block;

    width: 64px;
    height: 64px;
    margin-right: 0;

    content: '';
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    vertical-align: top;

    background: #2E3443 url(../img/ico_numb.png)no-repeat center center;
    background-size: 1.1vw;
}
.hl_top_act .hlt_numb:before{
        margin-right: .7vw;

    transition-delay: .4s;

      background: #ED1C24 url(../img/ico_numb_act.png)no-repeat center center;
    background-size: 1.1vw;
  }
.hl_top_act:hover  .hlt_numb:before{
        margin-right: 0vw;

    background: #2E3443 url(../img/ico_numb.png)no-repeat center center;
    background-size: 1.1vw;
  }
.hl_left_act:hover ~.hl_top  .hlt_numb:before{
        margin-right: 0vw;

    background: #2E3443 url(../img/ico_numb.png)no-repeat center center;
    background-size: 1.1vw;
  }
.hlt_call{
    display: inline-block;
    overflow: hidden;

    width: 144px;
    width: 8.04vw;
    height: 64px;

    transition:  .6s;
    text-align: center;
    vertical-align: top;
    white-space: nowrap;
    text-decoration: none;
    text-transform: uppercase;

    color: rgba(255,255,255, 1);
    background: #ED1C24;

    font-size: 12px;
    font-size: .63vw;
    font-weight: normal;
    line-height: 64px;
}
.hl_top_act .hlt_call{
        width: 0;

    color: rgba(255,255,255, 0);
}
.hl_top_act:hover .hlt_call{
    width: 10.04vw;

    color: rgba(255,255,255, 1);
}
.hl_left_act:hover ~.hl_top  .hlt_call{
    width: 10.04vw;

    color: rgba(255,255,255, 1);
}
/*hl_top*/



/*header*/
.header{
    position: relative;

    display: block;
    overflow: hidden;

    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    padding-top: 14.5vh;
    /*background: url(../img/header_mobile_bg.jpg)no-repeat center center;*/

    background: url(../img/header_1401.jpg)no-repeat center bottom;
    -webkit-background-size: cover;
    background-size: cover;
}
.back_bg, .front_bg{
    position: absolute;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;
   /* -webkit-transition: .05s linear;
    -o-transition: .05s linear;
    transition: .05s linear;*/
}
.back_bg{
    z-index: 2;
    top: 0;

    background: #fff;
}
.front_bg{
    z-index: 3;
    bottom: 0;
}
.header .back_bg{
    background: url(../img/hd_back_bg.jpg)no-repeat center center;
    -webkit-background-size: cover;
    background-size: cover;
}
.header .front_bg{
    background: url(../img/hd_front_bg.png)no-repeat center bottom;
    background-size: 100%;
}

.header_inner{
    position: relative;
    z-index: 5;

    display: block;

    max-width: 634px;
    max-width: 54.03vw;
    margin: 0 auto;
}
.hd_dark_dec {
    position: absolute;
    top: -165px;
    left: -240px;

    display: block;

    width: 1280px;
    height: 720px;

    opacity: .6;
    background: url(../img/hd_dark_dec.png)no-repeat center top;
    background-size: 100%;
}
h1 {
    position: relative;
    z-index: 2;

    color: #fff;

    font-family: 'IskraCYR-BoldItalic';
    font-size: 72px;
    font-size: 5vw;
    font-weight: normal;
    line-height: 100%;
    /*font-style: italic;*/
}
sub {
    position: relative;
    z-index: 2;

    display: block;
    /*font-style: italic;*/

    margin-top: 40px;
    margin-top: 4.5vh;

    color: #fff;

    font-size: 32px;
    font-size: 2.22vw;
    font-weight: 100;
    line-height: 100%;
}
.dec_square{
    position: relative;
    z-index: 2;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;

    width: 152px;
    width: 10.42vw;
    height: 152px;
    height: 10.42vw;

    opacity: 1;
    color: #fff;

    -ms-align-items: center;
    -moz-flex-direction: column;
    -o-flex-direction: column;
}
.dec_square span{
    color: #2E3443;

    font-weight: 500;
}
.hd_ds{
    margin-top: 40px;
    margin-top: 4.5vh;
}
.hd_ds .ds_anim{
    background: #ED1C24;
}

.hd_ds span{
    text-transform: uppercase;

    font-size: 16px;
    font-size: 1.1vw;
    line-height: 100%;
}
.hd_ds b{
    margin-top: 6px;

    font-size: 60px;
    font-size: 4.2vw;
    font-weight: 600;
    line-height: 100%;
}
.hd_ds p{
    display: block;

    margin-top: 6px;

    font-size: 18px;
    font-size: 1.25vw;
    font-weight: 600;
    line-height: 100%;
}
.header_inner .scroll_ico{
    position: relative;
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;

    margin-top: 100px;
    margin-top: 11.2vh;
}
/*header*/

/*scale_sect*/
.scale_sect{
    overflow: hidden;
}
/*scale_sect*/

/*kids_gard*/
.kids_gard{
    position: relative;

    display: block;

    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    padding-top: 80px;
    padding-left: 64px;

    transition: all 0.4s linear;

    background: rgba(255,255,255,1);
    /*background: url(../img/kdg_mob_bg.jpg)no-repeat center center;*/
    background: url(../img/kdg_bg_02.jpg)no-repeat center top;
    -webkit-background-size: cover;
    background-size: cover;
}
/*.kids_gard.act{
    background: rgba(0,0,0,.3);
    transition: all 0.4s linear;
}*/
.kids_gard .front_bg{
    transform: scale(1.5);

    background: url(../img/kdg_front_bg.jpg)no-repeat center center;
    background-size: 100%;
}
.tm{
    position: relative;
    z-index: 3;

    display: block;

    max-width: 100%;
    margin: 0 auto;
    padding-left: 12.6vw;
}
.kd_tm{
    padding-left: 7.6vw;
}
.tm_in{
    position: relative;

    display: inline-block;

    vertical-align: top;
}
.tm_in > span{
    display: block;
    /*font-style: italic;*/

    color: #2E3443;

    font-family: 'IskraCYR-BoldItalic';
    font-size: 40px;
    font-size: 2.8vw;
    font-weight: normal;
    line-height: 100%;
}
.tm_in > p{
    display: block;
    /*font-style: italic;*/

    margin-top: 12px;
    margin-top: 2.4vh;

    color: #4E5C64;

    font-size: 32px;
    font-size: 1.7vw;
    font-weight: 100;
    line-height: 100%;
}


.kids_gard .dec_square{
    top: 10px;
    right: -23vw;
    left: auto;

    animation:  btn_pulse 2s ease-in-out infinite;
}
@keyframes btn_pulse {

0%{transform: scale(1) rotate(-2deg);}
50%{transform: scale(1.05) rotate(2deg);}
100%{transform: scale(1) rotate(-2deg);}

    }
.kids_gard .dec_square:before {
    position: absolute;
    z-index: 2;
    right: 1.2vw;
    bottom: 1.2vw;

    display: block;

    width: 2vw;
    height: 2vw;

    content: '';
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;

    opacity: 1;
    background: url(../img/click_ico.png)no-repeat center top;
    background-size: 100%;
}
.kids_gard .dec_square:hover:before {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;

    opacity: 0;
}
.kids_gard .ds_anim{
    background: #FE9800;
}
.def_ds {
    position: absolute;
    z-index: 3;

    text-decoration: none;
}
.def_ds span{
    font-size: 14px;
    font-size: 1vw;
    line-height: 100%;
}
.def_ds b{
    margin-top: 6px;

    font-size: 60px;
    font-size: 4vw;
    font-weight: 600;
    line-height: 100%;
}
.noVariants{
    padding: 40px 0px;

    text-align: center;

    font-size: 1.6vw;
}
.def_ds p{
    display: block;

    margin-top: 6px;

    text-align: center;

    font-size: 18px;
    font-size: 1.38vw;
    font-weight: 600;
    line-height: 100%;
}

.scroll_ico{
    position: absolute;
    z-index: 3;
    bottom: 70px;
    left: calc(10% + 64px);

    display: block;
}


.dec_square .ds_anim{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: 100%;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;

    color: #fff;

    -ms-align-items: center;
    -moz-flex-direction: column;
    -o-flex-direction: column;
}
/*.dec_square:hover .ds_anim{
    transform: scale(1.05);
    box-shadow: 0px 10px 20px 5px rgba(0,0,0, .4);
    opacity: .8;
}*/
/*kids_gard*/



/*school_section*/
.school_section{
    position: relative;

    display: block;

    box-sizing: border-box;
    width: 100%;
    height: 100vh;
    padding-top: 80px;
    padding-left: 64px;
   /* background: url(../img/school_mob_bg.jpg)no-repeat center center;
    background: url(../img/school_mob_bg.jpg)no-repeat center center;*/

    background: url(../img/school_bg_03.jpg)no-repeat center bottom -80px;
    -webkit-background-size: cover;
    background-size: cover;
}
.school_section .front_bg{
    transform: scale(1.5);

    background: url(../img/school_front_bg.jpg)no-repeat center top;
    background-size: 100%;
}
.school_section .dec_square{
    right: 104px;
    bottom: 96px;

    animation:  btn_pulse 2s ease-in-out infinite;
}
@keyframes btn_pulse {

0%{
    transform: scale(1) rotate(-2deg);

    box-shadow: 0px 0px 0px rgba(0,0,0, 0) ;
}
50%{
    transform: scale(1.05) rotate(2deg);

    box-shadow: 10px 10px 18px rgba(0,0,0, .5) ;
}
100%{
    transform: scale(1) rotate(-2deg);

    box-shadow: 0px 0px 0px rgba(0,0,0, 0) ;
}

    }


.school_section .dec_square:before {
    position: absolute;
    z-index: 2;
    right: 34px;
    bottom: 24px;

    display: block;

    width: 34px;
    height: 34px;

    content: '';
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;

    opacity: 1;
    background: url(../img/click_ico.png)no-repeat center top;
    background-size: 100%;
}
.school_section .dec_square:hover:before {
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;

    opacity: 0;
}

.school_section .ds_anim{
    background: #0093FE;

}
.sc_tm{
    position: relative;

    padding-left: 7.6vw;
}
.sc_tm:before {
    position: absolute;
    top: 50%;
    left: -30px;

    display: block;

    width: 996px;
    height: 663px;

    content: '';
    transform: translateY(-50%);

    background: url(../img/school_bg_dec.png)no-repeat center top;
    background-size: 100% 100%;
}

.school_section .tm_in > span{
    color: #fff;
}
.school_section .tm_in > p{
    color: #fff;
}




.div_tit{
    display: block;

    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 12.6vw;

    color: #2E3443;

    font-family: 'IskraCYR-BoldItalic';
    font-size: 2.5vw;
    font-weight: normal;
    line-height: 100%;
}
/*school_section*/




/*parking_avenue*/
.parking_avenue{
    position: relative;
    z-index: 3;

    display: block;

    box-sizing: border-box;

    text-align: center;

    font-size: 0;
}
.pa_side{
    position: relative;

    display: inline-block;
    overflow: hidden;

    width: 50%;
    height: 29.9vw;

    vertical-align: top;
}
.pa_side img{
    position: relative;
    z-index: 1;

    display: block;

    min-width: 100%;
    max-width: 150%;
    min-height: 100%;

    -webkit-transition: .8s;
    -o-transition: .8s;
    transition: .8s;
}
.pa_tit{
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: left;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-left: 12.2vw;

    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
    text-align: left;

    opacity: 1;
    color: #fff;
    background: rgb(46, 52, 67, .5);

    font-family: 'IskraCYR-BoldItalic';
    font-size: 40px;
    font-size: 2.8vw;
    font-weight: normal;
    line-height: 100%;

    -ms-align-items: center;
}
.pa_tit:hover{
    background: rgb(46, 52, 67, 0);
}

.pa_tit l{
    position: relative;

   display: inline-block;

    padding: 0 10px;

    vertical-align: top;
}

.pa_tit l:before {
    position: absolute;
    z-index: -1;
    top: 7px;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    content: '';

    background: #ED1C24;
}
.pa_side+.pa_side .pa_tit{
       padding-left: 8.9vw;
}


.pa_side:hover img{
    -webkit-transition: 15s;
    -o-transition: 15s;
    transition: 15s;
    transform: scale(1.2);
}
.pa_side:hover .pa_tit {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transform: scale(1);

    opacity: 1;

    font-size: 2vw;
}
/*parking_avenue*/



/*infra*/
.infra{
    position: relative;

    display: block;
}
.is_wrap{
    position: relative;

    display: block;
}
.infra .tm{
    position: absolute;
    bottom: 6.5vh;
    left: 50%;

    width: 88%;

    transform: translateX(-50%);
}
.infra .tm_in > span{
    color: #fff;
}
.infra_slid{
    position: relative;

    display: block;
}
.infra_slid img{
    display: block;

    width: 100%;
}
.infra_slid_nav{
    display: block;

    max-width: 1160px;
    max-width: 84vw;
    margin: 0 auto;
        margin-top: -.28vw;
    margin-right: 120px;
    margin-right: 8.4vw;
}
.isn_in{
    position: relative;

    display: block;

    max-width: 160px;
    max-width: 11.2vw;
    margin: 0 auto;
    padding-top: 28px;
    padding-top: 1.95vw;
    padding-bottom: 18px;
    padding-bottom: 1.2vw;

    cursor: pointer;
    text-align: center;
}
.is_act{
    position: absolute;
    top: 10px;
    left: 50%;

    display: block;

    width: 4.45vw;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    transform: translateX(-50%);

    opacity: 0;
}
.isn_in:hover .is_act{
    top: 0;

    opacity: 1;
}
.slick-current .isn_in .is_act{
    top: 0;

    opacity: 1;
}
.is_ico{
    width: 32px;
    width: 2.3vw;
    height: 32px;
    height: 2.3vw;
    margin: 0 auto;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;

    background-position: center center!important;
    -webkit-background-size: 2.3vw!important;
    background-size: 2.3vw!important;
}
.is_sport .is_ico{
    background: url(../img/is_sport.svg)no-repeat center center;
}
.is_sport:hover .is_ico{
    background: url(../img/is_sport_act.svg)no-repeat center center;
}
.slick-current .is_sport .is_ico{
    background: url(../img/is_sport_act.svg)no-repeat center center;
}


.is_kd .is_ico{
    background: url(../img/is_kd.svg)no-repeat center center;
}
.is_kd:hover .is_ico{
    background: url(../img/is_kd_act.svg)no-repeat center center;
}
.slick-current .is_kd .is_ico{
    background: url(../img/is_kd_act.svg)no-repeat center center;
}


.is_avenue .is_ico{
    background: url(../img/is_avenue.svg)no-repeat center center;
}
.is_avenue:hover .is_ico{
    background: url(../img/is_avenue_act.svg)no-repeat center center;
}
.slick-current .is_avenue .is_ico{
    background: url(../img/is_avenue_act.svg)no-repeat center center;
}


.is_store .is_ico{
    background: url(../img/is_store.svg)no-repeat center center;
}
.is_store:hover .is_ico{
    background: url(../img/is_store_act.svg)no-repeat center center;
}
.slick-current .is_store .is_ico{
    background: url(../img/is_store_act.svg)no-repeat center center;
}


.is_transport .is_ico{
    background: url(../img/is_transport.svg)no-repeat center center;
}
.is_transport:hover .is_ico{
    background: url(../img/is_transport_act.svg)no-repeat center center;
}
.slick-current .is_transport .is_ico{
    background: url(../img/is_transport_act.svg)no-repeat center center;
}



.is_hypermarket .is_ico{
    background: url(../img/is_hypermarket.svg)no-repeat center center;
}
.is_hypermarket:hover .is_ico{
    background: url(../img/is_hypermarket_act.svg)no-repeat center center;
}
.slick-current .is_hypermarket .is_ico{
    background: url(../img/is_hypermarket_act.svg)no-repeat center center;
}


.is_name{
    display: block;

    margin-top: 12px;

    text-transform: uppercase;

    color: #2E3443;

    font-size: 12px;
    font-size: .84vw;
    font-weight: 600;
    line-height: 110%;
}
.is_info{
    display: block;

    margin-top: 6px;

    color: #4E5C64;

    font-size: 12px;
    font-size: .84vw;
    line-height: 110%;
}
.slick-next:before, .slick-prev:before{
        content:""!important;
}
.infra_slid_nav .slick-prev, .infra_slid_nav .slick-next {
    z-index: 3;

    width: 13px;
    width: 0.91vw;
    height: 27px;
    height: 1.9vw;
    margin: 0 auto;
    padding: 0px;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    transform: translateY(-50%);

    opacity: 1 !important;
}
.infra_slid_nav .slick-prev{
    left: 0%;

    background: url(../img/is_ar_prev.svg)no-repeat center center;
    -webkit-background-size: 100%;
    background-size: 100%;
}
.infra_slid_nav .slick-prev:hover{
    background: url(../img/is_ar_prev_act.svg)no-repeat center center;
    -webkit-background-size: 100%;
    background-size: 100%;
}
.infra_slid_nav .slick-next{
    right: 0%;

    background: url(../img/is_ar_next.svg)no-repeat center center;
    -webkit-background-size: 100%;
    background-size: 100%;
}
.infra_slid_nav .slick-next:hover{
    background: url(../img/is_ar_next_act.svg)no-repeat center center;
    -webkit-background-size: 100%;
    background-size: 100%;
}
/*infra*/


/*loc_map*/
.loc_map{
    display: block;

    box-sizing: border-box;
    padding-left: 64px;

    font-size: 0;
}

.lm_side {
    position: relative;

    display: inline-block;

    width: 50%;
    height: 51vh;
    min-height: 458px;
        min-height: 31vw;

    vertical-align: top;

    background: #fff;
}
.lm_tit{
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: left;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-left: 7.2vw;

    text-align: left;

    color: #2E3443;

    font-family: 'IskraCYR-BoldItalic';
    font-size: 40px;
    font-size: 2.8vw;
    font-weight: normal;
    line-height: 100%;

    -ms-align-items: center;
}

.lm_side+.lm_side{
    background: #EEEFF0;
}
.lm_side .lm_info{
       padding-left: 8.9vw;
}

.lm_side .scroll_ico {
    bottom: 40px;
    left: 7.2vw;
}
.lm_ds{
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left:auto;
}
.lm_ds .ds_anim{
    background: #86C502;
}
.lm_info{
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    /*align-items: center;*/
    align-content: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;

    box-sizing: border-box;
    width: 100%;
    /*font-style: italic;*/
    height: 100%;

    text-align: left;

    color: #2E3443;

    -ms-align-items: center;
    -moz-flex-direction: column;
    -o-flex-direction: column;
}
.lm_info span{
    font-size: 24px;
    font-size: 1.6vw;
    font-weight: 600;
    line-height: 100%;
}
.lm_info p{
    display: block;

    margin-top: 18px;

    font-size: 16px;
    font-size: 1.1vw;
    line-height: 100%;
}
/*loc_map*/



/*svg_plan*/
.svg_plan{
    position: relative;
    z-index: 3;

    display: block;

    box-sizing: border-box;
    width: 100%;
}
.swipe{
    display: none;
}
.svg_wrap{
    position: relative;

    display: block;
}
.svg_wrap img{
    display: block;

    width: 100%;
}
.svg_ds{
    position: absolute;
    z-index: 7;
    top: 0;
    left: calc(50% + 32px);
}
.svg_ds .ds_anim{
    background: url(../img/svg_ds.jpg)no-repeat center top;
    -webkit-background-size: cover;
    background-size: cover;
}
.svg_ds span{
    color: #fff;
}
.svg_ds_mob{
    display: none;
}
#paths_plan {
    position: absolute;
    z-index: 6;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
}
.lit_ident {
    position: absolute;
    z-index: 7;

    display: block;

    box-sizing: border-box;
    min-width: 48px;
    min-width: 3.34vw;
    height: 24px;
    height: 1.67vw;
    padding: 0 13px;
    padding: 0 .85vw;

    cursor: pointer;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transition-delay: .7s;
    text-align: center;

    color: #fff;
    border-radius: 40px;

    font-size: .84vw;
    font-weight: 600;
    line-height: 24px;
    line-height: 1.67vw;
}
.lit_ident i{
}


.leased{
    background: #ED1C24;
    /*cursor: default;*/
}
.building{
    background: #FFA800;
}
.start{
    background: #10C201;
}
.building:hover, .start:hover, .leased:hover, .liter_show, .start_liter_show{
    z-index: 111;

    margin-bottom: 5px;

        transition-delay: 0s;

    box-shadow: 0px 5px 6px rgba(0,0,0, .3);
}
.lit_dop_info {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 3px;

    display: inline-block;

    height: 100%;
    padding-right: .9vw;
    padding-left: .5vw;

    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transition-delay: .35s;
    transform: translateX(87%) scaleX(0);
    transform-origin: center left;
    white-space: nowrap;

    color: #fff;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    background: inherit;
    box-shadow:6px 2px 2px rgba(0,0,0, .2);

    font-size: 11px;
    font-size: .8vw;
    font-weight: normal;
    font-style: italic;

}

.lit_ident .lit_dop_info i{
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;

    opacity: 0;
}


.lit_ident:hover .lit_dop_info{
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transform: translateX(87%) scaleX(1);

    box-shadow:6px 2px 2px rgba(0,0,0, .2);

}
.lit_ident:hover .lit_dop_info i{
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transition-delay: .35s;

    opacity: 1;
}




.liter_show .lit_dop_info{
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transform: translateX(87%) scaleX(1);

    box-shadow:6px 2px 2px rgba(0,0,0, .2);

}
.liter_show .lit_dop_info i{
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transition-delay: .35s;

    opacity: 1;
}



.start.lit_ident .lit_dop_info{
    right: auto;
    left: 0;

    padding-right: .5vw;
    padding-left: .9vw;

    transform: translateX(-88%) scaleX(0);
    transform-origin: center right;

    border-top-left-radius: 40px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 40px;
    box-shadow: -6px 2px 2px rgba(0,0,0, .2);
}
.start.lit_ident:hover .lit_dop_info{
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transform: translateX(-88%) scaleX(1);

    box-shadow: -6px 2px 2px rgba(0,0,0, .2);
}



.start.lit_ident.start_liter_show .lit_dop_info{
    right: auto;
    left: 0;

    padding-right: .5vw;
    padding-left: .9vw;

    transform: translateX(-88%) scaleX(0);
    transform-origin: center right;

    border-top-left-radius: 40px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 40px;
    box-shadow: -6px 2px 2px rgba(0,0,0, .2);
}
.start.lit_ident.start_liter_show .lit_dop_info{
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transform: translateX(-88%) scaleX(1);

    box-shadow: -6px 2px 2px rgba(0,0,0, .2);
}

.start.lit_ident.start_liter_show .lit_dop_info i{
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transition-delay: .35s;

    opacity: 1;
}

.lit_info span{
    display: block;

    font-size: .9vw;
    font-weight: 100;
    line-height: 100%;
}

.lit_1_left {
    bottom: 77%;
    left: 14%;
}
.lit_2_left {
    bottom: 75%;
    left: 17%;
}
.lit_3_left {
    bottom: 71%;
    left: 12%;
}
.lit_4_left {
    bottom: 70%;
    left: 18%;
}
.lit_5_left {
    bottom: 65%;
    left: 12%;
}
.lit_6_left {
    bottom: 63%;
    left: 15%;
}
.lit_7_left {
    bottom: 57%;
    left: 15%;
}
.lit_8_left {
    bottom: 55%;
    left: 18%;
}
.lit_9_left {
    bottom: 56%;
    left: 24%;
}
.lit_10_left {
    bottom: 53%;
    left: 27%;
}
.lit_11_left {
    bottom: 50%;
    left: 30%;
}





.lit_1_right {
    bottom: 82%;
    left: 21%;
}
.lit_2_right {
    bottom: 82%;
    left: 27%;
}
.lit_3_right {
    bottom: 77%;
    left: 22%;
}
.lit_4_right {
    bottom: 80%;
    left: 31%;
}
.lit_5_right {
    bottom: 73%;
    left: 24%;
}
.lit_8_right {
    bottom: 68%;
    left: 26%;
}
.lit_11_right {
    bottom: 66%;
    left: 30%;
}





.lit_6_right {
    bottom: 85%;
    left: 41%;
}
.lit_7_right {
    bottom: 79%;
    left: 35%;
}
.lit_10_right {
    bottom: 76%;
    left: 38%;
}
.lit_9_right {
    bottom: 84%;
    left: 46%;
}
.lit_12_right {
    bottom: 81%;
    left: 49%;
}
.lit_13_right {
    bottom: 77%;
    left: 51%;
}
.lit_14_right {
    bottom: 74%;
    left: 54%;
}

.lit_15_right {
    bottom: 30%;
    left: 29%;
}
.lit_16_right {
  bottom: 16%;
    left: 29%;
}

.lit_17_right {
    bottom: 70%;
    left: 57%;
}



.lit_19_right {
    right: 19%;
    bottom: 90%;
}
.lit_20_right {
    right: 17%;
    bottom: 86%;
}
.lit_21_right {
    right: 15%;
    bottom: 82%;
}
.lit_22_right {
    right: 12%;
    bottom: 77%;
}





.svg_infra_ident{
    position: absolute;
    z-index: 7;

    display: block;

    width: 32px;
    width: 2.23vw;
    height: 32px;
    height: 2.23vw;

    border-radius: 50%;
    background: #fff;
    box-shadow: 0px 0px 0px .28vw rgba(255,255,255, .5);
}
.svg_infra_ident p{
    position: absolute;

    display: block;

    width: 100%;
    height: 100%;
}



.svg_infra_ident span {
    position: absolute;
    top: 50%;
    left: 1.9vw;

    display: block;

    padding: 0 12px 0 6px;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    transform: translateY(-50%) scaleX(0);
    transform-origin: center left;
    white-space: nowrap;
    text-transform: uppercase;

    opacity: 0;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    background: #fff;
    box-shadow: 0px 0px 0px 0.28vw rgba(255,255,255, .5);

    font-size: 0.7vw;
    line-height: 1.5vw;
}
.svg_infra_ident:hover span{
    transform: translateY(-50%) scaleX(1);

    opacity: 1;
}



.svg_parking p{
    background: url(../img/svg_ico_parking.svg)no-repeat center center;
    -webkit-background-size: 1.2vw;
    background-size: 1.2vw;
}
.svg_bus_stop p{
    background: url(../img/svg_ico_bus_stop.svg)no-repeat center center;
    -webkit-background-size: 1.2vw;
    background-size: 1.2vw;
}
.svg_school p{
    background: url(../img/svg_ico_school.svg)no-repeat center center;
    background-size: 1.1vw
}
.svg_kd p{
    background: url(../img/svg_ico_kd.svg)no-repeat center center;
        background-size: 1.05vw
}
.svg_store p{
    background: url(../img/svg_ico_store.svg)no-repeat center center;
    -webkit-background-size: 1.2vw;
    background-size: 1.2vw;
}

.svg_parking_1 {
    bottom: 53%;
    left: 8%;
}
.svg_parking_2 {
    bottom: 34%;
    left: 44%;
}
.svg_parking_3 {
    bottom: 56%;
    left: 53%;
}
.svg_parking_4 {
    bottom: 81%;
    left: 64%;
}
.svg_bus_stop {
    bottom: 44%;
    left: 6%;
}
.svg_store {
    top: 2%;
    left: 21%;
}
.svg_school {
    bottom: 68%;
    left: 45%;
}
.svg_kd {
    bottom: 61%;
    left: 36%;
}

.svg_info{
    position: absolute;
    right: 0;
    bottom: 110px;
    bottom: 7.6vw;

    display: block;

    box-sizing: border-box;
    width: 176px;
    width: 25.27vw;
    padding: 8px 15px;
    padding: .56vw 0vw .84vw 1.05vw;

    background: #fff;

    font-size: 0;
}
.svg_info span{
    position: relative;

    display: block;

    box-sizing: border-box;

    vertical-align: middle;

    font-size: 17px;
    font-size: 1.66vw;
    /*font-style: italic;*/
    font-weight: normal;
    line-height: 100%;
}
.svg_info span+ span{
    margin-top: 8px;
    margin-top: .5vw;
}
.svg_info span:before{
    position: relative;

    display: inline-block;

    width: 8px;
    width:  1vw;
    height: 8px;
    height: 1vw;
    margin-right: 9px;

    content: '';
    vertical-align: middle;

    border-radius: 50%;
}

.leased_info:before{
    background: #ED1C24;
}
.building_info:before{
    background: #FFA800;
}
.start_info:before{
    background: #10C201;
}

.info_ar {
    position: absolute;

    display: inline-block;

    padding: 0px 10px 0px 5px;
    padding: 0 .7vw 0 .35vw;

    color: #2E3443;
    border-radius: 4px;
    background: #fff;

    font-size: 0;
    font-size: 12px;
    font-size: .83vw;
    font-weight: 600;
    line-height: 24px;
    line-height: 1.7vw;
}
.info_ar span{
        opacity: .5;
        color: #2E3443;
}
.info_ar img {
    display: inline-block;

    width: 8px;
        width: 1.8vw;
    margin-right: 6px;
    margin-right: .4vw;

    transform: rotate(45deg);
    vertical-align: middle;
}
.info_ar_sea {
    top: 14%;
    left: 7%;
    /*animation: info_ar_sea_animate 1.8s ease-out infinite ;*/

    animation-delay: 1s;
}
.info_ar_center {
    bottom: 4%;
    left: 9%;
    /*animation: info_ar_center_animate 1.8s ease-out infinite ;*/
}
.info_ar_center img{
    transform: rotate(-45deg);
}
@keyframes info_ar_sea_animate {
    0%{
        bottom: 57%;
         left: 7%;
    }
     50%{
        bottom: 56.2%;
         left: 7.2%;
    }
     75%{
        bottom: 58%;
         left: 6.8%;
    }
     100%{
        bottom: 57%;
         left: 7%;
    }
}


@keyframes info_ar_center_animate {
    0%{
        bottom: 4%;
         left: 7%;
    }
     50%{
        bottom: 4.2%;
         left: 7.5%;
    }
     75%{
        bottom: 3%;
         left: 6.8%;
    }
     100%{
        bottom: 4%;
         left: 7%;
    }
}
/*svg_plan*/

/*comfortable_living*/
.comfortable_living{
    position: relative;

    display: block;

    box-sizing: border-box;
    padding-top: 44px;
    padding-bottom: 80px;
}
.cl_title{
    display: block;

    text-align: center;

    color: #2E3443;

    font-size: 24px;
    font-size: 1.6vw;
    font-weight: 600;
    font-style: italic;
    line-height: 100%;
}
.cl_wrapper{
    display: block;

    margin-top: 44px;
    padding-left: 64px;

    text-align: left;

    font-size: 0;
}
.cl_left{
    display: inline-block;

        box-sizing: border-box;
    width: 40.7%;
    margin-top: 72px;
        margin-top: 5vw;
    padding-right: 40px;
    padding-right: 2.8vw;
    padding-left: 110px;
    padding-left: 7.6vw;

    vertical-align: top;
}
.comf_title{
    display: block;

    margin-bottom: 72px;

    font-family: 'IskraCYR-BoldItalic';
    font-size: 2.8vw;
    line-height: 110%;
}
.cl_left p{
    font-size: 1.25vw;
    line-height: 120%;
    /*font-style: italic;*/
}
.cl_left p+p{
    margin-top: 8px;
}

.cl_right{
    display: inline-block;

        box-sizing: border-box;
    width: 640px;
        width: 46.5%;

    vertical-align: top;
}
.cl_nav_slid {
    position: relative;
    z-index: 2;

    display: block;

    width: 74.4%;
    margin: 0 auto;
    margin-right: 1.8vw;
}
.cln_inner{
    position: relative;

    display: block;

    box-sizing: border-box;
    width: 100%;
    min-height: 92px;
    padding-top: 18px;
    padding-bottom: 28px;

    text-align: center;

    color: #2E3443;

    font-size: 1.01vw;
    font-weight: 600;
}
.cl_slid .slick-prev, .cl_slid .slick-next {
    z-index: 3;
    top: auto;
    bottom: 28%;

    width: 13px;
    width: 2.91vw;
    height: 27px;
    height: 5.2vw;
    margin: 0 auto;
    margin: 0 auto;
    padding: 0px;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}
.cl_slid .slick-prev{
    left: 0vw;

    background: url(../img/is_ar_prev.svg)no-repeat center center;
    -webkit-background-size: 100%;
    background-size: 100%;
}
.cl_slid .slick-prev:hover{
    background: url(../img/is_ar_prev_act.svg)no-repeat center center;
    -webkit-background-size: 100%;
    background-size: 100%;
}
.cl_slid .slick-next{
    right: -6.8vw;

    background: url(../img/is_ar_next.svg)no-repeat center center;
    -webkit-background-size: 100%;
    background-size: 100%;
}
.cl_slid .slick-next:hover{
    background: url(../img/is_ar_next_act.svg)no-repeat center center;
    -webkit-background-size: 100%;
    background-size: 100%;
}



.cl_nav_slid .slick-list{
    padding: 0 !important;
}

.cln_act_ico{
    position: absolute;
    bottom: -6px;
    left: 50%;

    display: block;

    width: 125px;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    transform: translateX(-50%);

    opacity: 0;
}
.slick-current .cln_act_ico{
    bottom: 0;

    opacity: 1;
}






.cl_slid{
    position: relative;
    z-index: 1;

    display: block;

    width: 100%;
    margin-top: -6px;
}
.cls_inner{
    position: relative;

    display: block;

    box-sizing: border-box;
    width: 100%;
    padding-left: 112px;
    padding-left: 7.8vw;
}
.cls_inner img{
    display: block;

    width: 100%;
}


.cl_ds{
    position: absolute;
    z-index: 7;
    top: 40px;
    left: 7.8vw;

    -webkit-transition: 1.2s;
    -o-transition: 1.2s;
    transition: 1.2s;

    background: #ED1C24;
}
.slick-active .cl_ds{
    left: 0;
}
/*comfortable_living*/


/*viewing*/
.viewing{
    position: relative;

    display: block;

    box-sizing: border-box;
    padding-top: 56px;
    padding-bottom: 48px;
    padding-left: 12.6vw;

    text-align: left;

    background: url(../img/viewing_bg.jpg)no-repeat center top;
    -webkit-background-size: cover;
    background-size: cover;

    font-size: 0;
}
.view_tit{
    display: inline-block;
    /*font-style: italic;*/

    text-align: left;
    vertical-align: top;

    color: #fff;

    font-family: 'IskraCYR-BoldItalic';
    font-size: 2.8vw;
    line-height: 100%;
}




.viewing .form_main{
    display: inline-block;

    margin-top: 1vw;
    margin-left: 64px;
        margin-left: 4.45vw;

    vertical-align: top;
}


.form_main .pu_pl {
    position: relative;

    display: inline-block;

    width: 305px;
    width: 21.2vw;
    height: auto;
    margin: 0 auto;

    vertical-align: top;
}
.form_main .pu_pl+.pu_pl{
    margin-left: 2.22vw;
}
.form_main .pu_pl input {
    position: relative;
    z-index: 5;

    display: block;

    box-sizing: border-box;
    width: 100%;
    height: 64px;
    height: 4.45vw;
    padding: 0;
    padding-left: 1.25vw;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;

    color: #2E3443;
    border: none;
    background: none;

    font-family: 'futurabookc';
    font-size: 1.6vw;
}
.form_main .pu_pl:before{
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 0;

    content: '';
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;

    border-top-left-radius: .22vw;
    border-top-right-radius: .22vw;
    background: rgba(255,255,255, 1);
}

.form_main .input_act.pu_pl:before{
    height: 100%;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}
.form_main .pu_pl:after{
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 2px;

    content: '';
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;

    background: #fff;
}

.form_main .input_act.pu_pl:after{
    height: 4px;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;

    background: #2E3443;
}
.input_label_content {
    position: absolute;
    z-index: 4;
    top: 0px;
    left: 0;

    box-sizing: border-box;
    padding-left: 18px;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    white-space: nowrap;

    color: #fff;

    font-family: 'futurabookc';
    font-size: 1.6vw;
    font-weight: 500;
    line-height: 64px;
    line-height: 4.45vw;
}
.input_act .input_label_content{
    top: 4px;

    opacity: .5;
    color: #2E3443;

    font-size: 16px;
    font-size: 1vw;
    line-height: 1vw;
}
.form_main .input_act input{
    color: #2E3443;
}
.btn_view{
    display: inline-block;

    width: 192px;
    width: 13.35vw;
    height: 64px;
    height: 4.45vw;
    margin-left: 2.22vw;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    text-align: center;
    vertical-align: top;
    text-transform: uppercase;

    color: #2E3443;
    border: .1vw solid #fff;
    border-radius: 2px;
    background: #fff;

    font-size: 1.04vw;
    font-weight: 600;
}
.btn_view:hover{
    color: #fff;
    background: none;
}

/*react*/
.react_input_style .MuiFormLabel-root.Mui-error {
    color: #f44336!important;
}

.react_input_style .MuiInputBase-input{
    height: 4.45vw;
    padding: 0;
}
.react_input_style .MuiFormLabel-root{
    z-index: 7;

    box-sizing: border-box;
    padding-left: 18px;

    pointer-events: none;

    color: #2E3443;

    font-family: 'futurabookc';
    font-size: 1.6vw;
    font-weight: 500;
    line-height: 4.45vw;
}
.react_input_style .Mui-focused.MuiFormLabel-root,
.react_input_style .MuiFormLabel-filled.MuiFormLabel-root{
    opacity: .5;
    color: #2E3443;
}
.react_input_style .MuiInputBase-input{
    z-index: 6;

    box-sizing: border-box;
    padding-top: 1vw;
    padding-left: 1.25vw;

    color: #2E3443;

    font-family: 'futurabookc';
    font-size: 1.6vw;
}

.react_input_style  .MuiInput-underline:after{
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 2px;

    content: '';
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    transform: none;

    border: none;
    background: #2E3443;
}
.react_input_style .MuiFormLabel-filled ~  .MuiInput-underline:after, .react_input_style .Mui-focused ~  .MuiInput-underline:after{
    height: 4px;
    }


   .react_input_style .MuiInput-underline:before{
    z-index: 2;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 0;

   content: '';
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;

    border-bottom: none;
    border-top-left-radius: .22vw;
    border-top-right-radius: .22vw;
    background: rgba(36,53,63, .05);
   }
.react_input_style .MuiFormLabel-filled ~  .MuiInput-underline:before, .react_input_style .Mui-focused ~  .MuiInput-underline:before{
    height: 100%;

    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}

.react_select_style{
        margin-top: 16px;
}
.react_select_style .MuiNativeSelect-select.MuiNativeSelect-select{
    background: none;

    line-height: 100%;
}
.react_select_style.Mui-focused:before{
    height: 100%!important;

    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
}
.react_select_style .MuiFormLabel-filled.MuiInput-underline:after, .react_select_style .Mui-focused.MuiInput-underline:after{
    height: 4px;
    }


.react_select_style .MuiNativeSelect-icon {
    position: absolute;
    top: 0;
    top: 50%;
    right: 0;
    right: 12px;

    width: 19px;
    height: 10px;

    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;

    border: none;
    background: url(../img/select_ar.svg)no-repeat center top;
}

.react_select_style.MuiFormLabel-filled.MuiInput-underline .MuiNativeSelect-icon, .react_select_style.Mui-focused.MuiInput-underline .MuiNativeSelect-icon{
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transform: scale(1, -1);

    background: url(../img/select_ar_act.svg)no-repeat center top;
    }
.react_select_style .MuiNativeSelect-icon path{
    display: none;
}
.react_select_style .MuiNativeSelect-select:not([multiple]) option, .react_select_style .MuiNativeSelect-select:not([multiple]) optgroup{
    font-size: 1vw;
}











.viewing .react_input_style .MuiFormLabel-root{
    color: #fff;

    line-height: 3vw;
}
.viewing .react_input_style .MuiInput-underline:after{
    background: #fff;
}
.viewing .react_input_style .MuiInput-underline:before{
    background: #fff;
}

.viewing .react_input_style .MuiFormLabel-filled ~  .MuiInput-underline:after, .viewing .react_input_style .Mui-focused ~  .MuiInput-underline:after{
    background: #2E3443;
    }

.viewing .react_input_style .Mui-focused.MuiFormLabel-root,
.viewing .react_input_style .MuiFormLabel-filled.MuiFormLabel-root{
    opacity: .5;
    color: #2E3443;
}
.viewing .react_input_style .Mui-focused.MuiFormLabel-root, .viewing .react_input_style .MuiFormLabel-filled.MuiFormLabel-root{
    padding-top: 5px;

    color: #2E3443;

    line-height: 100%;
}

.viewing .react_input_style .MuiFormControl-root {
    width: 21.2vw!important;
}
 .viewing .MuiFormControl-root + .MuiFormControl-root{
    margin-left: 2.22vw!important;
}
.viewing label + .MuiInput-formControl{
    margin-top: 0;
}

/*react*/



/*viewing*/


/*gallery*/
.gallery{
    position: relative;

    display: block;

    padding-top: 80px;
}
.gal_nav{
    display: block;

    margin-top: 2.22vw;
}
.gal_nav li{
    display: inline-block;

    cursor: pointer;
    vertical-align: top;
}
.gal_nav li a {
    text-decoration: none;
}
.gal_nav li span{
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;

    color: #2E3443;

    font-size: 1vw;
    font-weight: 600;
}
.gal_nav li span:hover{
    color: #ED1C24;
}
.gal_nav li.act span{
    color: #ED1C24;
}
.gal_nav li+li{
    margin-left: 1.05vw;
}

.gal_img_wrap{
    position: relative;

    display: block;

    margin-top: 2.22vw;

    text-align: center;

    font-size: 0;
}
.gal_img{
    position: relative;

    display: inline-block;

    vertical-align: top;
}
.gal_img:before{
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    content: '';
    -webkit-transition: .3s ;
    -o-transition: .3s ;
    transition: .3s ;

    background: rgba(0,0,0, .4) url(../img/plus.svg)no-repeat center center;
    -webkit-background-size: 0 0;
    -webkit-background-size: 0vw;
    background-size: 0vw;
}
.gal_img:hover:before{
    background: rgba(0,0,0, 0) url(../img/plus.svg)no-repeat center center;
    -webkit-background-size: 5.6vw;
    background-size: 5.6vw;
}
.gal_img.gs_vid:hover:before{
    background: rgba(0,0,0, 0) url(../img/plus.svg)no-repeat center center;
    -webkit-background-size: 0vw;
    background-size: 0vw;
}
.gal_img.gs_vid:after{
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    content: '';
    -webkit-transition: .3s ;
    -o-transition: .3s ;
    transition: .3s ;

    background: url(../img/ytb_btn.png)no-repeat center center;
    -webkit-background-size: 100px;
    background-size: 100px;
}
.gal_img.gs_vid:hover:after{
    background: url(../img/ytb_btn.png)no-repeat center center;
    -webkit-background-size: 130px;
    background-size: 130px;
}
.gal_img img{
    display: block;

    width: 100%;
}
.s_img{
       width: 40%;
}
.l_img{
    width: 60%;
}
.gal_slid {
    display: block;

    box-sizing: border-box;
    width: calc(100% - 64px);
    margin: 0 auto;
    margin-top: 24px;
    margin-right: 0;
}


.gal_slid .slick-prev, .gal_slid .slick-next {
    z-index: 3;
    top: 50%;

    width: 50px;
    height: 50px;
    margin: 0 auto;
    margin: 0 auto;
    padding: 0px;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    transform: translateY(-50%);

    border-radius: 50%;
}
.gal_slid .slick-prev{
    left: 10px;

    background: rgba(46, 52, 67, .8)  url(../img/is_ar_prev.svg)no-repeat center left 46%;
    -webkit-background-size: 26%;
    background-size: 26%;
}
.gal_slid .slick-prev:hover{
    background: rgba(46, 52, 67, .8)  url(../img/is_ar_prev_act.svg)no-repeat center left 46%;
    -webkit-background-size: 26%;
    background-size: 26%;
}
.gal_slid .slick-next{
    right: 10px;

    background: rgba(46, 52, 67, .8)  url(../img/is_ar_next.svg)no-repeat center left 54%;
    -webkit-background-size: 26%;
    background-size: 26%;
}
.gal_slid .slick-next:hover{
    background: rgba(46, 52, 67, .8)  url(../img/is_ar_next_act.svg)no-repeat center left 54%;
    -webkit-background-size: 26%;
    background-size: 26%;
}
/*gallery*/





/*get_apart*/
.get_apart{
    position: relative;

    display: block;

    padding-top: 74px;
    padding-bottom: 74px;
}
.get_apart .tm_in>p{
    margin-top: 1.9vw;

    font-size: 1.6vw;
}
.ga_nav{
    display: block;

    max-width: 100%;
    margin: 0 auto;
    margin-top: 54px;
    margin-top: 3.8vw;
    padding-left: 12.6vw;

    font-size: 0;
}
.ga_rooms{
    position: relative;

    display: inline-block;

    vertical-align: top;

    font-size: 0;
}
.ga_name{
    position: absolute;
    z-index: 2;
    top: 8px;
    top: .56vw;
    left: 16px;
    left: .8vw;

    display: inline-block;

    pointer-events: none;

    opacity: .5;
    color: #2E3443;

    font-size: .7vw;
}
.ga_rooms span{
    position: relative;

    display: inline-block;

    box-sizing: border-box;
    padding: 30px 16px 10px 16px;
    padding: 2.04vw 1.171vw .85vw 1.171vw;

    cursor: pointer;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    vertical-align: top;

    color: #2E3443;
    border-top-left-radius: .22vw;
    background: #f5f5f6;

    font-size: 1.6vw;
    line-height: 100%;
}
.ga_rooms span:last-child{
    border-top-right-radius: .22vw;
}
.ga_rooms span:hover{

}
.ga_rooms span+span{
    margin-left: .07vw;

    border-top-left-radius: 0px;
}
.ga_rooms span:after{
    position: absolute;
    bottom: 0vw;
    left: 0;

    display: block;

    width: 100%;
    height: 0vw;

    content: '';
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;

    background: #ED1C24;
}
.ga_rooms span:hover:after{
    bottom: -.28vw;

     height: .28vw;

    opacity: .5;
}
.ga_rooms span.act:after{
    bottom: -.28vw;

    height: .28vw;

    opacity: 1;
}








.ga_slid{
    position: relative;

    display: inline-block;

    width: 320px;
    width: 18.2vw;
    margin-left:  1.04vw;

    vertical-align: top;

    border-bottom: none;
    /*border: .07vw solid #f5f5f6;*/
    border-top-left-radius: .22vw;
    border-top-right-radius: .22vw;

    font-size: 0;
}

/*.ga_form{
    display: block;
    width: 100%;
    box-sizing: border-box;
    font-size: 0;
}
.ga_inp_wrap{
    display: inline-block;
    vertical-align: top;
    width: 50%;
    height: 4.45vw;
    padding-top: 1.4vw;
    border-right: .07vw solid #f5f5f6;
    box-sizing: border-box;
}
.ga_inp_wrap+.ga_inp_wrap{
    border:none;
}
.ga_form input{
    border:none;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 2.92vw;
    box-sizing: border-box;
    border-top-left-radius: .22vw;
    padding-left: 1.12vw;
    font-size: 1.6vw;
}
.ga_form input+input{
    border-top-right-radius: .22vw;
}
*/


.ga_form{
    position: relative;

    display: block;

    width: 100%;

    font-size: 0;
}

.ga_form input{
    display: inline-block;

    box-sizing: border-box;
    width: 50%;
    height: 4.45vw;
    padding-top: 1.4vw;
    padding-left: 1.12vw;

    -webkit-transition: .3s ;
    -o-transition: .3s ;
    transition: .3s ;
    vertical-align: top;

    border:none;
    border: .07vw solid #f5f5f6;
    border-right: .07vw solid #fff;
    border-top-left-radius: .22vw;
    background: #f5f5f6;

    font-size: 1.6vw;
}
.ga_form input+input{
    padding-right:  1.12vw;
    padding-left: 0;

    text-align: right;

    border:none;
    border: .07vw solid #f5f5f6;
    border-top-left-radius: 0vw;
    border-top-right-radius: .22vw;
}
.ga_form input:focus{border-color: #f5f5f6;background: #fff;}
.ga_form input:hover{border-color: #f5f5f6;background: #fff;}

    /*Фон слайдера*/
.slid_style {
    position: relative;
    position: relative;
    position: relative;

    display: block;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: .28vw;
    margin: 0 auto;

    background: #2E3443;
}


/*Стиль кнопки слайдера*/
.slid_style .ui-slider-handle {
    position: absolute;
    z-index: 10;
    /*border: .28vw solid #ED1C24;*/

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 1.6vw;
    height: 1.6vw;
    margin-top: -.7vw;
    margin-left: -.8vw;

    cursor: pointer;

    border-radius: 50%;
    background: #ED1C24;
}
.slid_style .ui-slider-handle:before{
    position: absolute;
    top: 50%;
    left: 50%;

    display: block;

    width: .85vw;
    height: .85vw;

    content: '';
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transform: translate(-50%,-50%);

    opacity: 0;
    border-radius: 50%;
    background: #fff;
}
.slid_style .ui-slider-handle:hover:before{
    opacity: 1;
}
.slid_style .ui-state-active.ui-slider-handle:before{
    opacity: 1;
}
.slid_style .ui-slider-handle:hover{
    /*background: #fff;*/
}
.slid_style .ui-state-active.ui-slider-handle{
    /*background: #fff;*/
}

/*Полоска заполнителя слайдера*/
.slid_style .ui-slider-range {
    position: absolute;
    top: 0px;

    height: .28vw;

    background: #ED1C24;
}

/*slid_style_react*/

.MuiSlider-root.slid_style_react{
    height: 0.28vw;
    padding: 0;
}
.MuiSlider-root.slid_style_react .MuiSlider-rail{
    height: 100%;

    opacity: 1;
    background: #2E3443;
}
.MuiSlider-root.slid_style_react .MuiSlider-track{
    height: 100%;

    opacity: 1;
    background: #ED1C24;
}
.MuiSlider-thumb::after{
    display: none;
}
.MuiSlider-root.slid_style_react .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-root.slid_style_react .MuiSlider-thumb:hover{
    box-shadow: none!important;
}
.MuiSlider-thumb.MuiSlider-active{
    box-shadow: none!important;
}
.MuiSlider-root.slid_style_react .MuiSlider-thumb{
    width: 1.6vw;
    height: 1.6vw;
    margin-top: -.7vw;
    margin-left: -.8vw;

    background:  #ED1C24;
}
.MuiSlider-thumb::before{
    position: absolute;
    top: 50%;
    left: 50%;

    display: block;

    width: .85vw;
    height: .85vw;

    content: '';
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transform: translate(-50%,-50%);

    opacity: 0;
    border-radius: 50%;
    background: #fff;
}
.MuiSlider-thumb:hover::before{
    opacity: 1;
}








.slid_style_react .MuiSlider-valueLabel{
    top: -26px;
    left: auto;
}
.slid_style_react .MuiSlider-valueLabel > span{
    width: 88px;

    transform: none;

    border-radius: 0px;
    background-color: initial;
}
.slid_style_react .MuiSlider-valueLabel > span > span {
    transform: none;
    text-align: center;

    color: #2E3443;

    font-family: 'futurabookc';
    font-size: 1.6vw;
    font-weight: normal;
}
/*slid_style_react*/
.ga_floor{
    position: relative;

    display: inline-block;

    width: 320px;
    width: 13vw;
    margin-left:  1.04vw;

    vertical-align: top;

    font-size: 0;
}
/*.ga_floor .ga_inp_wrap{
    background: #f5f5f6;
    border-right: .07vw solid #fff;
    border-top-left-radius: .22vw;
}

.ga_floor .ga_inp_wrap+.ga_inp_wrap{
    border:none;
    border-top-left-radius: 0vw;
    border-top-right-radius: .22vw;
}
.ga_floor .ga_inp_wrap input{
    background: #f5f5f6;
}*/









.ga_completion_date{
    position: relative;

    display: inline-block;

    margin-left:  1.04vw;

    vertical-align: top;

    font-size: 0;
}
.ga_completion_date span{
    position: relative;

    display: inline-block;

    box-sizing: border-box;
    padding: 30px 16px 10px 16px;
    padding: 2.04vw 1.12vw .85vw 1.12vw;

    cursor: pointer;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    vertical-align: top;

    color: #2E3443;
    border-top-left-radius: .22vw;
    background: #f5f5f6;

    font-size: 1.6vw;
    line-height: 100%;
}
.ga_completion_date span:last-child{
    border-top-right-radius: .22vw;
}
.ga_completion_date span:hover{

}
.ga_completion_date span+span {
    margin-left: .07vw;
    padding: 2.04vw 0.5vw .85vw 0.5vw;

    border-top-left-radius: 0px;
}
.ga_completion_date span:after{
    position: absolute;
    bottom: 0vw;
    left: 0;

    display: block;

    width: 100%;
    height: 0vw;

    content: '';
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;

    background: #ED1C24;
}
.ga_completion_date span:hover:after{
    bottom: -.28vw;

     height: .28vw;

    opacity: .5;
}
.ga_completion_date span.act:after{
    bottom: -.28vw;

    height: .28vw;

    opacity: 1;
}
.ga_slider{
    position: relative;

    display: block;

    box-sizing: border-box;
    width: 100%;
    margin-top: 3.8vw;
    padding-left: 12.6vw;
}
.ga_inner {
    display: block;

    box-sizing: border-box;
    width: 312px;
    width: 21.7vw;
    height: auto;
    margin: 0 auto;
    padding: 2.2vw;

    cursor: pointer;

    border: .07vw solid #d5d6d9;
}
.gai_tit{
    display: block;

    text-align: center;

    color: #2E3443;

    font-size: 1.4vw;
    font-weight: 600;
    line-height: 100%;
}
.gai_img{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 192px;
    height: 13.5vw;
    margin: 0 auto;
    margin-top: 1.8vw;

    -ms-align-items: center;
}
.gai_img img{
    display: block;

    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
}
.gai_area{
    position: relative;

    display: block;

    width: 100%;
    margin-top: 1.6vw;

    font-size: 0;
}
.gai_area > span{
    position: relative;

    display: inline-block;

    box-sizing: border-box;
    width: 50%;
    height: 4.4vw;
    padding-top: 1.4vw;

    vertical-align: top;

    border-right: .07vw solid #ED1C24;

    font-size: 1.7vw;
}
.gai_area > span+span{
    padding-left: 1.85vw;

    border-right:none;
}
.gai_area .ga_name{
    top: 0;
    left: 0;

    line-height: 100%;
}
.gai_area > span+span .ga_name{
    left: 1.85vw;
}
.ga_btn{
    display: block;

    box-sizing: border-box;
    width: 100%;
    height: 4.45vw;

    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    color: #ED1C24;
    border: 1px solid #ED1C24;
    border-radius: .22vw;

    font-size: 1.1vw;
    font-weight: 600;
    line-height: 4.45vw;
}
.ga_btn:hover{
    color: #fff;
    background: #ED1C24;
}

 .ga_slider .slick-slide  .ga_inner{
   margin-right: 1.6vw;
 }


 .ga_slider:before {
    position: absolute;
    z-index: 1;
    top: -2px;
    right: 0;

    display: block;

    width: 136px;
    width: 9.45vw;
    height: 102%;

    content: '';
    pointer-events: none;

    background: url(../img/ga_dec.png)no-repeat center top;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
}



.ga_ds{
    position: absolute;
    top: -74px;
        top: 30px;
    left: 50%;

    width: 12.5vw;
    height: 12.5vw;

    transform: translateX(-50%);
    text-align: center;
}
.ga_ds p{
    font-size: 1.4vw;
}
.ga_ds .ds_anim{
    background: #FE9800;
}
.ga_ds_mob{
    display: none;
}



.ga_slider .slick-prev, .ga_slider .slick-next {
    z-index: 3;
    top: 50%;

    width: 80px;
    width: 5.6vw;
    height: 80px;
    height: 5.6vw;
    margin: 0 auto;
    margin: 0 auto;
    padding: 0px;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    transform: translateY(-50%);

    border-radius: 50%;
}
.ga_slider .slick-prev{
    left: auto;

    margin-left: -2.8vw;

    background: rgba(46, 52, 67, .8) url(../img/is_ar_prev.svg)no-repeat center left 52%;
    -webkit-background-size: 0.85vw;
    background-size: 0.85vw;
}
.ga_slider .slick-prev:hover{
    background: rgba(46, 52, 67, 1) url(../img/is_ar_prev_act.svg)no-repeat center left 52%;
    -webkit-background-size: 0.85vw;
    background-size: 0.85vw;
}
.ga_slider .slick-next{
    right: 1.5%;

    background: rgba(46, 52, 67, .8) url(../img/is_ar_next.svg)no-repeat center left 52%;
    -webkit-background-size: 0.85vw;
    background-size: 0.85vw;
}
.ga_slider .slick-next:hover{
    background: rgba(46, 52, 67, 1) url(../img/is_ar_next_act.svg)no-repeat center left 52%;
    -webkit-background-size: 0.85vw;
    background-size: 0.85vw;
}
/*get_apart*/




/*get_flat*/
.get_flat{
    position: relative;

    display: block;

    box-sizing: border-box;
    padding-top: 150px;

    background: url(../img/gf_bg_02.jpg)no-repeat center top;
    -webkit-background-size: cover;
    background-size: cover;
}
.get_flat .tm_in > span{
    color: #fff;
}
.gf_form_wrap{
    position: relative;

    display: block;

    box-sizing: border-box;
    max-width: 100%;
    margin: 0 auto;
    margin-top: 1.68vw;
    padding-left: 12.6vw;
}

.gf_form{
    position: relative;

    display: inline-block;
    float: left;

    box-sizing: border-box;
    width: 416px;
    width: 28.9vw;
    padding: 40px 30px;
    padding:  2.8vw 2.04vw;

    vertical-align: top;

    border-top-left-radius: .22vw;
    border-top-right-radius: .22vw;
    background: #fff;
}
.gf_form .pu_pl{
    display: block;

    width: 100%;
    margin-top: 12px;
    margin-top: .9vw;
}
.gf_form .pu_pl input{}
.gf_form .input_label_content{
    color: #2E3443;
}
.gf_form .pu_pl:before{
    opacity: 0.05;
    border-top-left-radius: .22vw;
    border-top-right-radius: .22vw;
    background: #24353F;
}
.gf_form .pu_pl:after{
    background: #2E3443;
}
.gf_form .pu_pl+.pu_pl {
    margin-left: 0;
}
.btn_main{
    display: block;

    box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 4.45vw;
    margin-top: 2.04vw;
    padding: 0;

    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    color: #fff;
    border:none;
    border: 1px solid #ED1C24;
    border-radius: .22vw;
    background: none;
    background: #ED1C24;

    font-size: 1.1vw;
    font-weight: 600;
    line-height: 4.45vw;
}
.btn_main:hover{
    color: #ED1C24;
    background: #fff;
}





.floor_slid_wrap{
    position: relative;

    display: block;

    width: 100%;

    font-size: 0;
}

.floor_slid_wrap input{
    display: inline-block;

    box-sizing: border-box;
    width: 50%;
    height: 4.45vw;
    padding-top: 1.4vw;
    padding-left: 1.12vw;

    -webkit-transition: .3s ;
    -o-transition: .3s ;
    transition: .3s ;
    vertical-align: top;

    border:none;
    border: .07vw solid #f5f5f6;
    border-right: .07vw solid #fff;
    border-top-left-radius: .22vw;
    background: #f5f5f6;

    font-size: 1.6vw;
}
.floor_slid_wrap input+input{
    padding-right:  1.12vw;
    padding-left: 0;

    text-align: right;

    border:none;
    border: .07vw solid #f5f5f6;
    border-top-left-radius: 0vw;
    border-top-right-radius: .22vw;
}
.floor_slid_wrap input:focus{border-color: #f5f5f6;background: #fff;}
.floor_slid_wrap input:hover{border-color: #f5f5f6;background: #fff;}











.gf_rooms{
    position: relative;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    margin-top: 2.04vw;

    font-size: 0;

    -ms-align-items: center;
}
.gf_rooms span{
    position: relative;

    display: inline-block;

    box-sizing: border-box;
    padding: 2.04vw 1.65vw .85vw 1.171vw;

    cursor: pointer;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    vertical-align: top;

    color: #2E3443;
    border-top-left-radius: .22vw;
    background: #f5f5f6;

    font-size: 1.6vw;
    line-height: 100%;
}
.gf_rooms span:last-child{
    border-top-right-radius: .22vw;
}
.gf_rooms span:hover{

}
.gf_rooms span+span{
    /*margin-left: .07vw;*/
    padding: 2.04vw 1.5vw .85vw;

    border-top-left-radius: 0px;
}
.gf_rooms span:after{
    position: absolute;
    bottom: 0vw;
    left: 0;

    display: block;

    width: 100%;
    height: 0vw;

    content: '';
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;

    background: #ED1C24;
}
.gf_rooms span:hover:after{
    bottom: -.28vw;

     height: .28vw;

    opacity: .5;
}
.gf_rooms span.act:after{
    bottom: -.28vw;

    height: .28vw;

    opacity: 1;
}
.checkbox_wrap{
    display: inline-block;

    box-sizing: border-box;
    margin-top: 1.6vw;
}
.checkbox_wrap input{
    display: inline-block;

    box-sizing: border-box;
    width: 1.6vw;
    height: 1.6vw;
    margin: 0 auto;

    cursor: pointer;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
    vertical-align: top;

    border:none;
    border: .07vw solid #ED1C24;
    border-radius: 0px;
    background: none;
    background: #fff url(../img/checkbox_ico.svg)no-repeat center center;
    /*-webkit-background-size: .8vw;*/
    /*background-size: .8vw;*/
    -webkit-background-size: 0;
    background-size: 0;

    appearance:none;
}

.checkbox_wrap input:checked{
    background: #ED1C24 url(../img/checkbox_ico.svg)no-repeat center center;
    -webkit-background-size: 1.5vw;
    background-size: 1.5vw;

}
.checkbox_wrap span{
    display: inline-block;

    margin-left: 1.16vw;

    cursor: pointer;
    vertical-align: top;

    font-size: 16px;
    font-size: 1.12vw;
    font-weight: 600;
    /*font-style: italic;*/
    line-height: 1.6vw;
}


.gf_ds{
    position: relative;
    top: 2.8vw;
    left: 2.8vw;

    width: 13.34vw;
    height: 13.34vw;

    text-align: center;
}
.gf_ds .ds_anim{
    background: #ED1C24;
}
.gf_ds_mob{
    display: none;
}
/*get_flat*/




/*advantages*/
.advantages{
    position: relative;

    display: block;
}
.advant_wrap{
    position: relative;

    display: block;

    text-align: center;

    font-size: 0;
}
.advant_sect{
    position: relative;

    display: inline-block;
    overflow: hidden;

    width: 50%;
    height: 29.9vw;

    vertical-align: top;
}
.advant_sect img{
    position: relative;
    z-index: 1;

    display: block;

    min-width: 100%;
    max-width: 150%;
    min-height: 100%;

    -webkit-transition: .8s;
    -o-transition: .8s;
    transition: .8s;
}
.advant_sect:hover img{
    -webkit-transition: 15s;
    -o-transition: 15s;
    transition: 15s;
    transform: scale(1.2);
}
.advant_tit{
    position: absolute;
    z-index: 5;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: left;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-left: 12.6vw;

    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    text-align: left;

    color: #2E3443;

    font-family: 'IskraCYR-BoldItalic';
    font-size: 2.8vw;
    font-weight: 100;
    line-height: 120%;

    -ms-align-items: center;
}
.adv_tit_animate .advant_tit{
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;

    opacity: 1;
    color: #fff;
    background: rgba(46, 52, 67, .35);
}
.advant_tit sup {
    position: relative;
    /*bottom: -2.3vw;*/

    margin-left: 7px;

    font-size: 1.2vw;
    line-height: 0;
}
.adv_tit_animate:hover .advant_tit{
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transform: scale(1);

    opacity: 1;
    background: rgba(46, 52, 67, 0);
        text-shadow: -2px 0px 14px rgba(0,0,0, 1);

    font-size: 2vw;
}
/*advantages*/


/*details*/
.details{
    position: relative;

    display: block;

    box-sizing: border-box;
    padding-top: 150px;

    background: url(../img/details_bg.jpg)no-repeat center top;
    -webkit-background-size: cover;
    background-size: cover;
}
.details .tm_in span{
    display: inline-block;

    vertical-align: top;

    color: #fff;

    font-size: 4.45vw;
 }
 .details .tm_in p{
    display: inline-block;

    margin-left: 11.2vw;

    vertical-align: top;

    color: #fff;
 }
 .details_wrap{
    position: relative;

    display: block;

    box-sizing: border-box;
    width: 100%;
    margin-top: 100px;
    margin-top: 7vw;
    padding-left: 64px;

    font-size: 0;
 }
 .detail_sect{
    position: relative;

    display: inline-block;
    overflow: hidden;

    box-sizing: border-box;
    width: 25%;
    height: 11.95vw;

    vertical-align: top;

    border-top: .1vw solid rgba(255,255,255,.5);
 }
 .detail_sect+ .detail_sect{
    border-left: .1vw solid rgba(255,255,255,.5);
 }
 .detail_sect:nth-child(5), .detail_sect:nth-child(9){
    border-left: 0px;
 }
 .detail_sect span{
    position: relative;
    z-index: 5;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: flex-start;
    justify-content: flex-start;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-top: 4.3vw;
    padding-right: 2vw;
    padding-left: 8vw;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    text-align: left;
    letter-spacing: .05vw;
    /*font-style: italic;*/
    text-transform: uppercase;

    color: #fff;

    font-size: 1vw;
    font-weight: 600;
    line-height: 175%;

    -ms-align-items: flex-start;
 }
 .detail_sect+.detail_sect  span{
    padding-left: 3.85vw;
 }
 .detail_sect:nth-child(5) span, .detail_sect:nth-child(9) span{
    padding-left: 8vw;
 }

 .detail_sect:hover span{
    color: #2E3443;
 }
 .det_bg{
    position: absolute;
    top: 50%;
    left: 0;

    display: block;

    width: 100%;
    height: 100%;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;

    opacity: 0;
    background: #fff;
 }
 .detail_sect:hover .det_bg{
    top: 0;

    opacity: 1;
 }
 .det_ico{
    position: absolute;
    top: -100px;
    right: 0;

    display: block;

    width: 3.34vw;
    height: 3.34vw;

    transition: top .4s ease-out 0s;
 }



 .det_communicat .det_ico{
    background: #FF5C00 url(../img/det_ico.svg)no-repeat center center;
    background-size: 48%;
 }
 .det_system .det_ico{
    background: #FF5C00 url(../img/det_system.svg)no-repeat center center;
    background-size: 48%;
 }
 .det_lift .det_ico{
    background: #FF5C00 url(../img/det_lift.svg)no-repeat center center;
    background-size: 48%;
 }
 .det_rostel .det_ico{
    background: #FF5C00 url(../img/det_rostel.svg)no-repeat center center;
    background-size: 48%;
 }
 .det_designer .det_ico{
    background: #FF5C00 url(../img/det_designer.svg)no-repeat center center;
    background-size: 48%;
 }
 .det_fire .det_ico{
    background: #FF5C00 url(../img/det_fire.svg)no-repeat center center;
    background-size: 48%;
 }
 .det_metall .det_ico{
    background: #FF5C00 url(../img/det_metall.svg)no-repeat center center;
    background-size: 48%;
 }
 .det_balcon .det_ico{
    background: #FF5C00 url(../img/det_balcon.svg)no-repeat center center;
    background-size: 48%;
 }
 .det_ekolog .det_ico{
    background: #FF5C00 url(../img/det_ekolog.svg)no-repeat center center;
    background-size: 48%;
 }
 .det_water .det_ico{
    background: #FF5C00 url(../img/det_water.svg)no-repeat center center;
    background-size: 48%;
 }
 .det_socket .det_ico{
    background: #FF5C00 url(../img/det_socket.svg)no-repeat center center;
    background-size: 48%;
 }
 .det_elect .det_ico{
    background: #FF5C00 url(../img/det_elect.svg)no-repeat center center;
    background-size: 48%;
 }





 .detail_sect:hover .det_ico{
    top: 0;
     /*transition: top .4s ease-out 0.2s, background-size .2s ease-out 0.5s;*/

     transition: top .4s ease-out 0.2s;
 }
/*details*/


/*how_buy*/
.how_buy{
    position: relative;

    display: block;

    padding-top: 90px;
    padding-bottom: 144px;

    background: url(../img/hb_bg.jpg)no-repeat center top;
    -webkit-background-size: cover;
    background-size: cover;
}
.how_buy .tm span{
    color: #fff;
}
.how_buy .tm p{
    color: #fff;
}
.how_buy .dec_square {
    top: 120%;
    right: auto;
    left: 0;
}
.how_buy .dec_square .ds_anim{
     background: #86C502;
}
.hb_list{
    position: relative;
    z-index: 11;

    display: block;
    float: right;

    box-sizing: border-box;
    /*width: 100%;*/
    max-width: 52vw;
    margin-top: -12vw;
    /*padding-left: 12.6vw;*/

    font-size: 0;
}
.hb_sect{
    position: relative;

    display: inline-block;

    width: 20.84vw;
}
.hb_sect + .hb_sect{
    margin-left: 2.23vw;
}
.hb_sect.hb_mat_kap{
    margin-top: 1.04vw;
    margin-left: 0;
}
.hb_wrap{
    display: block;

    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    height: 116px;
    height: 8.06vw;
    padding: 2.04vw;

    background: #fff;
}
.hb_tit{
    display: block;

    text-align: left;

}
.hb_tit img{
    display: inline-block;

    width: 1.95vw;

    vertical-align: top;
}
.hb_tit span{
    display: inline-block;

    padding-left: 1.04vw;

    vertical-align: top;

    color: #2E3443;

    font-size: 20px;
    font-size: 1.4vw;
    font-weight: 600;
    line-height: 1.95vw;
}
.hb_text{
    display: block;

    box-sizing: border-box;
    width: 100%;
    padding-left: 3vw;

    color: #4E5C64;

    font-size: 1.1vw;
    /*font-style: italic;*/
    line-height: 1.95vw;
}
.hb_btn{
    position: relative;

    display: block;

    box-sizing: border-box;
    width: 100%;
    padding-left: 5.04vw;

    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    text-decoration: none;

    color: #2E3443;
    border-top: .07vw solid rgba(46, 52, 67, .1);
    background: #fff;

    font-size: .83vw;
    /*font-style: italic;*/
    font-weight: 600;
    line-height: 56px;
    line-height: 3.9vw;
}
.hb_btn:before {
    position: absolute;
    top: 50%;
    right: 1.6vw;

    display: block;

    width: 3px;
    width: .42vw;
    height: 6px;
    height: .53vw;

    content: '';
    transform: translateY(-50%);

    background: url(../img/hb_btn_ico.svg)no-repeat center center;
    background-size: 100% 100%;
}
.hb_btn:hover{
    color: #ED1C24;
}
/*how_buy*/

/*developer*/
.developer{
    position: relative;

    display: block;
}

.dev_wrap{
    position: relative;

    display: block;

    text-align: center;

    font-size: 0;
}
.dev_sect{
    position: relative;

    display: inline-block;
    overflow: hidden;

    box-sizing: border-box;
    width: 50%;
    height: 31.7vw;
    padding-left: 12.6vw;

    vertical-align: top;
}
.dev_sect+ .dev_sect{
    background: rgba(78, 92, 100, .1);
}
.dev_sect:nth-child(even){
    padding-left: 8.9vw;
}
.dev_tit {
    position: relative;
    bottom: 0;
    left: auto;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: flex-start;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: flex-end;

    box-sizing: border-box;
    width: 100%;
    height: 31.7vw;
    padding-bottom: 5.04vw;

    text-align: left;

    -ms-align-items: flex-start;
    -moz-flex-direction: column;
    -o-flex-direction: column;
}
.dev_tit span{
    display: block;

    text-align: left;
    /*font-style: italic;*/

    color: #2E3443;

    font-family: 'IskraCYR-BoldItalic';
    font-size: 40px;
    font-size: 2.8vw;
    font-weight: normal;
    line-height: 130%;
}
.dev_tit p {
    display: block;
    /*font-style: italic;*/

    margin-top: 12px;
    margin-top: 2.4vh;

    text-align: left;

    color: #4E5C64;

    font-size: 1.6vw;
    font-weight: normal;
    line-height: 140%;
}
.dev_tit img{
    display: block;

    width: 156px;
    width: 10.7vw;
    margin: 0 auto;
    margin-bottom: 2.04vw;
    margin-left: 0;
}
.dev_sect .dec_square{
    position: absolute;
    right: 0;
    bottom: 0;

    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    -moz-flex-direction: column;
    -o-flex-direction: column;
}
.dev_sect .dec_square .ds_anim{
    background: #EE7203;
}

.dev_info{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: flex-start;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;

    box-sizing: border-box;
    width: 28.9vw;
    height: 31.7vw;
    padding-top: 3vw;

    text-align: left;

    -ms-align-items: center;
    -moz-flex-direction: column;
    -o-flex-direction: column;
}
.dev_sub{
    display: block;
    /*font-style: italic;*/

    font-size: 1.6vw;
    font-weight: 600;
    line-height: 140%;
}

.dev_info p{
    display: block;

    margin-top: 1.8vw;
    /*font-style: italic;*/

    color: #4E5C64;

    font-size: 1.12vw;
    line-height: 120%;
}

.dev_doc{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: flex-start;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;

    box-sizing: border-box;
        width: 28.9vw;
    height: 31.7vw;

    text-align: left;

    -ms-align-items: center;
    -moz-flex-direction: column;
    -o-flex-direction: column;

}
.dev_doc p{
    display: block;
    /*font-style: italic;*/

    color: #4E5C64;

    font-size: .9vw;
    line-height: 140%;
}
.dev_red_text{
    display: block;

    box-sizing: border-box;
    width: 100%;
    margin-top: 1.2vw;
    margin-bottom: 1.2vw;
    padding: 1.4vw;
    padding-top: 1.15vw;

    text-align: left;

    color: #fff;
    background: #ED1C24;
    /*font-style: italic;*/

    font-size: 1.25vw;
    font-weight: 600;
}
.dev_doc span{
    display: block;

    margin-top: 1.2vw;
    margin-bottom: .3vw;

    color: #2E3443;
    /*font-style: italic;*/

    font-size: 1.12vw;
    font-weight: 600;
    line-height: 120%;
}
.dev_doc span a{
    display: inline-block;

    vertical-align: top;

    font-size: inherit;
}
.dev_doc span a:before{
    display: none;
}
.dev_doc a{
    position: relative;

    display: inline-block;

    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    text-decoration: none;

    color: #ED1C24;

    font-size: .83vw;
    font-weight: 600;
    /*font-style: italic;*/
    line-height: 140%;
}
.dev_doc a+a{
    margin-top: .3vw;
}
.dev_doc a:before {
    position: absolute;
    top: 50%;
    right: -1.4vw;

    display: block;

    width: 3px;
    width: .42vw;
    height: 6px;
    height: .53vw;

    content: '';
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transform: translateY(-50%);

    background: url(../img/hb_btn_ico.svg)no-repeat center center;
    background-size: 100% 100%;
}
.dev_doc a:hover:before{
    right: -1vw;
}
.dev_doc a:hover{
    opacity: .8;
}
.dev_sect:last-child{
    padding-left: 0;
}
.dev_sect:last-child img{
    position: relative;
    z-index: 1;

   display: block;

    min-width: 100%;
    max-width: 150%;
    min-height: 100%;

    -webkit-transition: .8s;
    -o-transition: .8s;
    transition: .8s;
}
.dev_doc_mob{
    display: none;
}
.dev_doc_desk{
    display: flex;
    flex-direction: column;
}
/*developer*/





/*hod_str*/
.hod_str{
    position: relative;

    display: block;

    padding-top: 80px;
    padding-bottom: 100px;
}
.hds_nav{
    display: block;

    max-width: 100%;
    margin-top:  2.23vw;
    padding-left: 12.6vw;
}
.hds_years{
    display: inline-block;

    vertical-align: top;
}
.hds_nav a{
    display: inline-block;

    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    vertical-align: top;
    text-decoration: none;

    color: #4E5C64;

    font-size: 1vw;
    font-weight: 600;
    line-height: 100%;
}
.hds_nav a+a{
    margin-left: 1.6vw;
}
.hds_nav a:hover{
    color: #ED1C24;
}
.hds_nav a.act{
    color: #ED1C24;
}
.hds_months{
    display: inline-block;

    margin-left: 9.45vw;

    vertical-align: top;
}
.hds_months a{
    color: #4E5C64;
}
.hds_slid{
    display: block;

    width: 100%;
    margin-top:  2.23vw;
}

.hds_in{
    position: relative;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: flex-end;
    align-items: flex-end;
    justify-content: flex-end;

    box-sizing: border-box;
    box-sizing: border-box;
    /*max-width: 23.6vw;*/
    height: auto;
    padding-top: 0vw;
    padding-right: .5vw;

    -ms-align-items: flex-end;
}
.hds_in a{
    display: block;

    width: 100%;
}
.hds_in img{
    position: relative;
    z-index: 3;

    display: block;

    width: auto;
    max-height: 15.3vw;
}
.hds_in:before{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    display: block;

    content: '';

    opacity: 0.1;
    color: #2E3443;

    font-family: 'IskraCYR-Bold';
    font-size: 8.4vw;
    line-height: 50%;
}
/*.hds_september:before{
    content: 'Сентябрь';
}
.hds_oktober:before{
    content: 'Октябрь';
}
.hds_november:before{
    content: 'Ноябрь';
}
.hds_december:before{
    content: 'Декабрь';
}
.hds_january:before{
    content: 'Январь';
}
.hds_february:before{
    content: 'Февраль';
}
.hds_march:before{
    content: 'Март';
}
.hds_april:before{
    content: 'Апрель';
}
.hds_may:before{
    content: 'Май';
}
.hds_june:before{
    content: 'Июнь';
}
.hds_july:before{
    content: 'Июль';
}
.hds_august:before{
    content: 'Август';
}
*/












.hds_slid .slick-prev, .hds_slid .slick-next {
    z-index: 3;
    top: 50%;

    width: 80px;
    width: 5.6vw;
    height: 80px;
    height: 5.6vw;
    margin: 0 auto;
    margin: 0 auto;
    padding: 0px;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    transform: translateY(-50%);

    border-radius: 50%;
}
.hds_slid .slick-prev{
    left: 1%;

    background: rgba(255, 255, 255, 1) url(../img/is_ar_prev_act.svg)no-repeat center left 42%;
    -webkit-background-size: 0.85vw;
    background-size: 0.85vw;
}
.hds_slid .slick-prev:hover{
    background: rgba(255, 255, 255, .8) url(../img/is_ar_prev_act.svg)no-repeat center left 52%;
    -webkit-background-size: 0.85vw;
    background-size: 0.85vw;
}
.hds_slid .slick-next{
    right: 1%;

    background: rgba(255, 255, 255, 1) url(../img/is_ar_next_act.svg)no-repeat center left 52%;
    -webkit-background-size: 0.85vw;
    background-size: 0.85vw;
}
.hds_slid .slick-next:hover{
    background: rgba(255, 255, 255, .8) url(../img/is_ar_next_act.svg)no-repeat center left 52%;
    -webkit-background-size: 0.85vw;
    background-size: 0.85vw;
}
/*hod_str*/




/*action_offer*/
.action_offer{
    position: relative;

    display: block;

    box-sizing: border-box;
}
.ac_wrap{
    position: relative;

    display: block;

    font-size: 0;
    /* text-align: center; */
}
@media(min-width: 1191px) {
    .ac_wrap .ac_inn:nth-child(3) img {
        margin-left: 32px;
    }
}
.ac_inn {
    position: relative;

    display: inline-block;
    overflow: hidden;

    width: 50%;

    vertical-align: top;
}
.ac_tit {
    position: relative;
    /* padding-top: 120px;
    padding-top: 8.4vw; */
    z-index: 4;
    top: 0;
    left: 0;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: flex-start;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: flex-start;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-left: 6.6vw;

    text-align: left;

    -ms-align-items: flex-start;
    -moz-flex-direction: column;
    -o-flex-direction: column;
}
.ac_inn:nth-child(even) .ac_tit {
    /* padding-left: 5vw; */
}
.ac_tit span {
    display: block;

    text-align: left;
    /*font-style: italic;*/

    color: #2E3443;

    font-family: 'IskraCYR-BoldItalic';
    font-size: 40px;
    font-size: 2.8vw;
    font-weight: normal;
    line-height: 100%;
}
.ac_tit p {
    display: block;

    margin-top: 1vw;

    text-transform: uppercase;

    color: #2E3443;
    color: #fff;

    font-size: 1vw;
    /*font-style: italic;*/
    font-weight: 600;
    line-height: 120%;
}
.ac_inn+.ac_inn .ac_tit span{
    color: #fff;
}
.ac_ipot{
    background: #af282d;
}
.ac_sale_150k{
    background: #fec8d0;
}
.ac_rassr_0{
    background: #73cbc0;
}

.ac_inn img{
    display: block;
    /* position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2; */
}

.ac_ipot img {
    right: 18px;

    width: 340px;
    width: 21.65vw;
}
.ac_inn.ac_sale_ny img{
    top: 0;
    right: auto;
    bottom: auto;
    left: 20px;

    width: 347px;
    width: 100%;
    min-height: 100%;
}
.ac_inn.ac_sale_ny .ac_btn{
    margin-top: -80px;
}
.ac_sale_apart img {
    /* width: 450px; */
    width: 100%;
    /* min-height: 100%; */
    /* bottom: 0; */
    /* top: 0;
    left: 0; */
}

.ac_sale_apart.ac_inn .ac_btn {
    position: absolute;
    top: auto;
    bottom: 26%;
    left: 11%;

   margin-top: 0px;
}
.ac_sale_apart.ac_inn:hover .ac_btn {
    top: auto;
    bottom: 24%;

    opacity: 1;
}
.ac_btn{
    position: relative;
    top: 5px;

    display: block;

    width: 192px;
    width: 13.35vw;
    margin-top: 46px;

    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    opacity: 0;
    color: #2E3443;
    border: .07vw solid #fff;
    background: #fff;

    font-size: 2.8vw;
    font-size: 0.91vw;
    line-height:  2.8vw;
}
.ac_btn:hover{
    color: #fff;
    background: none;
}
.ac_inn:hover .ac_btn{
    top: 0;

    opacity: 1;
}
/*action_offer*/



/*contacs*/
.contacs{
    position: relative;

    display: block;
}
.cont_wrap{
    position: relative;

    display: block;

    font-size: 0;
}
.cont_left,.cont_right{
    display: inline-block;

    box-sizing: border-box;
    width: 50%;

    vertical-align: top;
}
.cont_left{
        padding-top: 110px;
        padding-top: 7.6vw;
        padding-bottom: 72px;
        padding-bottom: 5vw;
        padding-left: 12.6vw;

        background: #2E3443;
}
.cont_tm{
    display: block;

    text-align: left;
    /*font-style: italic;*/

    color: #fff;

    font-family: 'IskraCYR-BoldItalic';
    font-size: 40px;
    font-size: 2.8vw;
    font-weight: normal;
    line-height: 100%;
}
.sales_depart{
    margin-top: 5vw;
}
.sd_tit{
    color: #fff;
    /*font-style: italic;*/

    font-size: 1.6vw;
    font-weight: 600;
}
.sd_loc, .sd_phone, .sd_time{
    display: block;
    /*font-style: italic;*/

    color: #fff;

    font-size: 1.12vw;
    line-height: 140%;
}
.sd_loc {
    margin-top: 1.12vw;
}
.sd_phone{
    color: #ED1C24;
}
.sd_phone a{
    text-decoration: none;

    color: #ED1C24;
}
.sd_time{
    margin-top: .83vw;
    /*opacity: .5;*/

    color: #ED1C24;
    /*font-size: 1.4vw;*/
}

.sd_quest{
    display: block;

    margin-top: 3.9vw;
}
.sd_quest p{
    display: block;

    color: #fff;

    font-size: 1.6vw;
    font-weight: 600;
    /*font-style: italic;*/
    line-height: 140%;
}
.quest_btn{
    display: block;

    box-sizing: border-box;
    width: 13.35vw;
    height: 4.45vw;
    margin-top: 1.6vw;

    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;

    color: #ED1C24;
    border: 1px solid #ED1C24;
    border-radius: .22vw;

    font-size: 1.1vw;
    font-weight: 600;
    line-height: 4.45vw;
}
.quest_btn:hover{
    color: #fff;
    background: #ED1C24;
}
.policy{
    display: block;

    max-width: 21vw;
    margin-top: 1.04vw;

    color: #d5d6d9;

    font-size: .9vw;
    line-height: 100%;
}
.policy a{
    color: #ED1C24;
}

.cont_right{
    position: absolute;
    top: 0;
    right: 0;

    height: 100%;

    font-size: 0;
}
.cont_map{
    position: relative;

    display: block;

    width: 100%;
    height: 372px;
    height: 60%;
}
.cont_family, .cont_mail{
    display: inline-block;

    width: 50%;
    height: 40%;

    vertical-align: top;
}
.cont_family{
    box-sizing: border-box;
    padding-left: 3vw;

    background: #FF5C00;
}
.cont_mail{
    background: #fff;
}
.cfm_wrap{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: flex-end;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-bottom: 1.6vw;

    text-align: center;

    -ms-align-items: center;
    -moz-flex-direction: column;
    -o-flex-direction: column;
}

.cont_family .cfm_wrap{
    align-items: flex-start;

    text-align-last: left;
}

.cfm_wrap > img {
    display: block;
    /*margin-left: 0;*/

    width: 156px;
    width: 10.7vw;
    margin: 0 auto;
    margin-bottom:  4.1vw;
}
.cont_mail .cfm_wrap{
    padding-bottom: 1.5vw;
}
.cfm_tit{
    display: block;

    color: #fff;
    /*font-style: italic;*/

    font-size: 1.6vw;
    font-weight: 600;
}
.cfm_loc, .cfm_phone, .cfm_time, .cfm_mail{
    display: block;
    /*font-style: italic;*/

    color: #fff;

    font-size: 1.12vw;
    line-height: 140%;
}
.cfm_loc {
    margin-top: 1.12vw;
}
.cfm_phone{
    color: #fff;
}
.cfm_phone a{
    text-decoration: none;

    color: #fff;
}
.cfm_time{
    margin-top: .83vw;

    opacity: 1;
    color: #fff;
}
.cfm_mail{
    font-weight: 600;
}

.develop_info{
    display: block;

    text-align: center;

    font-size: 0;
}
.develop_info span{
    display: inline-block;

    margin-right: 1.12vw;

    text-align: right;

    opacity: 0.5;
    color: #000;

    font-size: 1.04vw;
    line-height: 120%;
}
.develop_info img{
    width: 7vw;
}
/*contacs*/



/*popup_rgba*/

.popup_rgba {
    position: fixed;
    z-index: 99999999;
    top: 0;
    left: 0;

    overflow: hidden;
        overflow-y: scroll;
    -ms-overflow-y: scroll;

    width: 100%;
    height: 100%;

    background: url("../img/popup_rgba.png");
}

    .popup_cell {
        display: table-cell;

        vertical-align: middle;
    }
    .popup_table {
        position: absolute;
        top: 0;
        left: 0;

        display: table;

        width: 100%;
        height: 100%;
    }
    .closeform {
    position: absolute;
    z-index: 999999;
    top: 1vw;
    right: 1vw;

    display: block;

    width: 1vw;
    height: 1vw;

    background: url(../img/closeform.svg) no-repeat center center;
    -webkit-background-size: cover;
    background-size: cover;
}
    .closeform:hover{opacity:  0.5;}


   .popup_main {
        position: relative;

        box-sizing: border-box;
        max-width: 416px;
        max-width: 28.9vw;
        margin: 0 auto;
        margin-top: 50px;
        margin-bottom: 70px;
        padding: 4.5vh 2.04vw;

        background: #FFF;
    }

.pu_tm{
    display: block;

    color: #2E3443;

    font-family: 'IskraCYR-BoldItalic';
    font-size: 2.8vw;
    line-height: 130%;
}
.pu_tm p{
    font-size: 1.52vw;
    line-height: 100%;
}
.renting_date {
    display: block;

    font-size: 2.2vw;
    line-height: 100%;
}
.pu_form{
    display: block;

    box-sizing: border-box;
    width: 100%;
    margin-top: 2.5vw;
}
.pu_form .pu_pl{
    width: 100%;
}
.pu_form .pu_pl+.pu_pl{
    margin: 0 auto;
    margin-top: 1vw;
}
.pu_form .pu_pl:before {
    background: rgba(36,53,63, .05);
}
.pu_form .pu_pl input{
    padding-top: 1vw;
}
.pu_form .pu_pl .input_label_content {
     top: 8px;

    color: #2E3443;
    }
.pu_form .pu_pl:after{
    background: #2E3443;
}

.pu_form .input_act.pu_pl:after{
    background: #2E3443;
}





.react_textarea .MuiInputBase-multiline{
    height: 4.45vw;
    padding: 0;
}
.react_textarea.MuiFormControl-root{
    width: 100%;
}
.react_textarea.MuiFormLabel-filled .MuiInputBase-input{

}




.jq-selectbox{
    position: relative;

    display: block;

    width: 100%;
    margin-top: 1vw;
}
.jq-selectbox:after {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 2px;

    content: '';
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;

    background: #2E3443;
}
.jq-selectbox.opened:after {
    height: 4px;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;

    background: #2E3443;
}

.jq-selectbox:before{
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;

    display: block;

    width: 100%;
    height: 0;

    content: '';
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;

    border-top-left-radius: .22vw;
    border-top-right-radius: .22vw;
        background: rgba(36,53,63, .05);
}

.jq-selectbox.opened:before{
    height: 100%;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
}
.jq-selectbox__dropdown ul {
    box-sizing: border-box;
    height:  10vw;
    max-height:  10vw;
    padding:  1vw;
}
.jq-selectbox__select {
    display: block;

    box-sizing: border-box;
    width: 100%;
    height: 4.45vw;
    padding-left:  1.25vw;

    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    vertical-align: top;

    color: #2E3443;
    border: none;
    /*border-bottom: 1px solid #ebebeb!important;*/
    border-radius: 0px;
    background: none;
    box-shadow: none!important;
    text-shadow: none!important;

    font: 1.6vw/4.45vw 'futurabookc';
    font-weight: 400;
}


.jq-selectbox__select:active{
    background: none;
}
.jq-selectbox.focused .jq-selectbox__select {
    /*border-bottom: 4px solid #009ee3!important;*/
    color: #2E3443;
    border: none;
}
.jq-selectbox.changed .jq-selectbox__select {
    /*border-bottom: 1px solid #009ee3!important;*/
    color: #2E3443;
    border: none;
}
.jq-selectbox.focused .jq-selectbox__select {
    /*border-bottom: 4px solid #009ee3!important;*/
    color: #2E3443;
}
.jq-selectbox.dropdown.opened  .jq-selectbox__select {
    /*border-bottom: 4px solid #009ee3!important;*/
    color: #2E3443;
}
.jq-selectbox__select-text{
    box-sizing: border-box;
    width: 100% !important;
    height: 4.45vw;
    padding-top: 0.5vw;
}

.jq-selectbox__trigger {
    top: 50%;
    right: 12px;

    width: 19px;
    height: 10px;

    border: none;
}
.jq-selectbox__trigger-arrow {
    position: absolute;
    top: 0;
    right: 0;

    width: 100%;
    height: 100%;

    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;

    border: none;
    background: url(../img/select_ar.svg)no-repeat center top;
}
.jq-selectbox__select:hover{
    background: none;
}
.jq-selectbox.dropdown.opened .jq-selectbox__trigger-arrow {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transform: scale(1, -1);

    background: url(../img/select_ar_act.svg)no-repeat center top;
}
.jq-selectbox__dropdown{
    top: 100% !important;

    margin: 0 auto;

    border-radius: 0px;

    font: .83vw/1.1vw 'futurabookc';
}





.pu_thx .pu_tm p{
margin-top: 1.7vw;

color: #ED1C24;
}








.pu_slid{
    position: relative;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: flex-end;

    width: 100%;
    height: 4.45vw;

    border-top-left-radius: .22vw;
    border-top-right-radius: .22vw;
    background: #f5f5f6;

    -ms-align-items: center;
    -moz-flex-direction: column;
    -o-flex-direction: column;
}
.pu_slid_rooms.pu_slid{
    display: flex;
}
.pu_calc .pu_pl{
    margin-top: 1vw;
}


.single_slid_style.slid_style .ui-slider-handle {
    top: -2.8vw;

    display: inline-block;

    width: 4.6vw;
    height: auto;
    margin: 0 auto;
    margin-left: -2.3vw;

    text-align: center;

    border-radius: 0px;
    background: none;

    font-size: 1.6vw;
}
.single_slid_style.slid_style .ui-slider-handle:before {
    top: 2.05vw;
    left: 50%;

    width: 1.68vw;
    height: 1.68vw;

    transform: translateX(-50%);

    opacity: 1;
    border-radius: 50%;
    background: #ED1C24;
}




.pu_flat{
    max-width: 850px;
    max-width: 59.05vw;
    padding: 3.8vw 2.3vw 2.2vw;
}
.flat_area{
    display: block;

    margin-top: .55vw;

    font-size: 0;
}
.flat_area span{
    display: inline-block;

    vertical-align: top;

    font-size: 1.5vw;
    line-height: 100%;
}
.fl_living_area{
    margin-left: 2.8vw;
}
.flat_area span sup{
    font-size: .7vw;
    line-height: 0;
}
.pu_flat_wrap{
    position: relative;

    display: block;

    box-sizing: border-box;
    margin-top:  4.7vw;

    font-size: 0;
}
.pu_flat_img {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    width: 385px;
    width: 26.8vw;
    height: 350px;
    height: 23.8vw;

    -ms-align-items: center;
}
.pu_flat_img img{
    display: block;

    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
}
.pfi_in{
    display: inline-block;
    float: right;

    vertical-align: top;
}
.pu_flat .pu_form {
    display: inline-block;
    float: left;

    width: 24.5vw;
    margin-top: 0;

    vertical-align: top;
}
.pu_form_tit{
    display: block;

    box-sizing: border-box;
    margin-bottom: 2.5vw;

    color: #ED1C24;

    font-family: 'IskraCYR-BoldItalic';
    font-size: 2.04vw;
    line-height: 100%;
}

.pu_tm span{
    display: block;

    font-family: 'futurabookc';
    font-size: 22px;
    font-size: 1.5vw;
    line-height: 100%;
}








.pu_floor{
    max-width: 850px;
    max-width: 59.05vw;
    padding: 3.8vw 2.3vw 2.2vw;
}

.pu_floor_nav{
    position: relative;

    display: block;

    margin-top: 2.22vw;

    font-size: 0;
}
.pu_slid{
    display: inline-block;

    width: 24.5vw;

    vertical-align: top;
}
.pu_slid_wrap{
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: flex-end;

    height: 4.45vw;

    -ms-align-items: center;
    -moz-flex-direction: column;
    -o-flex-direction: column;
}
.pu_entrace_wrap{
    display: inline-block;

    width: auto;
    margin-left: 2.22vw;

    vertical-align: top;
}
.pu_entrance{
    position: relative;

    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: flex-end;

    width: 100%;

    font-size: 0;

    -ms-align-items: center;
}
.pu_entrance span{
    position: relative;

    display: inline-block;

    box-sizing: border-box;
    padding: 2.04vw 3.65vw .85vw 3.171vw;

    cursor: pointer;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    vertical-align: top;

    color: #2E3443;
    border-top-left-radius: .22vw;
    background: #f5f5f6;

    font-size: 1.6vw;
    line-height: 100%;
}
.pu_entrance span:last-child{
    border-top-right-radius: .22vw;
}
.pu_entrance span:hover{

}
.pu_entrance span+span{
    /*margin-left: .07vw;*/
    padding: 2.04vw 1.5vw .85vw;

    border-top-left-radius: 0px;
}
.pu_entrance span:after{
    position: absolute;
    bottom: 0vw;
    left: 0;

    display: block;

    width: 100%;
    height: 0vw;

    content: '';
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;

    background: #ED1C24;
}
.pu_entrance span:hover:after{
    bottom: -.28vw;

     height: .28vw;

    opacity: .5;
}
.pu_entrance span.act:after{
    bottom: -.28vw;

    height: .28vw;

    opacity: 1;
}
.pu_floor_img{
    position: relative;

    display: block;

    box-sizing: border-box;
    width: 100%;
    margin-top: 4.2vw;
}
.pu_floor_img img{
    display: block;

    width: 100%;
}
.floor_img_flat{
    position: absolute;

    display: block;

    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;

    opacity: 0;
    /*background: rgba(0,0,0, .2);*/
    background: url(../img/loop.svg)no-repeat center center;
    -webkit-background-size: 0;
    background-size: 0;
}
.floor_img_flat:hover{
    opacity: 1;
    -webkit-background-size:  1.9vw;
    background-size:  1.9vw;
}

.fl_1 {
    top: 11%;
    left: 2%;

    width: 15%;
    height: 32%;
}
.fl_2 {
    top: 4%;
    left: 16%;

    width: 12%;
    height: 39%;
}
.fl_3 {
    top: 9%;
    left: 28%;

    width: 26%;
    height: 33%;
}
.fl_4 {
    top: 9%;
    left: 54%;

    width: 26%;
    height: 36%;
}
.fl_5 {
    top: 9%;
    left: 50%;

    width: 25%;
    height: 36%;
}
.fl_6 {
    top: 9%;
    right: 1%;

    width: 20%;
    height: 36%;
}
.fl_7 {
    top: 45%;
    right: 1%;

    width: 12%;
    height: 43%;
}
.fl_8 {
    top: 52%;
    right: 13%;

    width: 26%;
    height: 34%;
}
.fl_9 {
    top: 52%;
    left: 30%;

    width: 19%;
    height: 34%;
}
.fl_10 {
    top: 52%;
    left: 20%;

    width: 11%;
    height: 34%;
}
.fl_11 {
    top: 42%;
    left: 1%;

    width: 19.5%;
    height: 45%;
}
.fl_12 {
    top: 11%;
    left: 1%;

    width: 20%;
    height: 36%;
}

.fl_13 {
    top: 11%;
    left: 20.5%;

    width: 25%;
    height: 32%;
}

.fl_14 {
    top: 12%;
    left: 46%;

    width: 26%;
    height: 31%;
}
.fl_15 {
    top: 9%;
    right: 16%;

    width: 12%;
    height: 33%;
}
.fl_16 {
    top: 9%;
    right: 2%;

    width: 15%;
    height: 34%;
}
.fl_17 {
    top: 42%;
    right: 2%;

    width: 18%;
    height: 45%;
}
.fl_18 {
    top: 52%;
    right: 20%;

    width: 11%;
    height: 36%;
}
.fl_19 {
    top: 52%;
    right: 31%;

    width: 18%;
    height: 35%;
}
.fl_20 {
    top: 52%;
    left: 13%;

    width: 26%;
    height: 34%;
}
.fl_21 {
    top: 46%;
    left: 2%;

    width: 11%;
    height: 40%;
}

.fl_22 {
    top: 12%;
    left: 1%;

    width: 10%;
    height: 39%;
}
.fl_23 {
    top: 12%;
    left: 12%;

    width: 26%;
    height: 33%;
}
.fl_24 {
    top: 9%;
    left: 50%;

    width: 19%;
    height: 36%;
}
.fl_25 {
    top: 9%;
    left: 69%;

    width: 10%;
    height: 36%;
}
.fl_26 {
    top: 9%;
    right: 1%;

    width: 20%;
    height: 45%;
}
.fl_27 {
    top: 54%;
    right: 2%;

    width: 15%;
    height: 32%;
}
.fl_28 {
    top: 52%;
    right: 16%;

    width: 13%;
    height: 34%;
}
.fl_29 {
    top: 52%;
    left: 44%;

    width: 27%;
    height: 34%;
}
.fl_30 {
    top: 52%;
    left: 20%;

    width: 25%;
    height: 34%;
}
.fl_31 {
    top: 51%;
    left: 1%;

    width: 19.5%;
    height: 35%;
}
.fl_32 {
    top: 11%;
    left: 1%;

    width: 20%;
    height: 44%;
}

.fl_33 {
    top: 11%;
    left: 20.5%;

    width: 11%;
    height: 35%;
}

.fl_34 {
    top: 15%;
    left: 31%;

    width: 19%;
    height: 31%;
}
.fl_35 {
    top: 9%;
    right: 12%;

    width: 26%;
    height: 40%;
}
.fl_36 {
    top: 9%;
    right: 0%;

    width: 12%;
    height: 44%;
}
.fl_37 {
    top: 52%;
    right: 0%;

    width: 19%;
    height: 38%;
}
.fl_38 {
    top: 52%;
    right: 20%;

    width: 25%;
    height: 36%;
}
.fl_39 {
    top: 55%;
    right: 45%;

    width: 26%;
    height: 35%;
}
.fl_40 {
    top: 54%;
    left: 16%;

    width: 13%;
    height: 34%;
}
.fl_41 {
    top: 54%;
    left: 2%;

    width: 16%;
    height: 40%;
}

.fl_42 {
    top: 11%;
    left: 8%;

    width: 18%;
    height: 36%;
}

.fl_43 {
    top: 14%;
    left: 25.5%;

    width: 25%;
    height: 32%;
}

.fl_44 {
    top: 12%;
    left: 50%;

    width: 17%;
    height: 32%;
}
.fl_45 {
    top: 13%;
    right: 22%;

    width: 12%;
    height: 33%;
}
.fl_46 {
    top: 12%;
    right: 10%;

    width: 13%;
    height: 34%;
}
.fl_47 {
    top: 20%;
    right: 2%;

    width: 10%;
    height: 70%;
}
.fl_48 {
    top: 54%;
    right: 10%;

    width: 17%;
    height: 36%;
}
.fl_49 {
    top: 52%;
    right: 27%;

    width: 18%;
    height: 35%;
}

.fl_50 {
    top: 55%;
    left: 26%;

    width: 16%;
    height: 34%;
}
.fl_51 {
    top: 46%;
    left: 2%;

    width: 24%;
    height: 40%;
}

.fl_52 {
    top: 11%;
    left: 11%;

    width: 13%;
    height: 36%;
}

.fl_53 {
    top: 11%;
    left: 23.5%;

    width: 11%;
    height: 34%;
}

.fl_54 {
    top: 12%;
    left: 34%;

    width: 17%;
    height: 34%;
}
.fl_55 {
    top: 9%;
    right: 24%;

    width: 25%;
    height: 36%;
}
.fl_56 {
    top: 11%;
    right: 7%;

    width: 17%;
    height: 34%;
}
.fl_57 {
    top: 43%;
    right: 2%;

    width: 23%;
    height: 45%;
}
.fl_58 {
    top: 52%;
    right: 25%;

    width: 16%;
    height: 36%;
}
.fl_59 {
    top: 52%;
    right: 54%;

    width: 18%;
    height: 35%;
}

.fl_60 {
    top: 52%;
    left: 12%;

    width: 16%;
    height: 34%;
}
.fl_61 {
    top: 10%;
    left: 2%;

    width: 11%;
    height: 80%;
}

.fl_62 {
    top: 11%;
    left: 10%;

    width: 17%;
    height: 36%;
}

.fl_63 {
    top: 13%;
    left: 26.5%;

    width: 19%;
    height: 32%;
}

.fl_64 {
    top: 14%;
    left: 58%;

    width: 16%;
    height: 31%;
}
.fl_65 {
    top: 9%;
    right: 1%;

    width: 25%;
    height: 47%;
}
.fl_66 {
    top: 56%;
    right: 6%;

    width: 20%;
    height: 34%;
}
.fl_67 {
    top: 54%;
    right: 25%;

    width: 25%;
    height: 45%;
}
.fl_68 {
    top: 52%;
    right: 50%;

    width: 16%;
    height: 36%;
}
.fl_69 {
    top: 52%;
    right: 66%;

    width: 11%;
    height: 35%;
}

.fl_70 {
    top: 52%;
    left: 10%;

    width: 12%;
    height: 36%;
}
.fl_71 {
    top: 15%;
    left: 0%;

    width: 11%;
    height: 70%;
}

.fl_72 {
    top: 11%;
    left: 2%;

    width: 25%;
    height: 44%;
}

.fl_73 {
    top: 13%;
    left: 26.5%;

    width: 16%;
    height: 32%;
}

.fl_74 {
    top: 14%;
    left: 56%;

    width: 18%;
    height: 31%;
}
.fl_75 {
    top: 9%;
    right: 10%;

    width: 16%;
    height: 35%;
}
.fl_76 {
    top: 12%;
    right: 1%;

    width: 10%;
    height: 74%;
}
.fl_77 {
    top: 54%;
    right: 10%;

    width: 12%;
    height: 45%;
}

.fl_78 {
top: 52%;
    right: 22%;

width: 10%;
height: 36%;
}

.fl_79 {
    top: 52%;
    right: 33%;

    width: 16%;
    height: 36%;
}
.fl_80 {
    top: 52%;
    right: 49%;

    width: 25%;
    height: 35%;
}

.fl_81 {
    top: 52%;
    left: 8%;

    width: 18%;
    height: 36%;
}

.fl_82 {
    top: 0%;
    left: 0%;

    width: 25%;
    height: 52%;
}
.fl_83 {
    top: 0%;
    left: 25%;

    width: 18%;
    height: 44%;
}
.fl_84 {
    top: 0%;
    left: 55%;

    width: 27%;
    height: 44%;
}
.fl_85 {
    top: 4%;
    left: 82%;

    width: 17%;
    height: 87%;
}
.fl_86 {
    top: 55%;
    left: 66%;

    width: 16%;
    height: 37%;
}
.fl_87 {
    top: 55%;
    left: 49%;

    width: 17%;
    height: 37%;
}
.fl_88 {
    top: 55%;
    left: 24%;

    width: 24%;
    height: 37%;
}
.fl_89 {
    top: 55%;
    left: 4%;

    width: 20%;
    height: 42%;
}


.fl_90 {
    top: 0%;
    left: 0%;

    width: 18%;
    height: 92%;
}
.fl_91 {
    top: 0%;
    left: 18%;

    width: 27%;
    height: 45%;
}
.fl_92 {
    top: 0%;
    left: 57%;

    width: 18%;
    height: 45%;
}
.fl_93 {
    top: 0%;
    left: 75%;

    width: 24%;
    height: 53%;
}
.fl_94 {
    top: 54%;
    left: 77%;

    width: 19%;
    height: 45%;
}
.fl_95 {
    top: 54%;
    left: 52%;

    width: 24%;
    height: 45%;
}
.fl_96 {
    top: 54%;
    left: 35%;

    width: 17%;
    height: 45%;
}
.fl_97 {
    top: 54%;
    left: 18%;

    width: 16%;
    height: 45%;
}

.fl_98 {
    top: 11%;
    left: 1%;

    width: 17%;
    height: 84%;
}

.fl_99 {
    top: 9%;
    left: 10%;

    width: 20%;
    height: 33%;
}
.fl_100 {
    top: 9%;
    left: 30%;

    width: 31%;
    height: 33%;
}
.fl_101 {
    top: 9%;
    left: 60%;

    width: 20%;
    height: 33%;
}
.fl_102 {
    top: 9%;
    left: 80%;

    width: 19%;
    height: 77%;
}
.fl_103 {
    top: 53%;
    left: 55%;

    width: 27%;
    height: 43%;
}
.fl_104 {
    top: 53%;
    left: 18%;

    width: 19%;
    height: 43%;
}






.fl_105  {
    top: 11%;
    left: 1%;

    width: 17%;
    height: 84%;
}

.fl_106  {
    top: 9%;
    left: 10%;

    width: 20%;
    height: 33%;
}
.fl_107  {
    top: 9%;
    left: 30%;

    width: 31%;
    height: 33%;
}
.fl_108  {
    top: 9%;
    left: 60%;

    width: 20%;
    height: 33%;
}
.fl_109  {
    top: 9%;
    left: 80%;

    width: 19%;
    height: 77%;
}
.fl_110  {
    top: 53%;
    left: 55%;

    width: 27%;
    height: 43%;
}
.fl_111 {
    top: 53%;
    left: 18%;

    width: 19%;
    height: 43%;
}








.fl_112  {
    top: 11%;
    left: 1%;

    width: 17%;
    height: 84%;
}

.fl_113 {
    top: 9%;
    left: 10%;

    width: 20%;
    height: 33%;
}
.fl_114 {
    top: 9%;
    left: 30%;

    width: 31%;
    height: 33%;
}
.fl_115  {
    top: 9%;
    left: 60%;

    width: 20%;
    height: 33%;
}
.fl_116  {
    top: 9%;
    left: 80%;

    width: 19%;
    height: 77%;
}
.fl_117  {
    top: 53%;
    left: 55%;

    width: 27%;
    height: 43%;
}
.fl_118 {
    top: 53%;
    left: 18%;

    width: 19%;
    height: 43%;
}








.fl_119  {
    top: 11%;
    left: 1%;

    width: 17%;
    height: 84%;
}

.fl_120 {
    top: 9%;
    left: 10%;

    width: 20%;
    height: 33%;
}
.fl_121 {
    top: 9%;
    left: 30%;

    width: 31%;
    height: 33%;
}
.fl_122  {
    top: 9%;
    left: 60%;

    width: 20%;
    height: 33%;
}
.fl_123  {
    top: 9%;
    left: 80%;

    width: 19%;
    height: 77%;
}
.fl_124  {
    top: 53%;
    left: 55%;

    width: 27%;
    height: 43%;
}
.fl_125 {
    top: 53%;
    left: 18%;

    width: 19%;
    height: 43%;
}

.fl_126  {
    top: 11%;
    left: 1%;

    width: 17%;
    height: 84%;
}

.fl_127 {
    top: 9%;
    left: 10%;

    width: 20%;
    height: 33%;
}
.fl_128 {
    top: 9%;
    left: 30%;

    width: 31%;
    height: 33%;
}
.fl_129  {
    top: 9%;
    left: 60%;

    width: 20%;
    height: 33%;
}
.fl_130  {
    top: 9%;
    left: 80%;

    width: 19%;
    height: 77%;
}
.fl_131  {
    top: 53%;
    left: 55%;

    width: 27%;
    height: 43%;
}
.fl_132 {
    top: 53%;
    left: 18%;

    width: 19%;
    height: 43%;
}






.fl_133  {
    top: 11%;
    left: 1%;

    width: 17%;
    height: 84%;
}

.fl_134 {
    top: 9%;
    left: 10%;

    width: 20%;
    height: 33%;
}
.fl_135 {
    top: 9%;
    left: 30%;

    width: 31%;
    height: 33%;
}
.fl_136  {
    top: 9%;
    left: 60%;

    width: 20%;
    height: 33%;
}
.fl_137  {
    top: 9%;
    left: 80%;

    width: 19%;
    height: 77%;
}
.fl_138  {
    top: 53%;
    left: 55%;

    width: 27%;
    height: 43%;
}
.fl_139 {
    top: 53%;
    left: 18%;

    width: 19%;
    height: 43%;
}

.fl_140  {
    top: 0%;
    left: 0%;

    width: 25%;
    height: 53%;
}
.fl_141  {
    top: 0%;
    left: 25%;

    width: 18%;
    height: 45%;
}
.fl_142  {
    top: 0%;
    left: 55%;

    width: 27%;
    height: 45%;
}

.fl_143  {
    top: 0%;
    left: 82%;

    width: 17%;
    height: 92%;
}
.fl_144  {
    top: 54%;
    left: 66%;

    width: 16%;
    height: 37%;
}
.fl_145  {
    top: 54%;
    left: 49%;

    width: 17%;
    height: 37%;
}
.fl_146  {
    top: 54%;
    left: 24%;

    width: 24%;
    height: 37%;
}
.fl_147  {
    top: 54%;
    left: 4%;

    width: 20%;
    height: 41%;
}






.fl_148 {
    top: 0%;
    left: 0%;

    width: 20%;
    height: 71%;
}

.fl_149 {
    top: 0%;
    left: 21%;

    width: 19%;
    height: 44%;
}
.fl_150 {
    top: 0%;
    left: 55%;

    width: 12%;
    height: 44%;
}
.fl_151 {
    top: 0%;
    left: 68%;

    width: 21%;
    height: 44%;
}
.fl_152 {
    top: 0%;
    left: 89%;

    width: 10%;
    height: 90%;
}
.fl_153 {
    top: 54%;
    left: 76%;

    width: 12%;
    height: 37%;
}
.fl_154 {
    top: 54%;
    left: 48%;

    width: 28%;
    height: 37%;
}
.fl_155 {
    top: 54%;
    left: 24%;

    width: 23%;
    height: 37%;
}
.fl_156 {
    top: 54%;
    left: 12%;

    width: 12%;
    height: 37%;
}



.fl_157 {
    top: 0%;
    left: 0%;

    width: 12%;
    height: 90%;
}
.fl_158 {
    top: 0%;
    left: 11%;

    width: 22%;
    height: 44%;
}
.fl_159 {
    top: 0%;
    left: 33%;

    width: 12%;
    height: 44%;
}
.fl_160 {
    top: 0%;
    left: 60%;

    width: 20%;
    height: 44%;
}
.fl_161 {
    top: 4%;
    left: 80%;

    width: 19%;
    height: 70%;
}
.fl_162 {
    top: 54%;
    left: 76%;

    width: 12%;
    height: 38%;
}
.fl_163 {
    top: 54%;
    left: 53%;

    width: 23%;
    height: 38%;
}
.fl_164 {
    top: 54%;
    left: 24%;

    width: 28%;
    height: 38%;
}
.fl_165 {
    top: 54%;
    left: 12%;

    width: 12%;
    height: 38%;
}



.fl_166 {
    top: 3%;
    left: 0%;

    width: 18%;
    height: 90%;
}
.fl_167 {
    top: 3%;
    left: 18%;

    width: 27%;
    height: 41%;
}
.fl_168 {
    top: 3%;
    left: 58%;

    width: 17%;
    height: 41%;
}
.fl_169 {
    top: 2%;
    left: 75%;

    width: 24%;
    height: 47%;
}
.fl_170 {
    top: 54%;
    left: 76%;

    width: 20%;
    height: 44%;
}
.fl_171 {
    top: 54%;
    left: 52%;

    width: 24%;
    height: 44%;
}
.fl_172 {
    top: 54%;
    left: 34%;

    width: 17%;
    height: 44%;
}
.fl_173 {
    top: 54%;
    left: 18%;

    width: 16%;
    height: 44%;
}

.fl_174 {
    top: 11%;
    left: 1%;

    width: 16%;
    height: 81%;
}
.fl_175 {
    top: 11%;
    left: 16%;

    width: 13%;
    height: 35%;
}
.fl_176 {
    top: 11%;
    left: 29%;

    width: 17%;
    height: 35%;
}
.fl_177 {
    top: 10%;
    left: 46%;

    width: 17%;
    height: 36%;
}
.fl_178 {
    top: 10%;
    left: 62%;

    width: 12%;
    height: 36%;
}
.fl_179 {
    top: 10%;
    left: 74%;

    width: 17%;
    height: 36%;
}
.fl_180 {
    top: 47%;
    left: 83%;

    width: 17%;
    height: 45%;
}
.fl_181 {
    top: 56%;
    left: 60%;

    width: 23%;
    height: 45%;
}
.fl_182 {
    top: 56%;
    left: 17%;

    width: 25%;
    height: 45%;
}





.fl_183 {
    top: 11%;
    left: 1%;

    width: 16%;
    height: 81%;
}
.fl_184 {
    top: 11%;
  left: 9%;

    width: 17%;
    height: 35%;
}
.fl_185 {
    top: 11%;
       left: 26%;

    width: 12%;
    height: 35%;
}
.fl_186 {
    top: 10%;
    left: 38%;

    width: 16%;
    height: 36%;
}
.fl_187 {
    top: 10%;
   left: 54%;

    width: 18%;
    height: 36%;
}
.fl_188 {
    top: 10%;
    left: 72%;

    width: 11%;
    height: 36%;
}
.fl_189 {
    top: 6%;
    left: 83%;

    width: 17%;
    height: 88%;
}
.fl_190 {
    top: 56%;
    left: 60%;

    width: 23%;
    height: 45%;
}
.fl_191 {
    top: 56%;
    left: 17%;

    width: 25%;
    height: 45%;
}







.fl_192 {
    top: 11%;
    left: 1%;

    width: 16%;
    height: 81%;
}
.fl_193 {
    top: 11%;
    left: 16%;

    width: 13%;
    height: 35%;
}
.fl_194 {
    top: 11%;
    left: 29%;

    width: 17%;
    height: 35%;
}
.fl_195 {
    top: 10%;
    left: 46%;

    width: 17%;
    height: 36%;
}
.fl_196 {
    top: 10%;
    left: 62%;

    width: 12%;
    height: 36%;
}
.fl_197 {
    top: 10%;
    left: 74%;

    width: 17%;
    height: 36%;
}
.fl_198 {
    top: 10%;
    left: 90%;

    width: 10%;
    height: 83%;
}
.fl_199 {
    top: 56%;
    left: 60%;

    width: 30%;
    height: 45%;
}
.fl_200 {
    top: 56%;
    left: 17%;

    width: 25%;
    height: 45%;
}






.fl_201 {
    top: 11%;
    left: 1%;

    width: 16%;
    height: 81%;
}
.fl_202 {
    top: 11%;
    left: 16%;

    width: 13%;
    height: 35%;
}
.fl_203 {
    top: 11%;
    left: 29%;

    width: 17%;
    height: 35%;
}
.fl_204 {
    top: 10%;
    left: 46%;

    width: 17%;
    height: 36%;
}
.fl_205 {
    top: 10%;
    left: 62%;

    width: 12%;
    height: 36%;
}
.fl_206 {
    top: 10%;
    left: 74%;

    width: 17%;
    height: 36%;
}
.fl_207 {
    top: 10%;
    left: 90%;

    width: 10%;
    height: 83%;
}
.fl_208 {
    top: 56%;
    left: 60%;

    width: 30%;
    height: 45%;
}
.fl_209 {
    top: 56%;
    left: 17%;

    width: 25%;
    height: 45%;
}














.fl_210 {
    top: 11%;
    left: 1%;

    width: 8%;
    height: 81%;
}
.fl_211 {
    top: 11%;
  left: 9%;

    width: 17%;
    height: 35%;
}
.fl_212 {
    top: 11%;
       left: 26%;

    width: 12%;
    height: 35%;
}
.fl_213 {
    top: 10%;
    left: 38%;

    width: 16%;
    height: 36%;
}
.fl_214 {
    top: 10%;
   left: 54%;

    width: 18%;
    height: 36%;
}
.fl_215 {
    top: 10%;
    left: 72%;

    width: 11%;
    height: 36%;
}
.fl_216 {
    top: 6%;
    left: 83%;

    width: 17%;
    height: 88%;
}
.fl_217 {
    top: 56%;
    left: 60%;

    width: 23%;
    height: 45%;
}
.fl_218 {
    top: 56%;
    left: 17%;

    width: 25%;
    height: 45%;
}





.fl_219 {
    top: 11%;
    left: 1%;

    width: 8%;
    height: 81%;
}
.fl_220 {
    top: 11%;
  left: 9%;

    width: 17%;
    height: 35%;
}
.fl_221 {
    top: 11%;
       left: 26%;

    width: 12%;
    height: 35%;
}
.fl_222 {
    top: 10%;
    left: 38%;

    width: 16%;
    height: 36%;
}
.fl_223 {
    top: 10%;
   left: 54%;

    width: 18%;
    height: 36%;
}
.fl_224 {
    top: 10%;
    left: 72%;

    width: 11%;
    height: 36%;
}
.fl_225 {
    top: 6%;
    left: 83%;

    width: 17%;
    height: 88%;
}
.fl_226 {
    top: 56%;
    left: 60%;

    width: 23%;
    height: 45%;
}
.fl_227 {
    top: 56%;
    left: 17%;

    width: 25%;
    height: 45%;
}

.fl_228 {
    top: 0%;
    left: 0%;

    width: 25%;
    height: 53%;
}
.fl_229 {
    top: 0%;
    left: 25%;

    width: 17%;
    height: 44%;
}
.fl_230 {
    top: 0%;
    left: 55%;

    width: 19%;
    height: 44%;
}
.fl_231 {
    top: 0%;
    left: 74%;

    width: 17%;
    height: 44%;
}
.fl_232 {
    top: 9%;
    left: 90%;

    width: 9%;
    height: 84%;
}
.fl_233 {
    top: 55%;
    left: 78%;

    width: 13%;
    height: 45%;
}
.fl_234 {
    top: 55%;
    left: 67%;

    width: 11%;
    height: 45%;
}
.fl_235 {
    top: 55%;
    left: 50%;

    width: 17%;
    height: 45%;
}
.fl_236 {
    top: 55%;
    left: 25%;

    width: 25%;
    height: 45%;
}
.fl_237 {
    top: 55%;
    left: 6%;

    width: 19%;
    height: 45%;
}



.fl_238 {
    top: 9%;
    left: 0%;

    width: 11%;
    height: 84%;
}
.fl_239 {
    top: 5%;
    left: 11%;

    width: 21%;
    height: 40%;
}
.fl_240 {
    top: 5%;
    left: 48%;

    width: 21%;
    height: 40%;
}
.fl_241 {
    top: 5%;
    left: 70%;

    width: 19%;
    height: 40%;
}
.fl_242 {
    top: 5%;
    left: 90%;

    width: 10%;
    height: 88%;
}
.fl_243 {
    top: 55%;
    left: 75%;

    width: 15%;
    height: 44%;
}
.fl_244 {
    top: 55%;
    left: 62%;

    width: 13%;
    height: 44%;
}
.fl_245 {
    top: 55%;
    left: 42%;

    width: 20%;
    height: 44%;
}
.fl_246 {
    top: 55%;
    left: 11%;

    width: 30%;
    height: 44%;
}



.fl_247 {
    top: 9%;
    left: 0%;

    width: 9%;
    height: 84%;
}
.fl_248 {
    top: 9%;
    left: 9%;

    width: 17%;
    height: 36%;
}
.fl_249 {
    top: 9%;
    left: 26%;

    width: 19%;
    height: 36%;
}
.fl_250 {
    top: 9%;
    left: 58%;

    width: 18%;
    height: 36%;
}
.fl_251 {
    top: 4%;
    left: 75%;

    width: 24%;
    height: 47%;
}
.fl_252 {
    top: 54%;
    left: 75%;

    width: 19%;
    height: 46%;
}
.fl_253 {
    top: 54%;
    left: 50%;

    width: 25%;
    height: 46%;
}
.fl_254 {
    top: 54%;
    left: 33%;

    width: 17%;
    height: 46%;
}
.fl_255 {
    top: 54%;
    left: 21%;

    width: 12%;
    height: 46%;
}
.fl_256 {
    top: 54%;
    left: 9%;

    width: 12%;
    height: 46%;
}

.fl_257 {
    top: 2%;
    left: 7%;

    width: 18%;
    height: 42%;
}
.fl_258 {
    top: 2%;
    left: 25%;

    width: 25%;
    height: 42%;
}
.fl_259 {
    top: 2%;
    left: 50%;

    width: 17%;
    height: 42%;
}
.fl_260 {
    top: 2%;
    left: 68%;

    width: 11%;
    height: 42%;
}
.fl_261 {
    top: 2%;
    left: 79%;

    width: 12%;
    height: 42%;
}
.fl_262 {
    top: 7%;
    left: 91%;

    width: 9%;
    height: 84%;
}
.fl_263 {
    top: 56%;
    left: 74%;

    width: 17%;
    height: 38%;
}
.fl_264 {
    top: 56%;
    left: 55%;

    width: 19%;
    height: 38%;
}
.fl_265 {
    top: 56%;
    left: 26%;

    width: 16%;
    height: 38%;
}
.fl_266 {
    top: 53%;
    left: 3%;

    width: 22%;
    height: 44%;
}

.fl_267 {
    top: 5%;
    left: 0%;

    width: 9%;
    height: 85%;
}
.fl_268 {
    top: 5%;
    left: 9%;

    width: 13%;
    height: 40%;
}
.fl_269 {
    top: 5%;
    left: 21%;

    width: 12%;
    height: 40%;
}
.fl_270 {
    top: 5%;
    left: 33%;

    width: 17%;
    height: 40%;
}
.fl_271 {
    top: 5%;
    left: 50%;

    width: 25%;
    height: 40%;
}
.fl_272 {
    top: 5%;
    left: 75%;

    width: 19%;
    height: 40%;
}
.fl_273 {
    top: 46%;
    left: 75%;

    width: 24%;
    height: 47%;
}
.fl_274 {
    top: 55%;
    left: 58%;

    width: 17%;
    height: 40%;
}
.fl_275 {
    top: 55%;
    left: 26%;

    width: 19%;
    height: 40%;
}
.fl_276 {
    top: 55%;
    left: 9%;

    width: 17%;
    height: 40%;
}

.fl_277 {
    top: 8%;
    left: 0%;

    width: 9%;
    height: 85%;
}
.fl_278 {
    top: 8%;
    left: 9%;

    width: 17%;
    height: 38%;
}
.fl_279 {
    top: 8%;
    left: 26%;

    width: 19%;
    height: 38%;
}
.fl_280 {
    top: 8%;
    left: 58%;

    width: 17%;
    height: 38%;
}
.fl_281 {
    top: 8%;
    left: 75%;

    width: 24%;
    height: 46%;
}
.fl_282 {
    top: 55%;
    left: 76%;

    width: 17%;
    height: 41%;
}
.fl_283 {
    top: 55%;
    left: 50%;

    width: 25%;
    height: 41%;
}
.fl_284 {
    top: 55%;
    left: 33%;

    width: 17%;
    height: 41%;
}
.fl_285 {
    top: 55%;
    left: 22%;

    width: 11%;
    height: 41%;
}
.fl_286 {
    top: 55%;
    left: 9%;

    width: 13%;
    height: 41%;
}



.fl_287 {
    top: 8%;
    left: 0%;

    width: 18%;
    height: 45%;
}
.fl_288 {
    top: 8%;
    left: 18%;

    width: 13%;
    height: 35%;
}
.fl_289 {
    top: 8%;
    left: 31%;

    width: 7%;
    height: 35%;
}
.fl_290 {
    top: 8%;
    left: 38%;

    width: 13%;
    height: 35%;
}
.fl_291 {
    top: 8%;
    left: 51%;

    width: 12%;
    height: 36%;
}
.fl_292 {
    top: 8%;
        left: 63%;

    width: 7%;
    height: 36%;
}
.fl_293 {
    top: 8%;
       left: 70%;

    width: 18%;
    height: 36%;
}
.fl_294 {
    top: 8%;
    left: 88%;

    width: 12%;
    height: 36%;
}
.fl_295{
    top: 53%;
  left: 83%;

    width: 17%;
    height: 39%;
}

.fl_296 {
    top: 53%;
        left: 65%;

    width: 18%;
    height: 39%;
}
.fl_297 {
    top: 53%;
  left: 53%;

    width: 12%;
    height: 39%;
}
.fl_298 {
    top: 53%;
   left: 29%;

    width: 12%;
    height: 39%;
}
.fl_299 {
    top: 53%;
  left: 12%;

    width: 17%;
    height: 39%;
}
.fl_300 {
    top: 53%;
    left: 0%;

    width: 12%;
    height: 39%;
}






.fl_301 {
    top: 8%;
    left: 0%;

    width: 18%;
    height: 45%;
}
.fl_302 {
    top: 8%;
    left: 18%;

    width: 13%;
    height: 35%;
}
.fl_303 {
    top: 8%;
    left: 31%;

    width: 7%;
    height: 35%;
}
.fl_304 {
    top: 8%;
    left: 38%;

    width: 13%;
    height: 35%;
}
.fl_305 {
    top: 8%;
    left: 51%;

    width: 12%;
    height: 36%;
}
.fl_306 {
    top: 8%;
        left: 63%;

    width: 7%;
    height: 36%;
}
.fl_307 {
    top: 8%;
       left: 70%;

    width: 18%;
    height: 36%;
}
.fl_308 {
    top: 8%;
    left: 88%;

    width: 12%;
    height: 36%;
}
.fl_309{
    top: 53%;
  left: 83%;

    width: 17%;
    height: 39%;
}

.fl_310 {
    top: 53%;
        left: 65%;

    width: 18%;
    height: 39%;
}
.fl_311 {
    top: 53%;
  left: 53%;

    width: 12%;
    height: 39%;
}
.fl_312 {
    top: 53%;
   left: 29%;

    width: 12%;
    height: 39%;
}
.fl_313 {
    top: 53%;
  left: 12%;

    width: 17%;
    height: 39%;
}
.fl_314 {
    top: 53%;
    left: 0%;

    width: 12%;
    height: 39%;
}









.fl_315 {
    top: 8%;
    left: 0%;

    width: 12%;
    height: 85%;
}
.fl_316 {
    top: 8%;
    left: 12%;

    width: 18%;
    height: 35%;
}
.fl_317 {
    top: 8%;
    left: 31%;

    width: 7%;
    height: 35%;
}
.fl_318 {
    top: 8%;
    left: 38%;

    width: 13%;
    height: 35%;
}
.fl_319 {
    top: 8%;
    left: 51%;

    width: 12%;
    height: 36%;
}
.fl_320 {
    top: 8%;
        left: 63%;

    width: 7%;
    height: 36%;
}
.fl_321 {
    top: 8%;
       left: 70%;

    width: 18%;
    height: 36%;
}
.fl_322 {
    top: 8%;
    left: 88%;

    width: 12%;
    height: 36%;
}
.fl_323{
    top: 53%;
  left: 83%;

    width: 17%;
    height: 39%;
}

.fl_324 {
    top: 53%;
        left: 65%;

    width: 18%;
    height: 39%;
}
.fl_325 {
    top: 53%;
  left: 53%;

    width: 12%;
    height: 39%;
}
.fl_326 {
    top: 53%;
   left: 29%;

    width: 12%;
    height: 39%;
}
.fl_327 {
    top: 53%;
  left: 12%;

    width: 17%;
    height: 39%;
}





.fl_328  {
    top: 8%;
    left: 0%;

    width: 12%;
    height: 85%;
}
.fl_329 {
    top: 8%;
    left: 12%;

    width: 18%;
    height: 35%;
}
.fl_330 {
    top: 8%;
    left: 31%;

    width: 7%;
    height: 35%;
}
.fl_331 {
    top: 8%;
    left: 38%;

    width: 13%;
    height: 35%;
}
.fl_332 {
    top: 8%;
    left: 51%;

    width: 12%;
    height: 36%;
}
.fl_333 {
    top: 8%;
        left: 63%;

    width: 7%;
    height: 36%;
}
.fl_334 {
    top: 8%;
       left: 70%;

    width: 18%;
    height: 36%;
}
.fl_335 {
    top: 8%;
    left: 88%;

    width: 12%;
    height: 36%;
}
.fl_336{
    top: 53%;
  left: 83%;

    width: 17%;
    height: 39%;
}

.fl_337 {
    top: 53%;
        left: 65%;

    width: 18%;
    height: 39%;
}
.fl_338 {
    top: 53%;
  left: 53%;

    width: 12%;
    height: 39%;
}
.fl_339 {
    top: 53%;
   left: 29%;

    width: 12%;
    height: 39%;
}
.fl_340 {
    top: 53%;
  left: 12%;

    width: 17%;
    height: 39%;
}















.fl_341  {
    top: 8%;
    left: 0%;

    width: 22%;
    height: 41%;
}
.fl_342 {
    top: 8%;
    left: 22%;

    width: 29%;
    height: 35%;
}
.fl_343 {
    top: 8%;
   left: 50%;

    width: 28%;
    height: 35%;
}
.fl_344 {
    top: 8%;
    left: 78%;

    width: 21%;
    height: 35%;
}
.fl_345 {
    top: 54%;
   left: 80%;

    width: 19%;
    height: 38%;
}
.fl_346 {
    top: 54%;
       left: 68%;

    width: 13%;
    height: 38%;
}
.fl_347 {
    top: 54%;
     left: 53%;

    width: 18%;
    height: 38%;
}
.fl_348 {
    top: 54%;
    left: 29%;

    width: 9%;
    height: 38%;
}
.fl_349{
    top: 54%;
left: 0%;

    width: 28%;
    height: 38%;
}






.fl_350  {
    top: 8%;
    left: 0%;

    width: 22%;
    height: 41%;
}
.fl_351 {
    top: 8%;
    left: 22%;

    width: 29%;
    height: 35%;
}
.fl_352 {
    top: 8%;
   left: 50%;

    width: 28%;
    height: 35%;
}
.fl_353 {
    top: 8%;
    left: 78%;

    width: 21%;
    height: 35%;
}
.fl_354 {
    top: 54%;
   left: 80%;

    width: 19%;
    height: 38%;
}
.fl_355 {
    top: 54%;
       left: 68%;

    width: 13%;
    height: 38%;
}
.fl_356 {
    top: 54%;
     left: 53%;

    width: 18%;
    height: 38%;
}
.fl_357 {
    top: 54%;
    left: 29%;

    width: 9%;
    height: 38%;
}
.fl_358{
    top: 54%;
left: 0%;

    width: 28%;
    height: 38%;
}





.fl_359  {
    top: 8%;
    left: 0%;

    width: 22%;
    height: 41%;
}
.fl_360 {
    top: 8%;
    left: 22%;

    width: 29%;
    height: 35%;
}
.fl_361 {
    top: 8%;
   left: 50%;

    width: 28%;
    height: 35%;
}
.fl_362 {
    top: 8%;
    left: 78%;

    width: 21%;
    height: 35%;
}
.fl_363 {
    top: 54%;
   left: 80%;

    width: 19%;
    height: 38%;
}
.fl_364 {
    top: 54%;
       left: 68%;

    width: 13%;
    height: 38%;
}
.fl_365 {
    top: 54%;
     left: 53%;

    width: 18%;
    height: 38%;
}
.fl_366 {
    top: 54%;
    left: 29%;

    width: 9%;
    height: 38%;
}
.fl_367{
    top: 54%;
left: 0%;

    width: 28%;
    height: 38%;
}





.fl_368 {
    top: 10%;
    left: 0%;

    width: 20%;
    height: 81%;
}
.fl_369 {
    top: 8%;
    left: 20%;

    width: 26%;
    height: 38%;
}
.fl_370 {
    top: 8%;
    left: 63%;

    width: 19%;
    height: 38%;
}
.fl_371 {
    top: 8%;
    left: 82%;

    width: 17%;
    height: 82%;
}
.fl_372 {
    top: 57%;
    left: 77%;

    width: 14%;
    height: 34%;
}
.fl_373 {
    top: 57%;
    left: 58%;

    width: 20%;
    height: 34%;
}
.fl_374 {
    top: 57%;
    left: 30%;

    width: 28%;
    height: 34%;
}
.fl_375 {
    top: 57%;
    left: 19%;

    width: 12%;
    height: 34%;
}

















.fl_376 {
    top: 10%;
    left: 0%;

    width: 20%;
    height: 81%;
}
.fl_377 {
    top: 8%;
    left: 20%;

    width: 26%;
    height: 38%;
}
.fl_378 {
    top: 8%;
    left: 63%;

    width: 19%;
    height: 38%;
}
.fl_379 {
    top: 8%;
    left: 82%;

    width: 17%;
    height: 82%;
}
.fl_380 {
    top: 57%;
    left: 77%;

    width: 14%;
    height: 34%;
}
.fl_381 {
    top: 57%;
    left: 58%;

    width: 20%;
    height: 34%;
}
.fl_382 {
    top: 57%;
    left: 30%;

    width: 28%;
    height: 34%;
}
.fl_383 {
    top: 57%;
    left: 19%;

    width: 12%;
    height: 34%;
}





.fl_384 {
    top: 10%;
    left: 0%;

    width: 10%;
    height: 81%;
}
.fl_385 {
    top: 10%;
    left: 10%;

    width: 11%;
    height: 37%;
}
.fl_386 {
    top: 10%;
    left: 21%;

    width: 25%;
    height: 37%;
}
.fl_387 {
    top: 10%;
    left: 62%;

    width: 18%;
    height: 37%;
}
.fl_388 {
    top: 10%;
    left: 80%;

    width: 11%;
    height: 37%;
}
.fl_389 {
    top: 10%;
    left: 91%;

    width: 9%;
    height: 74%;
}
.fl_390 {
    top: 57%;
    left: 65%;

    width: 27%;
    height: 33%;
}
.fl_391 {
    top: 57%;
    left: 53%;

    width: 12%;
    height: 33%;
}
.fl_392 {
    top: 57%;
    left: 27%;

    width: 26%;
    height: 33%;
}
.fl_393 {
    top: 57%;
    left: 8%;

    width: 19%;
    height: 33%;
}






.fl_394 {
    top: 10%;
    left: 0%;

    width: 10%;
    height: 81%;
}
.fl_395 {
    top: 10%;
    left: 10%;

    width: 11%;
    height: 37%;
}
.fl_396 {
    top: 10%;
    left: 21%;

    width: 25%;
    height: 37%;
}
.fl_397 {
    top: 10%;
    left: 62%;

    width: 18%;
    height: 37%;
}
.fl_398 {
    top: 10%;
    left: 80%;

    width: 11%;
    height: 37%;
}
.fl_399 {
    top: 10%;
    left: 91%;

    width: 9%;
    height: 74%;
}
.fl_400 {
    top: 57%;
    left: 65%;

    width: 27%;
    height: 33%;
}
.fl_401 {
    top: 57%;
    left: 53%;

    width: 12%;
    height: 33%;
}
.fl_402 {
    top: 57%;
    left: 27%;

    width: 26%;
    height: 33%;
}
.fl_403 {
    top: 57%;
    left: 8%;

    width: 19%;
    height: 33%;
}








.fl_404 {
    top: 10%;
    left: 0%;

    width: 15%;
    height: 81%;
}
.fl_405 {
    top: 10%;
    left: 15%;

    width: 10%;
    height: 39%;
}
.fl_406 {
    top: 10%;
    left: 25%;

    width: 22%;
    height: 39%;
}
.fl_407 {
    top: 10%;
    left: 63%;

    width: 22%;
    height: 39%;
}
.fl_408 {
    top: 10%;
    left: 85%;

    width: 14%;
    height: 74%;
}
.fl_409 {
    top: 60%;
    left: 81%;

    width: 11%;
    height: 32%;
}
.fl_410 {
    top: 60%;
    left: 63%;

    width: 18%;
    height: 32%;
}
.fl_411 {
    top: 60%;
    left: 53%;

    width: 10%;
    height: 32%;
}
.fl_412 {
    top: 60%;
    left: 43%;

    width: 10%;
    height: 32%;
}
.fl_413 {
    top: 60%;
    left: 25%;

    width: 18%;
    height: 32%;
}
.fl_414 {
    top: 60%;
    left: 15%;

    width: 10%;
    height: 32%;
}











.fl_415 {
    top: 10%;
    left: 0%;

    width: 15%;
    height: 81%;
}
.fl_416 {
    top: 10%;
    left: 15%;

    width: 10%;
    height: 39%;
}
.fl_417 {
    top: 10%;
    left: 25%;

    width: 22%;
    height: 39%;
}
.fl_418 {
    top: 10%;
    left: 63%;

    width: 22%;
    height: 39%;
}
.fl_419 {
    top: 10%;
    left: 85%;

    width: 14%;
    height: 74%;
}
.fl_420 {
    top: 60%;
    left: 81%;

    width: 11%;
    height: 32%;
}
.fl_421 {
    top: 60%;
    left: 63%;

    width: 18%;
    height: 32%;
}
.fl_422 {
    top: 60%;
    left: 53%;

    width: 10%;
    height: 32%;
}
.fl_423 {
    top: 60%;
    left: 43%;

    width: 10%;
    height: 32%;
}
.fl_424 {
    top: 60%;
    left: 25%;

    width: 18%;
    height: 32%;
}
.fl_425 {
    top: 60%;
    left: 15%;

    width: 10%;
    height: 32%;
}










.fl_426 {
    top: 10%;
    left: 0%;

    width: 24%;
    height: 37%;
}
.fl_427 {
    top: 10%;
    left: 24%;

    width: 15%;
    height: 37%;
}
.fl_428 {
    top: 10%;
    left: 54%;

    width: 15%;
    height: 37%;
}
.fl_429 {
    top: 10%;
    left: 69%;

    width: 21%;
    height: 37%;
}

.fl_430 {
    top: 10%;
    left: 90%;

    width: 10%;
    height: 37%;
}
.fl_431 {
    top: 55%;
    left: 76%;

    width: 23%;
    height: 37%;
}
.fl_432 {
    top: 55%;
    left: 52%;

    width: 24%;
    height: 37%;
}
.fl_433 {
    top: 55%;
    left: 42%;

    width: 10%;
    height: 37%;
}
.fl_434 {
    top: 55%;
    left: 25%;

    width: 18%;
    height: 37%;
}
.fl_435 {
    top: 55%;
    left: 16%;

    width: 10%;
    height: 37%;
}
.fl_436 {
    top: 46%;
    left: 0%;

    width: 16%;
    height: 44%;
}








.fl_437 {
    top: 10%;
    left: 0%;

    width: 24%;
    height: 37%;
}
.fl_438 {
    top: 10%;
    left: 24%;

    width: 15%;
    height: 37%;
}
.fl_439 {
    top: 10%;
    left: 54%;

    width: 15%;
    height: 37%;
}
.fl_440 {
    top: 10%;
    left: 69%;

    width: 21%;
    height: 37%;
}

.fl_441 {
    top: 10%;
    left: 90%;

    width: 10%;
    height: 37%;
}
.fl_442 {
    top: 55%;
    left: 76%;

    width: 23%;
    height: 37%;
}
.fl_443 {
    top: 55%;
    left: 52%;

    width: 24%;
    height: 37%;
}
.fl_444 {
    top: 55%;
    left: 42%;

    width: 10%;
    height: 37%;
}
.fl_445 {
    top: 55%;
    left: 25%;

    width: 18%;
    height: 37%;
}
.fl_446 {
    top: 55%;
    left: 16%;

    width: 10%;
    height: 37%;
}
.fl_447 {
    top: 46%;
    left: 0%;

    width: 16%;
    height: 44%;
}









.fl_448 {
    top: 10%;
    left: 0%;

    width: 24%;
    height: 37%;
}
.fl_449 {
    top: 10%;
    left: 24%;

    width: 15%;
    height: 37%;
}
.fl_450 {
    top: 10%;
    left: 54%;

    width: 15%;
    height: 37%;
}
.fl_451 {
    top: 10%;
    left: 69%;

    width: 21%;
    height: 37%;
}

.fl_452 {
    top: 10%;
    left: 90%;

    width: 10%;
    height: 37%;
}
.fl_453 {
    top: 55%;
    left: 76%;

    width: 23%;
    height: 37%;
}
.fl_454 {
    top: 55%;
    left: 52%;

    width: 24%;
    height: 37%;
}
.fl_455 {
    top: 55%;
    left: 42%;

    width: 10%;
    height: 37%;
}
.fl_456 {
    top: 55%;
    left: 25%;

    width: 18%;
    height: 37%;
}
.fl_457 {
    top: 55%;
    left: 16%;

    width: 10%;
    height: 37%;
}
.fl_458 {
    top: 46%;
    left: 0%;

    width: 16%;
    height: 44%;
}



.fl_459 {
    top: 10%;
    left: 0%;

    width: 24%;
    height: 37%;
}
.fl_460 {
    top: 10%;
    left: 24%;

    width: 15%;
    height: 37%;
}
.fl_461 {
    top: 10%;
    left: 54%;

    width: 15%;
    height: 37%;
}
.fl_462 {
    top: 10%;
    left: 69%;

    width: 21%;
    height: 37%;
}

.fl_463 {
    top: 10%;
    left: 90%;

    width: 10%;
    height: 37%;
}
.fl_464 {
    top: 55%;
    left: 76%;

    width: 23%;
    height: 37%;
}
.fl_465 {
    top: 55%;
    left: 52%;

    width: 24%;
    height: 37%;
}
.fl_466 {
    top: 55%;
    left: 42%;

    width: 10%;
    height: 37%;
}
.fl_467 {
    top: 55%;
    left: 25%;

    width: 18%;
    height: 37%;
}
.fl_468 {
    top: 55%;
    left: 16%;

    width: 10%;
    height: 37%;
}
.fl_469 {
    top: 46%;
    left: 0%;

    width: 16%;
    height: 44%;
}

.fl_1701 {
    top: 53%;
    left: 51%;

    width: 25%;
    height: 47%;
}

.fl_1702 {
    top: 0%;
    left: 57%;

    width: 29%;
    height: 44%;
}

.fl_1703 {
    top: 33%;
    left: 76%;

    width: 24%;
    height: 67%;
}

.fl_1704 {
    top: 53%;
    left: 13%;

    width: 38%;
    height: 47%;
}

.fl_1705 {
    top: -1%;
    left: 0%;

    width: 39%;
    height: 44%;
}

.pu_tarea textarea{
    position: relative;
    z-index: 5;

    display: block;

    box-sizing: border-box;
    width: 100%;
    height: 4.45vw;
    padding: 0;
    padding: 0;
    padding-top: 1.6vw;
    padding-left: 1.25vw;

    resize: none;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;

    color: #2E3443;
    border: none;
    border: none;
    background: none;

    font-family: 'futurabookc';
    font-size: 1.6vw;
    line-height: 120%;
}

.pu_tarea textarea::placeholder{
    box-sizing: border-box;
    padding: 0;

    color: #2E3443;
    border: none;
    background: none;

    font-family: 'futurabookc';
    font-size: 1.6vw;
}
/*popup_rgba*/


iframe[name='google_conversion_frame'] {
    float: left;

    width: 0 !important;
    height: 0 !important;
    margin-top: -13px;

    font-size: 0 !important;
    line-height: 0 !important;
}

/*@media*/
@media screen and (max-height: 850px){
}
@media screen and (max-height: 750px){
    .header {
    padding-top: 11.5vh;
}
.hl_nav li a {
    height: 44px;

    line-height: 44px;
}
}
@media screen and (max-height: 670px){
    .hl_nav li a {
        height: 30px;

        line-height: 30px;
    }
    .hl_left_act .hl_nav{
        margin-top: 100px;
    }
    .header .front_bg {
    /*background: url(../img/hd_front_bg.png)no-repeat center bottom -100px;*/
    background-size: 100%;
}
 .school_section .front_bg {
    background: url(../img/school_front_bg.jpg)no-repeat center bottom -150px;
    background-size: cover;
}
    .kids_gard .front_bg {
        background: url(../img/kdg_front_bg.jpg)no-repeat center bottom -160px;
        background-size: cover;
    }
}
@media screen and (max-height: 650px){
    .header_inner .scroll_ico {
        margin-top: 28px;
    }
    .dec_square{
        width: 130px;
        height: 130px;
    }
    .hd_ds span{
        font-size: 14px;
    }
    .hd_ds b{
        font-size: 50px;
    }
    .hd_ds p{
        font-size: 14px;
    }
.kids_gard .dec_square:before, .school_section .dec_square:before {
    right: 10px;
    bottom: 10px;
}

    .dec_square span{
        font-size: 14px!important;
    }
    .def_ds b{
        font-size: 44px!important;
    }
    .def_ds p{
        font-size: 14px!important;
    }
    sub{
        font-size: 24px;
    }
    h1{
        font-size: 50px;
    }
    .header {
        padding-top: 90px;
    }
    .def_ds p{
        margin-top: 0;
    }
    .kids_gard{
        padding-top: 50px;
    }
    .tm_in > p{
        font-size: 28px;
    }
    .tm_in > span{
        font-size: 36px;
    }
    .hl_top_info span{
        font-size: 12px;
    }
    .hlt_in span{
        font-size: 12px;
    }
    .hlt_numb{
        font-size: 15px;
    }
    .hlt_call{
        font-size: 11px;
    }
    .hlt_develop img{
        font-size: 28px;
    }
    .hlt_numb:before{
        background-size: 20px;
    }
    .hl_left_act:hover ~.hl_top .hlt_numb:before {
    background-size: 20px;
}
.hlt_develop{
    width: 148px;
}
.hlt_in{
    width: 100%;
}
.hlt_numb{
    width: 202px;
}
.hlt_call{
    width: 128px;
}
.hl_nav li a{
    font-size: 14px;
}
.hl_bot{
    font-size: 9px;
}
.hl_left{
    width: 220px;
}
.hlt_in span{
    padding-left: 10px;
}
}
@media screen and (max-height: 600px){
    .kids_gard .front_bg {
    background: url(../img/kdg_front_bg.jpg)no-repeat center bottom -280px;
    background-size: cover;
}
.school_section .front_bg {
    background: url(../img/school_front_bg.jpg)no-repeat center bottom -220px;
    background-size: cover;
}
}
@media screen and (max-width: 1920px){
}
@media screen and (max-width: 1650px){
}
@media screen and (max-width: 1580px){
    .hlt_numb{
        font-size: 14px;
    }
}
@media screen and (max-width: 1500px){
    .header {
        padding-top: 140px;
    }
    .dec_square{
        width: 130px;
        height: 130px;
    }
    .kids_gard .dec_square:before, .school_section .dec_square:before {
        right: 15px;
        bottom: 10px;

        width: 24px;
        height: 24px;
    }

    .hd_ds span{
        font-size: 14px;
    }
    .hd_ds b{
        font-size: 50px;
    }
    .hd_ds p{
        font-size: 14px;
    }
    sub{
        font-size: 24px;
    }
    h1{
        font-size: 50px;
    }
    .tm_in > p{
        font-size: 28px;
    }
    .tm_in > span{
        font-size: 36px;
    }
.div_tit{
    font-size: 36px;
}
    .hl_top_info span{
        font-size: 12px;
    }
    .hlt_in span{
        font-size: 12px;
    }
    .hlt_numb{
        font-size: 15px;
    }
    .hlt_call{
        font-size: 11px;
    }
    .hlt_develop img{
        font-size: 28px;
    }
    .hlt_numb:before{
        background-size: 20px;
    }
    .hl_left_act:hover ~.hl_top .hlt_numb:before {
    background-size: 20px;
}
.hlt_develop{
    width: 148px;
}
.hlt_in{
    width: 100%;
}
.hlt_numb{
    width: 202px;
}
.hlt_call{
    width: 128px;
}
.hl_nav li a{
    font-size: 14px;
}
.hl_bot{
    font-size: 9px;
}
.hl_left{
    width: 220px;
}
.hl_top_info{
    margin-left: 250px;
}
   .hl_nav li a {
    height: 44px;

    line-height: 44px;
}
.hlt_in span{
    padding-left: 10px;
}
}
@media screen and (max-width: 1450px){

}
@media screen and (max-width: 1400px){
    .kids_gard .dec_square:before{
        bottom: 18px;
    }

}
@media screen and (max-width: 1290px) {

}
@media screen and (max-width: 1230px) {

}

@media screen and (max-width: 1220px) {
    }

@media screen and (max-width: 1210px) {
}

@media screen and (max-width: 1190px) {
    .policy {
        display: inline-block;

    max-width: 220px;
    margin-top: 12px;

    font-size: 10px;
}
    .kids_gard .front_bg {
    background: url(../img/kdg_front_bg.jpg)no-repeat center bottom -180px;
    background-size: cover;
}
.school_section .front_bg {
    background: url(../img/school_front_bg.jpg)no-repeat center bottom -120px;
    background-size: cover;
}
.hl_left{
    display: none;
}
.hl_top_info{
    display: none;
}
.hlt_develop{
    display: none;
}
.hlt_numb{
    width: auto;
    height: 40px;

    font-size: 0;
    line-height: 0;
}
.hlt_numb:before{
    width: 40px;
    height: 40px;
    margin: 0 !important;

    background: #2E3443 url(../img/ico_numb.png)no-repeat center center!important;
    background-size: 20px!important;
    }
.hlt_call{
    height: 40px;

    line-height: 40px;
}
.hl_top_act .hlt_call {
    width: 128px;

    color: rgba(255,255,255, 1);
}
.hl_top_act:hover .hlt_call {
    width: 128px;

    color: rgba(255,255,255, 1);
}
.hl_nav_btn {
    position: relative;

    display: inline-block;

    width: 40px;
    height: 40px;

    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    vertical-align: top;

    background: #ED1C24 url(../img/mob_menu.svg)no-repeat center center;
    background-size: 22px 22px;
}
.hl_nav_btn.opened {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;

    background: #ED1C24 url(../img/close_mob.svg)no-repeat center center;
    background-size: 18px 18px;
}
.hl_top{
    z-index: 101;
}
.hl_top_mob_nav {
    position: fixed;
    z-index: 100;
    top: 0;
    left: -100%;

    display: block;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding-top: 150px;

    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;

    opacity: 0;
    background: rgba(0,0,0, .7);
}
.hl_top_mob_nav li{
    display: block;

    text-align: center;
}
.hl_top_mob_nav a {
    text-decoration: none;
    text-transform: uppercase;

    color: #fff;

    font-size: 20px;
    line-height: 40px;
}
.hl_top_mob_nav.opened{
    left: 0;

    opacity: 1;
}

.header_inner{
    max-width: 100%;
    padding-left: 100px;
}

.header{
    padding-top: 80px;
    /*background: url(../img/header_bg_m_1811_01.jpg)no-repeat center top;*/

    background-size: cover;
}
.kids_gard{
    padding-left: 0;
    /*background: url(../img/kdg_bg_m_01.jpg)no-repeat center top;*/

    background-size: cover;
}
.school_section{
    padding-left: 0;
    /*background: url(../img/school_bg_m_02.jpg)no-repeat center top;*/

    background-size: cover;
}
.tm {
    width: 100%!important;
    padding-left: 100px!important;
}
.tm_in > p{
    font-size: 20px;
}
.div_tit{
    padding-left: 100px;
}


.dec_square:hover .ds_anim {
    transform: scale(1);

    opacity: 1;
    box-shadow: none;
}
.pa_side:hover .pa_tit {
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transform: scale(1);

    opacity: 1;

    font-size: 28px;
}
.pa_side:hover img {
    -webkit-transition: 15s;
    -o-transition: 15s;
    transition: 15s;
    transform: scale(1);
}
.pa_tit:hover {
    background: rgb(46, 52, 67, .5);
}
.pa_side+.pa_side .pa_tit{
    padding-left: 100px;
}
.pa_tit{
    padding-left: 100px;

    font-size: 28px;
}
.pa_side{
    height: auto;
}
.pa_side img {
    min-width: 1px;
    max-width: 100%;
    min-height: 1px;
    }
.infra .tm {
    bottom: 80px;
    left: auto;

    box-sizing: border-box;
    width: 100%;

    transform: none;
}
.infra_slid_nav {
    box-sizing: border-box;
    max-width: 100%;
    margin-top: -4px;
    margin-right: 0px;
    padding: 0 40px;
}
.is_act{
    width: 50px;
}
.is_ico{
    width: 28px;
    height: 28px;

    -webkit-background-size: 100% !important;
    background-size: 100% !important;
}
.is_name{
    font-size: 10px;
}
.is_info{
    font-size: 11px;
}
.isn_in {
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    padding: 0 12px;
    padding-top: 24px;
    padding-bottom: 20px;
    }
    .infra_slid_nav .slick-next{
        right: 20px;
    }
    .infra_slid_nav .slick-prev{
        left: 20px;
    }
.lm_tit{
    justify-content: center;

    padding-left: 0px;

    text-align: center;

    font-size: 36px;
}
.loc_map{
    padding-left: 0;
}
.scroll_ico{
    left: 100px;
}
.lm_side .scroll_ico{
    left: 100px;

    display: none;
}
.lm_info span{
    font-size: 20px;
}
.lm_info p{
    font-size: 14px;
}
.lm_side{
    height: auto;
    min-height: 1px;
    padding-top: 100px;
    padding-bottom: 260px;
}
.lm_side .lm_info {
    padding: 0 50px;
}
.lm_info span br{
    display: none;
}
.lm_info p br{display: none;}
.cl_wrapper{
    padding-left: 100px;
}
.cl_left{
    padding-right: 30px;
    padding-left: 0px;
}
.cl_right{
    width: 50.5%;
}
.comf_title{
    margin-bottom: 40px;

    font-size: 32px;
}
.cl_left p{
    font-size: 16px;
}
.cln_inner{
        min-height: 69px;

    font-size: 11px;
}
.cl_title{
    font-size: 28px;
}
.ga_nav{
    margin-top: 46px;
    padding-left: 100px;
}
.get_apart .tm_in>p {
    margin-top: 20px;

    font-size: 20px;
}
.ga_slider{
    margin-top: 40px;
    padding-left: 100px;
}
.get_flat{
    padding-top: 50px;
}
.gf_form_wrap{
    margin-top: 20px;
    padding-left: 100px;
}
.ga_ds p{
    font-size: 12px;
}

    .dec_square{
        width: 130px;
        height: 130px;
    }
    .hd_ds span{
        font-size: 14px;
    }
    .hd_ds b{
        font-size: 50px;
    }
    .hd_ds p{
        font-size: 14px;
    }


    .dec_square span{
        font-size: 14px!important;
    }
    .def_ds b{
        font-size: 44px!important;
    }
    .def_ds p{
        font-size: 14px!important;
    }

    .def_ds p{
        margin-top: 0;
    }


    .advant_tit{
        padding-left: 100px;

        font-size: 28px;
    }
    .advant_tit sup{
        margin-left: 2px;

        font-size: 12px;
    }
    .slid_style_react .MuiSlider-valueLabel{
        top: -6px;
    }
    .slid_style_react .MuiSlider-valueLabel > span{
        width: 40px;
        height: auto;
    }
    .slid_style_react .MuiSlider-valueLabel > span > span{
        font-size: 12px;
    }
.advant_sect:hover img{
    -webkit-transition: 15s;
    -o-transition: 15s;
    transition: 15s;
    transform: scale(1);
}

.adv_tit_animate:hover .advant_tit{
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    transform: scale(1);

    opacity: 1;
    background: rgba(46, 52, 67, .35);

        font-size: 28px;
}

.details_wrap{
    margin-top: 40px;
    padding-left: 0;
}
.details .tm_in span{
    font-size: 36px;
}
.details .tm_in p{
    margin-left: 120px;
}
.details{
    padding-top: 70px;
}
.detail_sect:hover .det_bg{
    opacity: 0;
}
.detail_sect .det_bg{
    display: none;
}
.detail_sect:hover .det_ico{
    top:auto;

    display: none;
}
.detail_sect .det_ico{
    /*display: none;*/
    top:auto;

    width: 28px;
    height: 28px;
}
.detail_sect:hover span{
    color: #fff;
}

.detail_sect+ .detail_sect {
    border-left: 1px solid rgba(255,255,255,.5);
}
.detail_sect {
    height: 140px;

    border-top: 1px solid rgba(255,255,255,.5);
    }
    .detail_sect span {
    padding: 30px 20px 0px 20px!important;

    letter-spacing: .5px;

    font-size: 10px;
}
.how_buy .dec_square {
    position: relative;
    top: auto;
    right: auto;
    left: auto;

    display: block;

    margin-top: 20px;
}
.how_buy{
    padding-top: 40px;
    padding-bottom: 50px;
}

.hb_list{
    margin-top: 30px;
    padding-left: 100px;
}
.hb_sect{
    width: 300px;
}
.hb_wrap{
    height: 96px;
    padding: 14px;
}
.hb_tit img{
    width: 24px;
}
.hb_tit span{
    padding-left: 12px;

    font-size: 18px;
    line-height: 24px;
}
.hb_text {
    padding-left: 35px;

    font-size: 16px;
    line-height: 22px;
}
.hb_btn {
    padding-left: 48px;

    border-top: 1px solid rgba(46, 52, 67, .1);

    font-size: 12px;
    line-height: 44px;
    }
    .hb_sect + .hb_sect {
    margin-left: 20px;
}

.hb_sect.hb_mat_kap {
    margin-top: 20px;
    margin-left: 0;
}



.hb_list {
    width: 700px;
    max-width: 100%;
    margin-top: -272px;
    padding-left: 0;
}










.dev_sect {
    height: auto;
    height: 390px;
    padding-left: 100px;
}
.dev_tit span{
    font-size: 32px;
    line-height: 100%;
}
.dev_tit p {
    margin-top: 14px;

    font-size: 18px;
}
.dev_tit img{
    width: 120px;
    margin-top: 30px;
}
.dev_sect:nth-child(even){
    padding-left: 100px;
}
.dev_sect:last-child{
    padding-left: 0;
}
.dev_sub{
    font-size: 18px;
}
.dev_info p{
    margin-top: 18px;

    font-size: 14px;
    line-height: 100%;
}
.dev_doc p{
    font-size: 13px;
}

.dev_red_text {
    margin-top: 14px;
    margin-bottom: 16px;
    padding: 14px;
    padding-top: 16px;

    font-size: 14px;
}
.dev_doc a{
    font-size: 10px;
}
.dev_doc{
    width: 86%;
    height: 100%;
}

.hds_nav {
    margin-top: 30px;
    padding-left: 100px;
    }
    .hds_nav a{
        font-size: 12px;
    }
    .hds_months{
        margin-left: 100px;
    }
    .hds_slid{
        margin-top: 30px;
    }
    .hds_in {
    padding-right: 10px;
    /*padding-top: 40px;*/
  /*  padding-top: 40px;
    width: 150px;
    height: 213px;*/
}
.hds_in img{
    max-width: 100%;
    max-height: 100%;
}
.hds_in:before {
    font-size: 74px;
    }
    .hds_slid .slick-next{
        background-size: 10px;
    }
    .hds_slid .slick-prev, .hds_slid .slick-next{
        width: 42px;
        height: 42px;
    }
    .hds_slid .slick-next:hover{
        background-size: 10px;
    }
    .hds_slid .slick-prev{
        background-size: 10px;
    }
    .hds_slid .slick-prev:hover{
        background-size: 10px;
    }
    .ac_inn{
        height: 350px;
    }
    .ac_tit{
        padding-top: 80px;
        padding-left: 50px;
    }
    .ac_tit span{
        font-size: 30px;
    }
    .ac_inn:nth-child(even) .ac_tit{
        padding-left: 50px;
    }
    .ac_tit p{
        margin-top: 12px;

        font-size: 11px;
    }
    .ac_ipot img{
        width: 230px;
    }
    .ac_sale_150k img {
    width: 368px;
}
    .ac_rassr_0 img {
    bottom: 30px;

    width: 250px;
}


    .cont_tm{
        font-size: 34px;
    }
    .sales_depart{
        margin-top: 30px;
    }
    .sd_quest{
        margin-top: 46px;
    }
    .sd_tit{
        font-size: 20px;
    }
    .sd_loc {
    margin-top: 14px;
}
.sd_loc, .sd_phone, .sd_time{
    font-size: 14px;
}
.sd_time{
    margin-top: 8px;
}
.sd_quest p{
    font-size: 16px;
}
.quest_btn {
    width: 154px;
    height: 50px;
    margin-top: 20px;

    border-radius: 2px;

    font-size: 12px;
    line-height: 50px;
}
.cfm_tit{
    font-size: 18px;
}
.cfm_loc {
    margin-top: 12px;
}
.cfm_loc, .cfm_phone, .cfm_time, .cfm_mail{
    font-size: 14px;
}
.cfm_time{
    margin-top: 8px;
}
.cont_left {
    padding-top: 68px;
    padding-bottom: 50px;
    padding-left: 100px;
}
.cfm_wrap{
    padding-top: 18px;
    padding-bottom: 18px;
}
.cont_mail .cfm_wrap{
    padding-top: 18px;
    padding-bottom: 18px;
}
.cfm_wrap > img{
    width: 100px;
    /*margin: 0 auto;*/
}
.develop_info span{
    margin-right: 12px;

    font-size: 12px;
    line-height: 100%;
}
.develop_info img {
    width: 90px;
}

.popup_main {
    max-width: 300px;
    padding: 24px 20px;
}
.pu_form{
    margin-top: 20px;
   }
   .form_main .pu_pl:before{
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
   }
   .jq-selectbox{
    margin-top: 14px;
   }
   .pu_form .pu_pl input {
    padding-top: 10px;
}
.jq-selectbox__select-text {
    height: 40px;
    padding-top: 5px;
}
.jq-selectbox:before {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    }
    .jq-selectbox__select {
    height: 40px;
    padding-left: 12px;

    font: 15px/40px 'futurabookc';
}
.jq-selectbox__dropdown {
    z-index: 11;
    top: 100%!important;

    margin: 0 auto;

    border-radius: 0px;

    font: 10px/18px 'futurabookc';
}
.closeform {
    top: 10px;
    right: 10px;

    width: 10px;
    height: 10px;
}
.pu_tm{
    font-size: 24px;
}
.renting_date {
    font-size: 18px;
}
.form_main .pu_pl input {
    height: 40px;
    padding-left: 12px;

    font-size: 15px;
    }
.input_label_content {
    top: 5px;

    padding-left: 12px;

    font-size: 15px;
    line-height: 40px;
}
.pu_form .pu_pl .input_label_content{
    top: 0;
}
.pu_form .input_act .input_label_content{
    top: 4px;
}
.pu_form .btn_main {
    height: 40px;
    margin-top: 14px;

    border-radius: 2px;

    font-size: 12px;
    line-height: 40px;
}
.pu_thx .pu_tm p {
    margin-top: 8px;
    }
    .pu_tm p {
    font-size: 16px;
}
.pu_slid{
    width: 100%;
}
.jq-selectbox__dropdown ul{
    height: 100px;
}
.pu_calc .pu_pl{
    margin-top: 14px;
}
.pu_slid{
    height: 40px;
}
.single_slid_style.slid_style .ui-slider-handle {
    top: -26px;

    width: 44px;
    margin-left: -22px;

    font-size: 15px;
}
.single_slid_style.slid_style .ui-slider-handle:before {
    top: 20px;

    width: 15px;
    height: 15px;
    }
    .pu_form .pu_pl+.pu_pl{
        margin-top: 14px;
    }
    .pu_tarea textarea {
        height: 40px;
        padding-top: 10px;
        padding-left: 12px;

        font-size: 15px;
    }
.pu_tarea textarea::placeholder{
    font-size: 15px;
}
.pu_slid_wrap{
    height: 40px;
}
.pu_entrace_wrap {
    display: block;

    width: 100%;
    margin-top: 14px;
    margin-left: 0;
}
.pu_entrance span {
    width: 50%;
    padding: 0px;
    padding-top: 12px;

    text-align: center;

    border-top-left-radius: 2px;

    font-size: 14px;
    line-height: 40px;
    }
    .pu_entrance span:last-child{
        border-top-right-radius: .22vw;
    }
    .pu_entrance span+span{
        padding: 0;
        padding-top: 12px;
    }
    .pu_entrance span.act:after {
    bottom: -2px;

    height: 2px;

    opacity: 1;
}
.pu_entrance span:hover:after {
    bottom: -2px;

    height: 2px;
}
.pu_tm span{
    margin-top: 8px;

    text-align: center;

    font-size: 18px;
}

.pu_tm{
    line-height: 100%;
}
.flat_area{
    margin-top: 14px;
}
.flat_area span{
    display: block;

    font-size: 16px;
}
.flat_area span{
    margin-left: 0;
}
.pu_flat_wrap{
    margin-top: 14px;
}
.pfi_in{
    display: block;
    float: none;
}
.pu_flat_img{
    width: 100%;
    height: 280px;
}
.pu_flat .pu_form{
    display: block;
    float: none;

    width: 100%;
    margin: 0 auto;
    margin-top: 14px;
}
.flat_area span sup{
    font-size: 10px;
}
.pu_form_tit{
    font-size: 20px;
}
.gal_slid{
    width: 100%;
}
.ac_btn{
    top: 0;

    width: 120px;
    margin-top: 20px;

    opacity: 1;
    border: 1px solid #fff;

    font-size: 10px;
    line-height: 27px;
}
.lit_dop_info{
    padding-right: 10px;
    padding-left: 5px;

    font-size: 8px;
}
.start.lit_ident .lit_dop_info {
    padding-right: 5px;
    padding-left: 10px;
    }


.liter_show .lit_dop_info{
    padding-right: 10px;
    padding-left: 5px;

    font-size: 8px;
}
.start.lit_ident.start_liter_show .lit_dop_info {
    padding-right: 5px;
    padding-left: 10px;
    }
    .noVariants{
        font-size: 12px;
    }

    .ac_inn.ac_sale_ny img{
        left: 0;
    }


    .ac_inn.ac_sale_apart,
    .ac_inn.ac_sale_ny{
        height: auto;
    }
    .ac_inn.ac_sale_apart .ac_tit,
    .ac_inn.ac_sale_ny .ac_tit{
        position: absolute;
    }
    .ac_inn.ac_sale_ny img{
        position: relative;
    }
    .ac_inn.ac_sale_apart img{
        position: relative;
    }


    .ac_sale_apart.ac_inn:hover .ac_btn {
        top: auto;
        bottom: 50px;

        opacity: 1;
    }
    .ac_inn.ac_sale_ny .ac_btn {
        margin-top: -30px;
    }
    .ac_sale_apart.ac_inn .ac_btn{
        bottom: 50px;
    }


    .react_input_style .MuiInputBase-input{
        height: 54px;
        padding-top: 10px;
    padding-left: 16px;
    }
    .react_input_style .MuiFormLabel-root {
    padding-left: 16px;

    font-size: 18px;
    line-height: 54px;
}
.react_input_style .MuiInputBase-input{
    font-size: 18px;
}

.react_textarea .MuiInputBase-multiline{
        height: 54px!important;
    }
    .react_textarea  textarea.MuiInputBase-input{
        height: 21px!important;
    }
    .react_textarea  .MuiFormLabel-root {
    padding-left: 16px;

    font-size: 18px;
    line-height: 54px;
}

.MuiFormLabel-filled.MuiFormLabel-root, .Mui-focused.MuiFormLabel-root{
    padding-top: 4px;
}
.MuiSlider-root.slid_style_react .MuiSlider-thumb {
    width: 12px;
    height: 12px;
    margin-top: -5px;
    margin-left: -6px;
    }
    .MuiSlider-thumb::before{
        width: 6px;
        height: 6px;
    }
    .react_input_style .MuiInput-underline:before {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}
.MuiSlider-root.slid_style_react{
    height: 2px;
}
.react_select_style .MuiNativeSelect-select:not([multiple]) option, .react_select_style .MuiNativeSelect-select:not([multiple]) optgroup{
    font-size: 14px;
}

}

@media screen and (max-width: 1150px) {
     .kids_gard .front_bg {
    background: url(../img/kdg_front_bg.jpg)no-repeat center bottom -80px;
    background-size: cover;
}
.school_section .front_bg {
    background: url(../img/school_front_bg.jpg)no-repeat center bottom -80px;
    background-size: cover;
}
.cl_right {
    width: 52.5%;
}
.cls_inner{
    padding-left: 4.8vw;
}
.cl_slid .slick-next {
    right: -55px;
}
.cl_slid .slick-prev, .cl_slid .slick-next {
    width: 32px;
    height: 58px;
    }
}

@media screen and (max-width: 1060px) {
    .header {
        background-position: top left;
    }
    .kids_gard{
        background-position: bottom left;
    }
    .school_section {
    background-position: top center;
}
.get_flat{
    background-position: top left;
}
    .header .front_bg {
    background: url(../img/hd_front_bg.png)no-repeat center bottom 0px;
    background-size: 100%;
}
.kids_gard .front_bg {
    background: url(../img/kdg_front_bg.jpg)no-repeat center bottom 0px;
    background-size: cover;
}
.school_section .front_bg {
    background: url(../img/school_front_bg.jpg)no-repeat center bottom 0px;
    background-size: cover;
}
.gal_nav li span{
    font-size: 12px;
}
.gal_nav{
    margin-top: 20px;
}
.gal_img_wrap{
    margin-top: 20px;
}
.hb_list{
    width: 960px;
    margin-top: 10px;

    text-align: center;
}
.hb_sect{
    margin: 10px;
}
.hb_sect.hb_mat_kap{
    margin: 10px;
}
.hb_sect+.hb_sect{
    margin: 10px;
}
}

@media screen and (max-width: 1010px) {

}

@media screen and (max-width: 1000px) {
    .svg_store p{
        -webkit-background-size: 14px;
        background-size: 14px;
    }
    .svg_parking p{
        -webkit-background-size: 14px;
        background-size: 14px;
    }
    .svg_bus_stop p{
        -webkit-background-size: 14px;
        background-size: 14px;
    }
    .svg_school p{
        -webkit-background-size: 14px;
        background-size: 14px;
    }
    .svg_kd p{
        -webkit-background-size: 14px;
        background-size: 14px;
    }
    .svg_infra_ident{
        width: 22px;
        height: 22px;

        box-shadow: 0px 0px 0px 3px rgba(255,255,255, .5);
    }
    .lit_ident{
        min-width: 33px;
        height: 16px;
        padding: 0 8px;

        font-size: 10px;
        line-height: 16px;
    }
    .info_ar {
        padding: 0 7px 0 4px;

        font-size: 9px;
        line-height: 24px;
        line-height: 100%;
    }
    .svg_info {
        right: 0;
        bottom: 100px;

        width: 130px;
        padding: 10px 8.5px;
    }
    .svg_info span:before{
        width: 5px;
        height: 5px;
    }
    .svg_info span{
        font-size: 12px;
    }
    .svg_scroll{
        display: block;
        overflow: hidden;
        overflow-x: scroll;

        width: 100%;
    }
    .svg_wrap{
        width: 1000px;
    }
.swipe {
    position: absolute;
    z-index: 111;
    top: 16px;
    left: 20px;

    display: block;

    width: 50px;
    height: 50px;

    animation: move 3s linear infinite;

    background: url(../img/swipe.png)no-repeat center top;
    -webkit-background-size: 100%;
    background-size: 100%;
}
@keyframes move {
    0%{
        left: 20px;
    }
     50%{
        left: 40px;
    }
     100%{
        left: 20px;
    }
    }
    .info_ar img {
    width: 14px;
    margin-right: 3px;
}
.svg_ds{
    display: none;
}
.svg_ds_mob {
    top: auto;
    bottom: 0;
    left: 0;

    display: flex;
}
/*.svg_ds {
    left: auto;
    right: 0;
    z-index: 7;
    width: 90px!important;
    height: 90px!important;
}*/
.lit_info span{
    font-size: 10px;
}
.lit_info {
    width: 47px;
    height: 47px;
}
.svg_wrap {
    margin-left: -68px;
}
}


@media screen and (max-width: 990px) {
}

@media screen and (max-width: 960px) {
    .svg_infra_ident span {
    left: 18px;

    padding: 0 8px 0 6px;

    font-size: 8px;
    line-height: 14px;

}
    .dev_sect:last-child img{
        min-height: 1px;
    }
    .gal_img_main{
        display: none;
    }
    .gal_slid{
        display: block !important;

        width: 100%;
        margin-top: 20px;
        padding-left: 0;
    }

.gal_slid .slick-prev, .gal_slid .slick-next {
    z-index: 3;
    top: 50%;

    width: 30px;
    height: 30px;
    margin: 0 auto;
    margin: 0 auto;
    padding: 0px;

    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    transform: translateY(-50%);

    border-radius: 50%;
}
.gal_slid .slick-prev{
    left: 10px;

    background: rgba(46, 52, 67, .8)  url(../img/is_ar_prev.svg)no-repeat center left 55%;
    -webkit-background-size: 26%;
    background-size: 26%;
}
.gal_slid .slick-prev:hover{
    background: rgba(46, 52, 67, .8)  url(../img/is_ar_prev_act.svg)no-repeat center left 55%;
    -webkit-background-size: 26%;
    background-size: 26%;
}
.gal_slid .slick-next{
    right: 10px;

    background: rgba(46, 52, 67, .8)  url(../img/is_ar_next.svg)no-repeat center left 55%;
    -webkit-background-size: 26%;
    background-size: 26%;
}
.gal_slid .slick-next:hover{
    background: rgba(46, 52, 67, .8)  url(../img/is_ar_next_act.svg)no-repeat center left 55%;
    -webkit-background-size: 26%;
    background-size: 26%;
}

    .gal_img:hover:before {
    background: rgba(0,0,0, 0) url(../img/plus.svg)no-repeat center center;
    -webkit-background-size: 48px;
    background-size: 48px;
}
    .get_flat{
        padding-bottom: 40px;
    }
    .gf_form{
        display: block;
        float: none;

        width: 300px;
        padding: 15px;

        border-radius: 3px;
    }
    .floor_slid_wrap  input {
    height: 53px;
    padding-top: 16px;
    padding-left: 13px;

    border: 1px solid #f5f5f6;
    border-right: 1px solid #fff;
    border-top-left-radius: 3px;

    font-size: 20px;
}
.floor_slid_wrap input+input {
    padding-right: 13px;
    padding-left: 0;

    border: 1px solid #f5f5f6;
    border-top-left-radius: 0;
    border-top-right-radius: 3px;
}
.gf_rooms{
    margin-top: 14px;
}
.gf_rooms span {
    padding: 24px 15px 10px 16px;

    border-top-left-radius: 3px;

    font-size: 18px;
}

.gf_rooms span:hover:after{
    bottom: -2px;

     height: 2px;

    opacity: .5;
}
.gf_rooms span.act:after{
    bottom: -2px;

    height: 2px;

    opacity: 1;
}
.gf_rooms span+span {
    margin-left: 1px;
        padding: 24px 15px 10px 15px;
}
.gf_form .pu_pl{
    margin-top: 12px;
}
.checkbox_wrap{
    margin-top: 12px;
}
.checkbox_wrap span {
    margin-left: 10px;

    font-size: 12px;
    line-height: 15px;
}
.checkbox_wrap input{
    width: 15px;
    height: 15px;
}
.checkbox_wrap input:checked {
    background-size: 94%;
}
.form_main .pu_pl+.pu_pl{
    margin-top: 12px;
}
.btn_main{
    height: 40px;
    margin-top: 12px;

    border-radius: 2px;

    font-size: 12px;
    line-height: 40px;
}
.gf_ds {
    top: auto;
    left: auto;

    display: none;

    margin-top: 20px;
}
.gf_ds_mob{
    display: flex;

    margin-top: 20px;
    margin-bottom: 20px;
}
    .cl_wrapper{
        padding-left: 0;

        text-align: center;
    }
    .cl_left {
    display: block;

    width: 300px;
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0px;
}
.comf_title{
    text-align: center;
}
.cl_right {
    display: block;

    width: 310px;
    margin: 0 auto;
    margin-top: 40px;
}
.cl_nav_slid {
    width: 100%;
    margin-right: 0;
}
.cln_inner{
    height: 50px;
    min-height: 1px;
    padding-top: 0px;
}
.cl_ds {
    top: auto;
    bottom: 0;
    left: 50%;

    width: 80px;
    height: 80px;
    margin: 0 auto;

    transform: translateX(-50%);
}
.slick-active .cl_ds {
    top: auto;
    bottom: 0;
    left: 50%;

    transform: translateX(-50%);
}
.cls_inner{
    padding-left: 0px;
}
.cl_slid .slick-prev{
    left: -30px;
}
.cl_slid .slick-next{
    right: -30px;
}
.cl_slid .slick-prev, .cl_slid .slick-next {
    top: 50%;
    bottom: auto;

    width: 30px;
    height: 40px;

    transform: translateY(-50%);
}


.viewing{
    flex-direction: column!important;

    padding: 0px;
    padding-top: 40px;
    padding-bottom: 40px;

    text-align: center;
}
.view_tit{
    text-align: center;

    font-size: 28px;
}
.viewing .form_main{
    display: block;
    flex-direction: column!important;

    width: 300px;
    margin: 0 auto;
    margin-top: 22px;
}
.viewing .form_main .pu_pl{
    display: block;

    width: 100%;
}
.viewing .form_main .pu_pl+.pu_pl{
    margin-top: 14px;
    margin-left: 0px;
}
.viewing .react_input_style .MuiFormControl-root {
    width: 100%!important;
}
.viewing .MuiFormControl-root + .MuiFormControl-root {
    margin-top: 10px;
    margin-left: 0vw!important;
}
.btn_view{
    display: block;

    width: 100%;
    height: 40px;
    margin: 0 auto;
    margin-top: 14px;

    font-size: 14px;
}

.form_main .pu_pl input{
    height: 40px;
    padding-left: 12px;

    font-size: 15px;
}
.input_label_content{
    height: 40px;
    padding-left: 12px;

    font-size: 16px;
    line-height: 40px;
}
.input_act .input_label_content {
    top: 3px;

    font-size: 10px;
    line-height: 8px;
}
.ga_ds{
    display: none;

    height: 70px;
}
.ga_ds_mob {
    position: relative;
    top: auto;
    left: auto;

    display: flex;

    margin: 0 auto;
    margin: 16px auto;

    transform: none;
}

.ga_name {
    top: 4px;
    left: 14px;

    font-size: 12px;
    }
    .ga_rooms span {
         padding: 24px 18px 10px 18px;

        border-top-left-radius: 3px;

        font-size: 18px;
    }
    .ga_rooms {
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: space-between;

        width: 300px;
        margin: 0 auto;
    }
    .ga_slid{
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: space-between;

        width: 300px;
        margin: 0 auto;
        margin-top: 10px;

        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }
    .ga_floor{
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: space-between;

        width: 300px;
        margin: 0 auto;
        margin-top: 10px;

        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }
    .ga_completion_date{
        display: flex;
        align-content: center;
        align-items: center;
        justify-content: space-between;

        width: 300px;
        margin: 0 auto;
        margin-top: 10px;

        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
    }
    .ga_form input {
        height: 53px;
        padding-top: 16px;
        padding-left: 13px;

        border: 1px solid #f5f5f6;
        border-right: 1px solid #fff;
        border-top-left-radius: 3px;

        font-size: 20px;
    }
   .ga_form input+input {
    padding-right: 13px;
    padding-left: 0;

    border: 1px solid #f5f5f6;
    border-top-left-radius: 0;
    border-top-right-radius: 3px;
}
.ga_rooms span+span{
    margin-left: 1px;
}

.ga_completion_date span {
    padding: 24px 36px 10px 14px;

    border-top-left-radius: 3px;

    font-size: 16px;
}
.ga_completion_date span + span {
    padding: 24px 8.5px 10px 8.5px;
}

.get_apart .tm{
    padding-left: 0!important;

    text-align: center;
}
.get_apart .tm_in > span{
    text-align: center;
}
.ga_nav{
    margin-top: 20px;
    padding-left: 0px;
}
.ga_inner{
    width: 300px;
}
.ga_slider .slick-slide .ga_inner{
    margin: 0 auto;
}
.ga_slider{
    padding-left: 10px;
}
.gai_tit{
    font-size: 16px;
}
.gai_img{
    height: 200px;
    margin-top: 12px;
}
.gai_area{
    margin-top: 16px;
}
.gai_area > span {
    height: 42px;
    padding-top: 14px;

    border-right: 1px solid #ED1C24;

    font-size: 16px;
}
.ga_btn{
    height: 40px;

    font-size: 12px;
    line-height: 40px;
}
.gai_area > span+span{
    padding-left: 18px;
}
.ga_slider .slick-prev, .ga_slider .slick-next{
    width: 56px;
    height: 56px;
}


.ga_slider .slick-next{
    right: 10px;

    background: rgba(46, 52, 67, .8) url(../img/is_ar_next.svg)no-repeat center left 52%;
    -webkit-background-size: 10px;
    background-size: 10px;
}
.ga_slider .slick-next:hover{
    background: rgba(46, 52, 67, 1) url(../img/is_ar_next_act.svg)no-repeat center left 52%;
    -webkit-background-size: 10px;
    background-size: 10px;
}
.ga_slider .slick-prev{
    left: 10px;

    margin: 0 auto;

    background: rgba(46, 52, 67, .8) url(../img/is_ar_prev.svg)no-repeat center left 52%;
    -webkit-background-size: 10px;
    background-size: 10px;
}
.ga_slider .slickprevt:hover{
    background: rgba(46, 52, 67, 1) url(../img/is_ar_prev_act.svg)no-repeat center left 52%;
    -webkit-background-size: 10px;
    background-size: 10px;
}
.ga_slider:before{
    width: 22px;
}
.slid_style .ui-slider-handle {
    position: absolute;

    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-left: -8px;

    border-radius: 50%;
    }
    .slid_style .ui-slider-handle:before{
        width: 8px;
        height: 8px;
    }
    .ga_rooms span.act:after{
        bottom: -2px;

        height: 2px;
    }
    .slid_style .ui-slider-range{
        height: 2px;
    }
    .slid_style{
        height: 2px;
    }
    .ga_completion_date span.act:after{
        bottom: -2px;

        height: 2px;
    }

    .dev_sect {
        width: 100%;
        height: auto;
        padding-left: 0;
    }
    .dev_tit {
        display: block;
        align-content: center;
        align-items: center;
        justify-content: center;

        height: auto;
        padding-top: 30px;
        padding-bottom: 30px;

        text-align: center;
    }
    .dev_tit span{
        text-align: center;
    }
    .dev_tit p{
        text-align: center;
    }
    .dev_tit img{
        margin: 0 auto;
        margin-top: 20px;
    }
    .dev_sect:nth-child(even) {
    padding-left: 0;
}
.dev_info {
    display: block;

    width: 100%;
    height: auto;
    padding: 0 20px;
    padding-top: 20px;
    padding-bottom: 20px;

    text-align: center;

    border-bottom: 4px solid #fff;
}
.dev_doc {
    display: block;

    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: 0 10px;
    padding-top: 20px;
    padding-bottom: 20px;

    text-align: center;

    border-bottom: 4px solid #fff;
}
.dev_doc_desk{
    display: none;
}
.dev_doc_mob{
    display: block;
}

.dev_red_text{
    text-align: center;
}
.dev_sect .dec_square{
    position: relative;
    right: auto;
    bottom: auto;

    margin: 0 auto;
}
.dev_doc span{
    margin-top: 14px;
    margin-bottom: 8px;

    font-size: 13px;
}
.dev_doc_mob span{
    margin-top: 0;
    padding-top: 0;

    font-size: 18px;
}
.dev_doc a {
    display: block;

    width: 107px;
    margin: 0 auto;

    text-align: left;

    font-size: 13px;
}
.dev_doc a:before {
    right: -14px;

    width: 6px;
    height: 10px;
    }
    .ac_inn {
    display: block;

    width: 100%;
    height: 150px;

    text-align: center;
}
.ac_tit {
    display: block;

    padding-top: 20px;
    padding-left: 0;

    text-align: center;
}
.ac_tit span{
    text-align: center;
}
.ac_inn:nth-child(even) .ac_tit{
    padding-left: 0;
}
.ac_inn+.ac_inn{
    height: 320px;
    padding-left: 30px;

    text-align: left;
}
.ac_inn+.ac_inn span{
    text-align: left;
}
.ac_inn+.ac_inn p{
    text-align: left;
}

.ac_inn.ac_sale_ny img {
    left: -30px;
}
.ac_inn.ac_sale_apart img {
    left: -30px;
}


.ac_inn.ac_sale_ny, .ac_inn.ac_sale_apart{
    height: auto!important;
}
.ac_inn.ac_sale_ny .ac_btn {
    position: absolute;
    top: 10%;
    left: 10%;

    margin-top: 0;
}
}

@media screen and (max-width: 940px) {
}

@media screen and (max-width: 900px) {
}

@media screen and (max-width: 880px) {
.cont_left {
    display: block;

    width: 100%;
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 0;

    text-align: center;
}
.cont_tm{
    text-align: center;
}
.quest_btn{
    margin: 0 auto;
    margin-top: 20px;
}
.cont_right {
    position: relative;
    top: auto;
    right: auto;

    display: block;

    width: 100%;
    height: auto;

    font-size: 0;
}
.cont_map{
    height: 300px;
}
.cont_family, .cont_mail {
    display: block;

    width: 100%;
    height: auto;
}
.cont_family .cfm_wrap {
    align-items: center;

    text-align-last: center;
}
}

@media screen and (max-width: 830px) {

}

@media screen and (max-width: 780px) {
	.pa_tit l:before{
		top: 4px;
	}
    .kids_gard .dec_square {
    position: relative;
    top: auto;
    right: auto;
    left: auto;

    margin: 0 auto;
    margin-top: 20px;
}
.tm{
    box-sizing: border-box;
    padding: 0 10px!important;

    text-align: center;
    }

.div_tit{
    box-sizing: border-box;
    padding: 40px 10px;

    text-align: center;
}
    .school_section {
    background-position: top center;
}
.school_section .dec_square{
    animation: none;
}
.kids_gard .dec_square{
    animation: none;
}
    .school_section .dec_square {
    position: relative;
    right: auto;
    bottom: auto;

    margin: 0 auto;
    margin-top: 20px;
}
.pa_side {
    display: block;

    width: 100%;
    height: auto;
}
.pa_side img{
    display: block;

    width: 100%;
}
.infra_slid_nav .slick-prev, .infra_slid_nav .slick-next{
    width: 10px;
    height: 20px;

    transform: translateY(-50%);
}
.lm_side{
    display: block;

    width: 100%;
}
.lm_side .lm_info{
    padding-left: 100px;
}
.loc_map{
    border-top: 1px solid #EEEFF0;
}
.advant_sect {
    display: block;
    overflow: hidden;

    width: 100%;
    height: auto;
    min-height: 150px;
    }

.advant_tit {
    justify-content: center;

    padding-left: 0px;

    text-align: center;
}
.advant_sect img {
    width: 100%;
    min-width: 1px;
    min-height: 1px;
}
.detail_sect{
    height: 120px;
}
.detail_sect span{
    align-content: center;
    align-items: center;
    justify-content: center;

    padding: 0 !important;

    text-align: center;

    -ms-align-items: center;
}
.how_buy .dec_square{
    margin: 0 auto;
    margin-top: 20px;
}
.hb_sect{
    margin: 10px!important;
}
.hb_list {
    width: 100%;
    padding: 0px;

    text-align: center;
}
.hb_sect{
    text-align: left;
}
.hds_nav{
    padding-right: 10px;
    padding-left: 10px;

    text-align: center;
}
.hds_months{
    display: block;

    margin-left: 0px;
}
.ac_ipot img {
    width: 180px;
}

.ac_sale_150k img {
    width: 190px;
}
.ac_rassr_0 img {
    width: 220px;
}
.lm_side .dec_square{
    display: none;
}
.lm_tit{
    padding-bottom: 80px;
}
}


@media screen and (max-width: 760px) {
}

@media screen and (max-width: 740px) {
}

@media screen and (max-width: 720px) {
}

@media screen and (max-width: 690px) {

}

@media screen and (max-width: 680px) {
    .gal_img img {
        display: block;

        width: auto;
        /*height: 320px;*/
    }
    .scroll_ico{
        display: none !important;
    }
    .header_inner{
        padding: 0 10px;

        text-align: center;
    }
    .dec_square{
        margin: 0 auto;
        margin-top: 20px;
    }
    .gf_ds_mob{
        margin-bottom: 20px;
    }
    .infra .tm {
        position: relative;
        bottom: auto;

        width: 100%;
    }
    .infra{
        padding-top: 40px!important;
    }
    .infra_slid{
        margin-top: 40px;
    }
    .infra .tm_in > span {
        color: #2E3443;
    }
    .svg_ds{
        margin-top: 0;
    }
    .gf_form_wrap{
        padding-left: 0;
    }
    .gf_form{
        margin: 0 auto;
    }
.detail_sect{
    width: 50%;
}
.details .tm_in span {
    font-size: 28px;
}
.details .tm_in p {
    display: block;

    margin: 0 auto;
    margin-top: 18px;
}
.hd_dark_dec {
    top: -100px;
    left: 50%;

    width: 100vw;
    height: 90vw;

    transform: translateX(-50%);

    opacity: 1!important;
    border-radius: 50%;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    background: rgba(0,0,0, .45)!important;
    box-shadow: 0px 0px 70px rgba(0,0,0, 1);
}
.sc_tm:before {
    top: 50%;
    left: 50%;

    width: 100vw;
    height: 100vw;

    transform: translate(-50%, -50%);

    background: url(../img/school_bg_dec.png)no-repeat center top;
    background-size: 100% 100%;
}
}

@media screen and (max-width: 620px) {
}

@media screen and (max-width: 580px) {
}
@media screen and (max-width: 560px) {
    .hds_in img{
        margin: 0 auto;
    }
}

@media screen and (max-width: 550px) {
    .hds_in img{
        padding-right: 0;
    }
}

@media screen and (max-width: 540px) {

    .gal_img img {
        /*height: 190px;*/
    }
    .gal_img{
        display: block;
    }
    .l_img{
        width: 100%;
    }
    .s_img{
        width: 100%;
    }
    .ac_rassr_0 img {
    width: 170px;
}
}

@media screen and (max-width: 530px) {
}

@media screen and (max-width: 490px) {
}

@media screen and (max-width: 480px) {
.header{
    background: url(../img/header_bg_1811_02.jpg)no-repeat center left -100px;
    background: url(../img/header_bg_m_1811_03.jpg)no-repeat center left;
    background-size: cover;
}
.kids_gard{
    padding-left: 0;

    background: url(../img/kdg_bg_m_03.jpg)no-repeat center top;
    background-size: cover;
}
.school_section{
    padding-left: 0;

    background: url(../img/school_bg_m_04.jpg)no-repeat center top;
    background-size: cover;
}
    .cl_slid .slick-prev, .cl_slid .slick-next {
    width: 30px;
    height: 30px;

    border-radius: 50%;
}
.cl_slid .slick-prev {
    left: 10px;

    background: rgba(0,0,0, .3)  url(../img/is_ar_prev.svg)no-repeat center center;
    background-size: 33%;
}
.cl_slid .slick-next {
    right: 10vw;

    background: rgba(0,0,0, .3) url(../img/is_ar_next.svg)no-repeat center center;
    background-size: 33%;
}
.cl_slid .slick-prev:hover{
    background: rgba(0,0,0, .3) url(../img/is_ar_prev_act.svg)no-repeat center center;
    background-size: 33%;
}

.cl_slid .slick-next:hover{
    background: rgba(0,0,0, .3) url(../img/is_ar_next_act.svg)no-repeat center center;
    background-size: 33%;
}
    .tm_in > span {
    box-sizing: border-box;
    padding: 0 8px;

    font-size: 28px;
}
.div_tit{
    font-size: 28px;
}
.tm_in > p {
    font-size: 16px;
}
    h1 {
    font-size: 30px;
}
sub {
    font-size: 20px;
}
sub br{
    display: none;
}
.pa_tit{
    padding-left: 50px;
}
.pa_side+.pa_side .pa_tit{
    padding-left: 50px;
}
.lm_side .lm_info{
    padding-left: 50px;
}
.lm_tit{
    /*padding-left: 50px;*/
    font-size: 28px;
}
.lm_side {
    padding-top: 0px;
    padding-bottom: 0px;
}
.lm_tit {
    /*padding-left: 50px;*/
    position: relative;

    padding-top: 20px;
    /*padding-bottom: 200px;*/
}
.lm_side .lm_info {
    position: relative;

    padding: 26px 50px;
}
.tm_in > span br{
    display: none;
}

/*.detail_sect{
    width: 100%;
}*/

.ac_sale_apart.ac_inn .ac_btn {
    bottom: 36px;
}
.ac_sale_apart.ac_inn:hover .ac_btn{
    bottom: 36px;
}
}
@media screen and (max-width: 470px) {
}

@media screen and (max-width: 460px) {
}

@media screen and (max-width: 430px) {
}

@media screen and (max-width: 420px) {
   /* .lm_side .dec_square{
        width: 50% !important;
    }*/
}

@media screen and (max-width: 380px) {
}

@media screen and (max-width: 360px) {
}

@media screen and (max-width: 340px) {
}

.cookie-popup {
    position: fixed;
    z-index: 100;
    bottom: 0;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 475px;
    padding: 15px;
    transition: opacity 0.3s;
    transform: translateX(-50%);
    background: #ED1C24;
  }
  @media (max-width: 575px) {
    .cookie-popup {
      width: 100%;
      padding: 10px;
    }
  }
  .cookie-popup:not(.show) {
    display: none;
    opacity: 0;
  }
  .cookie-popup__text {
    margin: 0;
    color: #fff;
    line-height: 150%;
  }
  .cookie-popup .link {
    text-decoration: underline;
    color: #fff;
  }
  @media (hover: hover) and (pointer: fine) {
    .cookie-popup .link:hover {
      text-decoration: none;
    }
  }

  .link--cookie {
    background: none;
    border: none;
    color: #fff;
    padding: 0;
}

.button--cookie {
    background: none;
    border: none;
    color: #fff;
    padding: 5px 10px;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
}

.button--cookie:hover {
    background-color: #c7161d;
}
