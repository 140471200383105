@font-face {
    font-family: 'futurabookc';
    src: url('fonts/futurabookc.eot');
    src: url('fonts/futurabookc.eot?#iefix') format('embedded-opentype'),
         url('fonts/futurabookc.woff') format('woff'),
         url('fonts/futurabookc.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'futurabookc';
    src: url('fonts/futurademic.eot');
    src: url('fonts/futurademic.eot?#iefix') format('embedded-opentype'),
         url('fonts/futurademic.woff') format('woff'),
         url('fonts/futurademic.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'futurabookc';
    src: url('fonts/futuralightc.eot');
    src: url('fonts/futuralightc.eot?#iefix') format('embedded-opentype'),
         url('fonts/futuralightc.woff') format('woff'),
         url('fonts/futuralightc.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'IskraCYR-BoldItalic';
    src: url('fonts/IskraCYR-BoldItalic.eot');
    src: url('fonts/IskraCYR-BoldItalic.eot?#iefix') format('embedded-opentype'),
         url('fonts/IskraCYR-BoldItalic.woff') format('woff'),
         url('fonts/IskraCYR-BoldItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'IskraCYR-Bold';
    src: url('fonts/IskraCYR-Bold.eot');
    src: url('fonts/IskraCYR-Bold.eot?#iefix') format('embedded-opentype'),
         url('fonts/IskraCYR-Bold.woff') format('woff'),
         url('fonts/IskraCYR-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
